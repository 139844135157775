import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import SummaryItem from './SummaryItem'
import SummaryCheckWindow from './SummaryCheckWindow'
import EnableOrDisableChecklist from './EnableOrDisableChecklist'
import { tableData } from '../tests/testTableData'

type Props = {
  checklistData: ObjectStringKeyAnyValue
  showEditButton?: boolean
}

function SettingsDetails({
  checklistData,
  showEditButton = true
}: Props) {

  function textForScheduleFrequency(databaseValue: string) {
    if (databaseValue == 'ADHOC') {
      return 'Ad-hoc'
    }
    if (databaseValue == 'WEEKLY') {
      return 'Scheduled'
    }

  }
  const {
    tableData
  } = useContext(GlobalContext)
  const { checklistId } = useParams()

  return <div>

    <Card>
      <div className={`w-full flex flex-row gap-4 items-center justify-between`}>
        <h4 className={`font-righteous text-xl font-brandblue`}>Settings</h4>

        
      </div>

      {showEditButton && <EnableOrDisableChecklist
        thisChecklist={checklistData}
      // savingProgress={savingProgress}
      // setSavingProgress={setSavingProgress}
      />}
      <SummaryItem
        title={'Checklist Name'}
        link={`settings/checklist-name`}
        value={checklistData['ChecklistName']}
        showEditButton={false} />



      {checklistData['AssetTypeId'] && <SummaryItem
        title={'Checklist for asset type'}
        link={``}
        value={
          checklistData['AssetTypeId'] &&
            tableData &&
            tableData.AssetTypes &&
            tableData.AssetTypes[checklistData['AssetTypeId']] &&
            tableData.AssetTypes[checklistData['AssetTypeId']].DisplayName ?
            tableData.AssetTypes[checklistData['AssetTypeId']].DisplayName :
            `asset type Id: ${checklistData['AssetTypeId']}`}
        showEditButton={false} />}


      <SummaryItem
        title={'Schedule Frequency'}
        link={`settings/schedule-frequency`}
        value={checklistData['Schedule'] ? textForScheduleFrequency(checklistData['Schedule']['Frequency']) : null}
        showEditButton={false} />

      {checklistData && checklistData['Schedule'] && checklistData['Schedule']['Frequency'] !== 'ADHOC' && <SummaryCheckWindow
        title={'Check Windows'}
        link={`settings/schedule-weekly`}
        value={checklistData['Schedule'] ? checklistData['Schedule']['CheckWindows'] : null}
        showEditButton={false} />}



      <div className={`w-full flex flex-col gap-1`}>
      <Button
          internalLinkUrl={`/checklists/${checklistId}/settings`}
          text={'Edit settings'}
          fullwidth={true}
        />

        {/* <Button
          internalLinkUrl={`/checklists/${checklistId}/questions`}
          text={`Edit questions`}
          variant={`primary`}
          // size={`big`}
          fullwidth={true}
        /> */}
      </div>

    </Card>





  </div>
}
export default SettingsDetails