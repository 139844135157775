import { getStartAndEndTimestampsForDay } from "./getStartAndEndTimestampsForDay"

export const updateProcessData = (tableData: ObjectStringKeyAnyValue) => {
    const newProcessData: ObjectStringKeyAnyValue = {}
    const resultsData: ObjectStringKeyAnyValue = {}
    //console.log(`🔥 Formatting results data`)
    // console.log(tableData.ProcessRecords)

    // iterate through process definitions and add to process data object
    if (tableData.ProcessDefinitions) {
        for (const processId in tableData.ProcessDefinitions) {
            const processDefinition = tableData.ProcessDefinitions[processId]
            const orderedStageIds = processDefinition.Stages && Object.keys(processDefinition.Stages).sort((a, b) => {
                return processDefinition.Stages[a].Order - processDefinition.Stages[b].Order
            })

            const downloadable_reports = {} 
            if (processDefinition && processDefinition.Reports) {
                for (const reportId in processDefinition.Reports) {
                    const report = processDefinition.Reports[reportId]
                    if (processDefinition && processDefinition.ReportingCustomTemplates && processDefinition.ReportingCustomTemplates[reportId]) {
                        
                        //@ts-ignore
                        downloadable_reports[reportId] = {
                            DisplayName: report.DisplayName,
                            ReportId: reportId
                        }
                    }
                }
            }
            newProcessData[processId] = {
                DisplayName: processDefinition.DisplayName,
                InProgress: false,
                Description: 'Ready to start',
                Stages: processDefinition.Stages,
                OrderedStageIds: orderedStageIds,
                ProcessRecords: {},
                DownloadableReports: downloadable_reports
            }
        }
    }

    if (tableData.ProcessRecords) {
        for (const processRecordId in tableData.ProcessRecords) {
            const processRecord = tableData.ProcessRecords[processRecordId]
            const processDefinitionId = processRecord.ProcessDefinitionId as string

            if (processDefinitionId && newProcessData[processDefinitionId]) {
                newProcessData[processDefinitionId].ProcessRecords[processRecordId] = processRecord
                const thisRecord = newProcessData[processDefinitionId].ProcessRecords[processRecordId]

                const currentStageId = processRecord.CurrentStageId
                const stages = newProcessData[processDefinitionId].Stages
                const currentStage = currentStageId && stages && stages[currentStageId]
                const currentStageName = currentStage && currentStage.DisplayName

                if (currentStage && currentStage.NextSteps) {
                    thisRecord['NextSteps'] = currentStage.NextSteps
                }
                thisRecord['InProgress'] = true
                thisRecord['processRecord'] = processRecord
                thisRecord['Description'] = `Stage: ${currentStageName}`

                thisRecord['CheckPeriods'] = {}

                for (const stageId in processRecord.StageProgress) {
                    const stageProgress = processRecord.StageProgress[stageId]
                    const checklistPeriodIdsFromStageProgress = stageProgress.ChecklistPeriodIds
                    const checklistPeriodIdsArray = checklistPeriodIdsFromStageProgress && Object.values(checklistPeriodIdsFromStageProgress)
                    // console.log(`🚨 checklist period ids for stage: ${stageId}`)
                    // console.log(checklistPeriodIdsArray)
                    if (checklistPeriodIdsArray && checklistPeriodIdsArray.length > 0) {
                        for (const checklistPeriodId of checklistPeriodIdsArray) {
                            const thisChecklistPeriod = tableData && checklistPeriodId && tableData.ChecklistPeriods && tableData.ChecklistPeriods[checklistPeriodId]
                            if (thisChecklistPeriod) {
                                thisRecord['CheckPeriods'][checklistPeriodId] = thisChecklistPeriod
                            } else {
                                // console.log(`🚨 Checklist period ${checklistPeriodId} not found`)
                            }
                        }
                    }
                }

            }
        }
    } else {
        //    console.log("No process records found")
        //    console.log(Object.keys(tableData))
    }
    //console.log("Process data formatted:")
    //console.log(newProcessData)
    
    return newProcessData
}