import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Text from '../formfields/Text'
import { validateForm } from '../utils/validateForm'
import { removeNonAlphanumeric } from '../utils/removeNonAlphanumeric'
import Dropdown from '../formfields/Dropdown'
import YellowAlert from '../ui-elements/YellowAlert'
import AssociatedItems from '../ui-elements/AssociatedItems'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import AddButton from '../formfields/AddButton'
import AddEditStage from './AddEditStage'
import FooterNavButtonFrame from '../ui-elements/FooterNavButtonFrame'
import ProcessReportingOptionsStage from './ProcessReportingOptionsStage'
import JsonDebug from '../ui-elements/JsonDebug'

type Props = {
}

function ProcessReportingOptions({
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)

    const { processDefinitionId, reportingOptionId } = useParams()
    const navigate = useNavigate()

    const initialFormData = { "DisplayName": "" }
    const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
    const [formErrors, setFormErrors] = useState<ObjectStringKeyAnyValue>({})
    const [sortedStageIds, setSortedStageIds] = useState<string[]>([])
    const thisProcess = processDefinitionId && tableData && tableData.ProcessDefinitions && tableData.ProcessDefinitions[processDefinitionId]
    const thisReportingOptionFormFields = reportingOptionId && formFields.Reports && formFields.Reports[reportingOptionId]
    const [fileToUpload, setFileToUpload] = useState<any>(null)

    useEffect(() => {
        // populate fields with existing data
        if (thisProcess) {
            const newFieldValues: ObjectStringKeyAnyValue = { ...thisProcess }
            if(reportingOptionId && newFieldValues.ReportingCustomTemplates) {
                      delete newFieldValues.ReportingCustomTemplates
            }

            setFormFields(newFieldValues)
            if (thisProcess.Stages) {
                const updatedArrayOfStageIds = Object.keys(thisProcess.Stages).sort((a: string, b: string) => {
                    return thisProcess.Stages[a].Order - thisProcess.Stages[b].Order
                }) || []
                setSortedStageIds(updatedArrayOfStageIds)
            }
        }
    }, [tableData])



    const handleChangeReportingOption = (key: string, value: any) => {
        if (reportingOptionId && formFields['Reports'] && formFields['Reports'][reportingOptionId]) {
            const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
            newFieldValues['Reports'][reportingOptionId][key] = value
            setFormFields(newFieldValues)
        } else {
        }
    }



    const submitForm = () => {
        console.log("Submit form pressed")
        const isValid = validateForm()
        if (isValid && processDefinitionId) {
            if (fileToUpload) {
                requestUploadUrl()
                saveItem()
            } else {
                saveItem()
            }
        }
    }

    const saveItem = () => {
        setShowModal({ "spinner": 'Saving...' })
        const payload: ObjectStringKeyAnyValue = {
            action: "processesAdmin",
            subAction: "saveProcess",
            formFields: formFields,
            Id: processDefinitionId
        }
        sendMessageToWebsocket(JSON.stringify(payload))
        const unsubscribe = subscribe("processSaved", data => {
            if (data.Id === processDefinitionId) {
                setShowModal(null)
                //navigate(`/process/${processDefinitionId}/reporting`)
            }
            unsubscribe()
        })
    }




    const validateForm = () => {
        console.log('validating form')
        let isValid = true
        const newErrors: ObjectStringKeyAnyValue = {}

        if (reportingOptionId && formFields.Reports && formFields.Reports[reportingOptionId]) {

            if (!formFields.Reports[reportingOptionId]['DisplayName']) {
                isValid = false
                newErrors[`${reportingOptionId}-DisplayName`] = 'Please enter a display name'
            }

   
            for (const stageId in formFields.Reports[reportingOptionId]['StageOptions']) {
                const checklistsForThisStage = formFields.Stages && formFields.Stages[stageId] && formFields.Stages[stageId].Checklists ? formFields.Stages[stageId].Checklists : {}
                const checklistIdsforThisStage = Object.values(checklistsForThisStage)
                if (checklistsForThisStage && checklistIdsforThisStage.length > 0) {

                    // for each checklist that is included, check it has a cell reference
                    for (const checklistId of checklistIdsforThisStage) {
                        const checklistIdAsString = `${checklistId}`
                        const optionsForThisChecklistId = reportingOptionId && 
                        formFields.Reports[reportingOptionId]['StageOptions'] && 
                        formFields.Reports[reportingOptionId]['StageOptions'][stageId] && 
                        formFields.Reports[reportingOptionId]['StageOptions'][stageId][checklistIdAsString] 

                        if (optionsForThisChecklistId && !optionsForThisChecklistId.CellReference) {
                            isValid = false
                            newErrors[`${reportingOptionId}-${stageId}-${checklistId}`] = 'Please include a cell reference'
                        }
                    }

                }
            }

        } else {
            isValid = false
            console.log('⛔️ Error - missing values')
        }

        setFormErrors(newErrors)
        //return false
        return isValid
    }



    const requestUploadUrl = async () => {
        setShowModal({ "spinner": 'Uploading...' })
        const timestamp = new Date().getTime().toString()
        const filename = `spreadsheet.xlsx`
        const payload = JSON.stringify({
            action: "document",
            subAction: "process_report_template_upload",
            processDefinitionId: processDefinitionId,
            reportingOptionId: reportingOptionId,
            file: filename
        })
        sendMessageToWebsocket(payload)

        const response = await new Promise<void>((resolve, reject) => {
            const unsubscribe = subscribe("processReportTemplateUploadResponse", async data => {

                console.log(`uploading to: ${data.uploadUrl}`)
                unsubscribe()
                const file = new File([fileToUpload], timestamp, {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                })

                await fetch(data.uploadUrl, {
                    method: 'PUT',
                    body: fileToUpload,
                    headers: {
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    },
                })
                    .then(response => {
                        console.log('Success:', response)
                        const payload = JSON.stringify({
                            action: "document",
                            subAction: "process_report_template_upload_2",
                            processDefinitionId: processDefinitionId,
                            reportingOptionId: reportingOptionId,
                            ImageFileName: data.imageFileName
                        })
                        sendMessageToWebsocket(payload)

                        const unsubscribe = subscribe("processReportTemplateUpload2Response", data => {
                            console.log(data)
                            setShowModal(null)
                            //saveItem()
                            unsubscribe()
                        })

                        return response
                    }).catch((error) => {
                        console.error('Error:', error)
                    })
            })
        })
    }


    if ((!tableData.ProcessDefinitions || !thisProcess)) {
        return <div className={`w-full flex flex-col items-center`}>
            <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>
                <Spinner>Loading...</Spinner>
            </div>
        </div>
    }

    return <div className={`w-full flex flex-col items-center mb-6`}>
        <div className={`max-w-3xl w-full p-4 flex flex-col gap-6 items-center justify-between`}>

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <h3 className={`font-righteous text-3xl font-brandblue`}>
                    Reporting for process: {thisProcess.DisplayName}
                </h3>
            </div>

            <div className={`w-full flex flex-col gap-2`}>
                <p className={`font-righteous text-xl font-brandblue`}>Display Name</p>
                <input
                    className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full text-sm px-3 py-2`}
                    type='text'
                    name={`DisplayName`}
                    value={thisReportingOptionFormFields && thisReportingOptionFormFields['DisplayName'] ? thisReportingOptionFormFields['DisplayName'] : ''}
                    onChange={(e) => handleChangeReportingOption('DisplayName', e.target.value)}
                />
                {formErrors[`${reportingOptionId}-DisplayName`] && <RedAlert size={`small`} alignment='left'>{formErrors[`${reportingOptionId}-DisplayName`]}</RedAlert>}
            </div>


            <div className={`w-full flex flex-col gap-2`}>
                <p className={`font-righteous text-xl font-brandblue`}>Custom report template</p>

                <div className={`w-full flex flex-row gap-2`}>

                    <input
                        className={``}
                        type='file'
                        accept=".xls,.xlsx"
                        name={`spreadsheetFile`}
                        id={`spreadsheetFile`}
                        onChange={(e) => setFileToUpload(e!.target!.files![0])}
                    />


                    {thisReportingOptionFormFields && thisReportingOptionFormFields['OriginalFileId'] &&
                        <Button
                            onClick={() => { }}
                            text='Download existing spreadsheet'
                            fullwidth={false}
                        />}
                </div>

                {formErrors[`${reportingOptionId}-File`] && <RedAlert size={`small`} alignment='left'>{formErrors[`${reportingOptionId}-File`]}</RedAlert>}


            </div>



            <div className={`w-full flex flex-col items-center gap-4`}>
                <div className={`w-full flex flex-col items-start gap-4`}>

                    {sortedStageIds.map((stageId: string, index: number) => {
                        return <ProcessReportingOptionsStage
                            stage={thisProcess.Stages[stageId]}
                            stageId={stageId}
                            key={index}
                            formFields={formFields}
                            setFormFields={setFormFields}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                        />
                    })}

                </div>

                {/* {<JsonDebug jsonobject={formFields} />} */}

                <FooterNavButtonFrame>
                    <Button
                        internalLinkUrl={`/process/${processDefinitionId}/reporting/`}
                        text={`Back`}
                        variant={`gray`}
                        size='big'
                    />
                    <Button
                        onClick={submitForm}
                        text={`Save`}
                        size='big'
                    />
                </FooterNavButtonFrame>
            </div>

        </div>
    </div>

}
export default ProcessReportingOptions