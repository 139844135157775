import React, { useState, useEffect, useRef, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import { dateDiff } from '../utils/datediff'
import { getDate } from '../utils/getDate'
import CloudformationOutputs from '../CloudformationOutputs.json'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Button from '../formfields/Button'
import XButton from '../formfields/XButton'
import { pythonDayNumber } from '../utils/pythonDayNumber'
import { validateForm } from '../utils/validateForm'
import SubNavigation from '../shared-components/SubNavigation'



type Props = {
  setTableData: React.Dispatch<React.SetStateAction<any>>
}

type DateRowProps = {
  fridgesInGroup: ObjectStringKeyAnyValue | null
  checkPeriod: any
  isFuture: boolean
  isToday: boolean
}

type ReadingCellProps = {
  checksFromToday: ObjectStringKeyAnyValue
  CheckId: string
  profileId: string
  expectedMinTemp: number | null
  expectedMaxTemp: number | null
}

type NoReadingYetCellProps = {
  assetId: string
  expectedMinTemp: number | null
  expectedMaxTemp: number | null
  startTimeStamp: number
  endTimeStamp: number
  isFuture: boolean
}

interface Check {
  days: string[];
  start: Time;
  end: Time;
}

interface Time {
  hours: string;
  minutes: string;
}

interface CheckPeriod {
  startTime: Date;
  endTime: Date;
  checkId: string;
}

function ReadingCell({
  checksFromToday,
  CheckId,
  profileId,
  expectedMinTemp,
  expectedMaxTemp
}: ReadingCellProps) {
  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)
  const [showDetails, setShowDetails] = useState(false)
  const [isWithinExpectedRange, setIsWithinExpectedRange] = useState(true)
  const userForThisReading = tableData!.Users[checksFromToday[CheckId].UserId]
  const guestUserForThisReading = checksFromToday[CheckId].GuestUserName
  const reading = parseInt(checksFromToday[CheckId].Reading)
  const assetId = checksFromToday[CheckId].AssetId
  const notes = checksFromToday[CheckId].Notes


  useEffect(() => {
    // check if reading is within the expected range for this profile
    if (profileId && reading && expectedMaxTemp !== null && expectedMinTemp !== null) {
      if (reading < expectedMinTemp || reading > expectedMaxTemp) {
        setIsWithinExpectedRange(false)
      }
    }
  }, [])


  return <div
    className={`rounded m-1
    ${isWithinExpectedRange && !notes && 'bg-green-200'}
    ${isWithinExpectedRange && notes && 'bg-yellow-200'}
    ${!isWithinExpectedRange && 'bg-red-100'}
    ${!showDetails && 'hover:opacity-80'}
    `}
    style={{ minWidth: '130px' }}
  >

    <div
      className={`w-full flex flex-row justify-between items-stretch`}
    >
      <h4
        className={`flex-1 text-lg font-bold font-righteous px-2 py-2 cursor-pointer
        ${isWithinExpectedRange && !notes && 'text-green-700 text-center'}
        ${isWithinExpectedRange && notes && 'text-yellow-800'}
        ${!isWithinExpectedRange && 'text-red-700'}
        ${!showDetails && 'hover:opacity-80'}
        `}
        onClick={() => { setShowDetails(true) }}
      >
        {checksFromToday[CheckId].Reading}&deg;
      </h4>

      <div className={`flex flex-row gap-2 justify-end items-center px-2 py-2`}
        onClick={() => { setShowDetails(true) }}>
        {!isWithinExpectedRange && <img
          src='/red-alert-icon.png'
          alt=''
          className={`
        ${!showDetails && 'hover:opacity-80'}
        h-5 w-5 cursor-pointer
        `} />}

        {notes && <p>✏️</p>}
      </div>




      {showDetails === true && <Modal
        setShowModal={setShowDetails}
        showCloseButton={true}
        closeButtonValue={false}
      >
        <div className={`flex flex-col items-center gap-2 text-sm`}>

          <h2 className={`mt-1 text-center text-2xl  font-bold font-righteous text-brandblue`}>
            Reading for {tableData && tableData.Assets[assetId].AssetName} ({assetId})
          </h2>

          <div
            className={``}>
            <p>Reading taken at {getDate(checksFromToday[CheckId].TimeStamp)} {userForThisReading && ` by ${userForThisReading.UserName ? userForThisReading.UserName : userForThisReading.UserEmail}`} {guestUserForThisReading && ` by ${guestUserForThisReading} (guest user)`}
            </p>
          </div>

          {checksFromToday[CheckId].uploadedImagePath && <img
            src={`https://${CloudformationOutputs.ImageBucketName}.s3.${CloudformationOutputs.AWSRegion}.amazonaws.com/${checksFromToday[CheckId].uploadedImagePath}`}
            alt='Photo of temperature reading'
            className={`w-full border border-gray-400 rounded mt-2 mb-2`}
          />}



          {expectedMinTemp !== null && reading < expectedMinTemp && <div
            className={`rounded border border-red-200 p-2 bg-red-100 w-full flex flex-row gap-2 items-start`}
          >
            <img src='/red-alert-icon.png' alt='' className={`w-6 mt-1`} />
            <p>Reading of {reading}&deg; is below minimum expected temperature of {expectedMinTemp}&deg;</p>
          </div>}

          {expectedMaxTemp !== null && reading > expectedMaxTemp && <div
            className={`rounded border border-red-200 p-2 bg-red-100 w-full flex flex-row gap-2 items-start`}
          >
            <img src='/red-alert-icon.png' alt='' className={`w-6 mt-1`} />
            <p>Reading of {reading}&deg; exceeds maximum expected temperature of {expectedMaxTemp}&deg;</p>
          </div>}

          {notes && <div
            className={`rounded border border-yellow-400 p-2 bg-yellow-100 w-full flex flex-row gap-2 items-start`}
          >

            <p>✏️</p>
            <p>{notes}</p>
          </div>}
        </div>
      </Modal>}
    </div>

  </div>
}

function NoReadingYetCell({
  assetId,
  expectedMinTemp,
  expectedMaxTemp,
  startTimeStamp,
  endTimeStamp,
  isFuture
}: NoReadingYetCellProps) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const initialFormData = {
    "Reading": { "value": '', "required": true, "type": "number" },
    "TimeStamp": { "value": '', "required": true, "type": "number" },
    "Notes": { "value": '', "required": false, "type": "text" }
  }
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
  const [showModal, setShowModal] = useState(false)
  const [withinRange, setWithinRange] = useState(true)
  const [savingProgress, setSavingProgress] = useState(false)
  const [manualReadingTimes, setManualReadingTimes] = useState<Array<number>>([])

  useEffect(() => {
    if (formFields && formFields['Reading'] && formFields['Reading']['value']) {
      const reading = parseInt(formFields['Reading']['value'])

      if (isNaN(reading) || (expectedMinTemp && reading < expectedMinTemp) || (expectedMaxTemp && reading > expectedMaxTemp)) {
        setWithinRange(false)
        console.log('❎')
      } else {
        console.log(`OK: ${reading} is between ${expectedMinTemp} and ${expectedMaxTemp}`)
        setWithinRange(true)
      }

    } else {
      setWithinRange(true)
    }
  }, [formFields])

  useEffect(() => {
    if (showModal) {
      populateReadingTimes()
    }
  }, [startTimeStamp, showModal])


  const populateReadingTimes = () => {
    console.log('populating reading times')
    const newReadingTimes: number[] = []
    const increment = 1000 * 60 * 15
    const nowTimestamp = Date.now()
    for (let newTimeStamp = startTimeStamp; newTimeStamp < (endTimeStamp + increment); newTimeStamp += increment) {
      if (newTimeStamp <= nowTimestamp) {
        newReadingTimes.push(newTimeStamp)
      }
    }
    setManualReadingTimes(newReadingTimes)
  }

  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key] = { ...newFieldValues[key], 'value': value }
    setFormFields(newFieldValues)
  }



  const saveData = async () => {
    const reading = formFields.Reading && formFields.Reading.value ? formFields.Reading.value : null
    const userId = userData && userData['cognito:username'] ? userData['cognito:username'] : null
    const timeStamp = formFields.TimeStamp && formFields.TimeStamp.value ? formFields.TimeStamp.value : null
    if (reading && assetId && userId) {
      setFormFields(initialFormData)
      setSavingProgress(true)
      setShowModal(false)
      // for now, set the timestamp to the start of the check period (plus 1 milisecond)
      const assetValues: ObjectStringKeyAnyValue = {
        AssetId: `${assetId}`,
        Reading: `${reading}`,
        manuallyAdded: true,
        TimeStamp: `${timeStamp}`,
        UserId: `${userId}`,
      }
      if (formFields.Notes && formFields.Notes.value) {
        assetValues['Notes'] = formFields.Notes.value
      }
      const payloadObj: { [index: string]: any } = {
        action: "addItemRequest",
        tableName: "Checks",
        values: assetValues
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)

      const unsubscribe = subscribe("tableUpdateItemAdded", data => {
        setSavingProgress(false)
        unsubscribe()
      })

    } else {
      console.log('🚫 must supply reading, asset id and user id')
    }
  }

  return <div
    className={`rounded m-1 flex flex-col items-center`}
    style={{ minWidth: '130px' }}
  >

    {!isFuture && <button
      className={`w-full rounded bg-gray-300 px-2 py-1 text-xs text-gray-600 hover:opacity-80`}
      onClick={() => setShowModal(true)}>➕ Add</button>}



    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>Saving...</p></Spinner>
    </Modal>}

    {showModal === true && <Modal
      setShowModal={setShowModal}
      showCloseButton={true}
      closeButtonValue={false}
    >
      <div className={`flex flex-col gap-4`}>
        <h2 className={`text-xl font-bold $ text-brandblue `}> Add a reading for {assetId}</h2>

        <div className={`flex flex-col gap-2`}>
          <p className={`font-bold text-left`}>Reading:</p>
          {expectedMinTemp != null && expectedMaxTemp != null && <p>Expected value between {expectedMinTemp}&deg; and {expectedMaxTemp}&deg;</p>}
          <input
            type="number"
            onChange={(e) => handleChange('Reading', e.target.value)}
            value={formFields['Reading'] ? formFields['Reading']['value'] : ''}
            className={`w-full bg-gray-200 border border-gray-300 text-gray-900 text-left rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 p-2.5`}
          />

          {formFields['Reading'] && formFields['Reading']['isValid'] === false && <p className={`text-xs text-red-700`}>Please enter a valid reading</p>}


          {!withinRange && <div className={`flex flex-row gap-2 rounded border border-yellow-400 px-2 py-1 bg-yellow-100 text-yellow-800`}>
            <img src={`/tip-icon.png`} className={`w-4 h-4`} alt='' />
            <p className={`text-xs`}>Reading is not within expected range</p>
          </div>}
        </div>



        <div className={`flex flex-col gap-2`}>
          <p className={`font-bold text-left`}>Time taken:</p>
          <select
            onChange={(e) => handleChange('TimeStamp', e.target.value)}
            value={formFields['TimeStamp'] ? formFields['TimeStamp']['value'] : ''}
            className={`w-full mb-1 bg-gray-200 border border-gray-300 text-gray-900 text-left rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 p-2.5`}>
            <option value=''>Please select</option>
            {manualReadingTimes.map((readingTime, index) => {
              return <option key={index} value={readingTime}>{getDate(readingTime, 'justHoursAndMinutes')}</option>
            })}
          </select>
          {formFields['TimeStamp'] && formFields['TimeStamp']['isValid'] === false && <p className={`text-xs text-red-700`}>Please select a number</p>}
        </div>


        <div className={`flex flex-col gap-2`}>
          <p className={`font-bold text-left`}>Note:</p>
          <input
            type="text"
            onChange={(e) => handleChange('Notes', e.target.value)}
            value={formFields['Notes'] ? formFields['Notes']['value'] : ''}
            className={`w-full bg-gray-200 border border-gray-300 text-gray-900 text-left rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 p-2.5`}
          />

          {formFields['Notes'] && formFields['Notes']['isValid'] === false && <p className={`text-xs text-red-700`}>Please enter a note</p>}
        </div>


        <Button
          onClick={(e: any) => validateForm(e, formFields, setFormFields, saveData)}
          text={'Add'}
          variant={'primary'}
        />
      </div>
    </Modal>}

  </div>
}


function DateRow({
  fridgesInGroup,
  checkPeriod,
  isToday,
  isFuture
}: DateRowProps) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)
  const checksFromToday: ObjectStringKeyAnyValue = {}
  const notes: ObjectStringKeyAnyValue = {}
  const startTimeStamp = Date.parse(checkPeriod.startTime)
  const endTimeStamp = Date.parse(checkPeriod.endTime)




  tableData && tableData.Checks && Object.keys(tableData.Checks).forEach((CheckId: string) => {

    const check_is_after_start_time = parseInt(tableData.Checks[CheckId].TimeStamp) >= startTimeStamp
    const check_is_before_end_time = parseInt(tableData.Checks[CheckId].TimeStamp) <= endTimeStamp

    if (check_is_after_start_time && check_is_before_end_time) {
      checksFromToday[CheckId] = tableData.Checks[CheckId]
      if (tableData.Checks[CheckId].Notes) {
        notes[tableData.Checks[CheckId].AssetId] = tableData.Checks[CheckId].Notes
      }
    }
  })


  return <>
    {fridgesInGroup && Object.keys(fridgesInGroup).map((assetId, index) => {

      const checksForThisFridgeToday = Object.values(checksFromToday).filter((check) => check.AssetId === assetId)
      const profileId = tableData!.Assets[assetId].ProfileId || null
      const expectedMaxTemp = tableData!.AssetProfiles[profileId] ? parseInt(tableData!.AssetProfiles[profileId].ExpectedMaxTemp) : null
      const expectedMinTemp = tableData!.AssetProfiles[profileId] ? parseInt(tableData!.AssetProfiles[profileId].ExpectedMinTemp) : null

      return <td className={`border-r border-gray-400 align-top`} key={index}>
        {checksForThisFridgeToday.map((check, index) => {
          return <ReadingCell
            key={index}
            checksFromToday={checksFromToday}
            CheckId={check.Id}
            profileId={profileId}
            expectedMinTemp={expectedMinTemp}
            expectedMaxTemp={expectedMaxTemp}
          />
        })}
        <NoReadingYetCell
          key={index}
          assetId={assetId}
          expectedMinTemp={expectedMinTemp}
          expectedMaxTemp={expectedMaxTemp}
          startTimeStamp={startTimeStamp}
          endTimeStamp={endTimeStamp}
          isFuture={isFuture}
        />
      </td>
    })}
  </>
}

function startAndEndTimesForChecksOnDay(date: Date, checks: Record<string, Check>): CheckPeriod[] {
  const dayNumber = pythonDayNumber(date.getDay());

  let checksToReturn: CheckPeriod[] = [];

  for (const id in checks) {
    if (checks[id].days.includes(dayNumber.toString())) {
      let startTimeToday = timeOnDayAt(date, parseInt(checks[id].start.hours), parseInt(checks[id].start.minutes));
      let endTimeToday = timeOnDayAt(date, parseInt(checks[id].end.hours), parseInt(checks[id].end.minutes));

      if (endTimeToday < startTimeToday) {
        endTimeToday.setDate(endTimeToday.getDate() + 1); // Add one day
      }

      checksToReturn.push({
        startTime: startTimeToday,
        endTime: endTimeToday,
        checkId: id
      });
    }
  }
  return checksToReturn;
}

function getCheckPeriodsForTimeRange(timeToCheck: Date, numberOfDays: number, checks: Record<string, Check>): CheckPeriod[] {
  let allChecks: CheckPeriod[] = [];
  for (let n = 0; n < numberOfDays; n++) {
    let date = new Date(timeToCheck);
    date.setDate(date.getDate() + n);
    let checksForTheDay = startAndEndTimesForChecksOnDay(date, checks);
    allChecks = allChecks.concat(checksForTheDay);
  }

  allChecks.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
  return allChecks;
}

function timeOnDayAt(date: Date, hours: number, minutes: number): Date {
  let newDate = new Date(date);
  newDate.setHours(hours, minutes, 0, 0);
  return newDate;
}



function getCheckPeriodsForDateRange(checksForGroup: any, startDate: any, days: any) {
  //console.log(`Check periods requested for range ${startDate} ${days}`)
  const checks = getCheckPeriodsForTimeRange(new Date(parseInt(startDate)), days, checksForGroup)
  return checks
}



function ListReadings({ setTableData}: Props) {

  const {
      tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)
  let { groupId } = useParams()
  const today = new Date()
  const numberOfDaysToShow = 7
  const numberOfWeeksToShow = 10
  const assetsToCheck = fridges ? Object.keys(fridges) : []
  const [waitingForDataExport, setWaitingForDataExport] = useState(false)
  const [showStartDate, setShowStartDate] = useState<string | null>(null)
  const [fridgesInGroup, setFridgesInGroup] = useState<ObjectStringKeyAnyValue | null>(null)
  const [showGroup, setShowGroup] = useState<string | null>(groupId ? groupId : null)
  const [datesForDropdown, setDatesForDropdown] = useState<Array<string>>([])
  const [datesToShow, setDatesToShow] = useState<Array<string>>([])
  const [checkPeriodsToShow, setCheckPeriodsToShow] = useState<any>([])
  const [checkPeriodsByDay, setCheckPeriodsByDay] = useState<any>([])
  const [scrollPosition, setScrollPosition] = useState(0)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)
  // const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false)
  const scrollContainerRef: any = useRef(null)
  const scrollContainer: any = scrollContainerRef.current




  useEffect(() => {
    // fetchData()

    const datesArray: string[] = []
    for (let i = 1; i <= numberOfWeeksToShow; i++) {
      const dateToShow = new Date(today)
      const daysUntilMonday = 7 - dateToShow.getDay() + 1
      dateToShow.setDate(today.getDate() - (i * 7) + daysUntilMonday)
      dateToShow.setHours(0, 0, 0, 0)
      const dateToShowTimeStamp = Date.parse(`${dateToShow}`)
      datesArray.push(`${dateToShowTimeStamp}`)
    }
    setDatesForDropdown(datesArray)
    setShowStartDate(datesArray[0])
  }, [])



  useEffect(() => {
    if (tableData) {
      if (!tableData.Groups || Object.keys(tableData.Groups).length === 0) {
        // setShowGroup('all')
      }
    }
  }, [tableData])


  useEffect(() => {
    setWaitingForDataExport(false)
    if (showGroup) {
      let fridgesInSelectedGroup: ObjectStringKeyAnyValue = {}
      if (fridges) {
        const arrayOfFridgeValues = Object.values(fridges) as ObjectStringKeyAnyValue[]
        arrayOfFridgeValues.sort((a, b) => a.AssetName.localeCompare(b.AssetName));
        const sortedFridgeData: ObjectStringKeyAnyValue = {};
        arrayOfFridgeValues.forEach(item => {
          sortedFridgeData[item.Id] = item;
        })
        Object.keys(sortedFridgeData).forEach((key, index) => {
          if (fridges[key].GroupId === showGroup || showGroup === 'all') {
            fridgesInSelectedGroup[key] = fridges[key]
          }
        })
      }
      setFridgesInGroup(fridgesInSelectedGroup)
    } else {
      setShowGroup(null)
    }
  }, [showGroup, fridges])



  useEffect(() => {

    if (tableData && showStartDate && showGroup) {
      if (tableData.Checks && Object.keys(tableData.Checks).length === 0) {
        fetchData()
      }
      else if (!tableData.Checks) {
        fetchData()
      }
    } else {
      // console.log(`
      // checks: ${tableData && tableData.Checks && JSON.stringify(tableData.Checks)}
      // showstartdate: ${showStartDate}
      // showGroup: ${showGroup}
      // tableData: ${tableData}
      // `)
    }

    if (showStartDate) {
      const datesToShowArray = []
      for (let i = 0; i <= numberOfDaysToShow - 1; i++) {
        datesToShowArray.push(`${parseInt(showStartDate) + (i * 24 * 60 * 60 * 1000)}`)
      }
      if (tableData && showGroup && tableData['Groups'] && tableData['Groups'][showGroup] && tableData['Groups'][showGroup]['Checks']) {
        const checksForGroup = tableData['Groups'][showGroup]['Checks']
        setCheckPeriodsToShow(getCheckPeriodsForDateRange(checksForGroup, showStartDate, numberOfDaysToShow))
      }
      setDatesToShow(datesToShowArray)
    }
  }, [showStartDate, showGroup, tableData])


  useEffect(() => {
    const newTableData = { ...tableData }
    delete newTableData.Checks
    setTableData(newTableData)
  }, [showStartDate, showGroup])



  useEffect(() => {
    if (tableData && showGroup && tableData['Groups'] && tableData['Groups'][showGroup] && tableData['Groups'][showGroup]['Checks']) {
      const newCheckPeriodsByDay: ObjectStringKeyAnyValue = {}
      datesToShow.forEach(dateTimeStamp => {
        const checksForGroup = tableData['Groups'][showGroup]['Checks']
        const checks = getCheckPeriodsForTimeRange(new Date(parseInt(dateTimeStamp)), 1, checksForGroup)
        newCheckPeriodsByDay[dateTimeStamp] = checks
      })
      setCheckPeriodsByDay(newCheckPeriodsByDay)
    }
  }, [datesToShow, showGroup, tableData])



  useEffect(() => {
    updateScrollStatus()
  }, [scrollPosition, scrollContainer])


  const updateScrollStatus = () => {
    if (scrollContainer) {
      setCanScrollLeft(scrollContainer.scrollLeft > 0)
      setCanScrollRight(scrollContainer.scrollLeft < scrollContainer.scrollWidth - scrollContainer.clientWidth)
    }
  }

  const handleScroll = (scrollAmount: number) => {
    if ((scrollAmount < 0 && canScrollLeft) || (scrollAmount > 0 && canScrollRight)) {
      const newScrollPosition = scrollPosition + scrollAmount
      scrollContainerRef!.current!.scrollLeft = newScrollPosition
      setScrollPosition(newScrollPosition)
    }
  }




  const fetchData = () => {
    const showEndDate = showStartDate && parseInt(showStartDate) + (7 * 24 * 60 * 60 * 1000)
    console.log(`✅ fetching data: ${showStartDate} - ${showEndDate}`)
    const payloadObj: ObjectStringKeyAnyValue = {
      action: "fetchSingleTableData",
      tableName: "Checks",
      assetsToCheck: assetsToCheck,
      startDate: showStartDate,
      endDate: showEndDate,
    }
    if (showGroup) {
      payloadObj['GroupId'] = showGroup
    }
    const payload = JSON.stringify(payloadObj)
    sendMessageToWebsocket(payload)
  }


  const exportData = () => {
    if (showStartDate) {
      setWaitingForDataExport(true)
      //console.log('exporting data')
      let groupName = null
      if (showGroup && tableData && tableData.Groups) {
        groupName = tableData.Groups[showGroup].GroupName
      }
      const payloadObj: ObjectStringKeyAnyValue = {
        action: "document",
        documentType: 'pdf',
        startDate: showStartDate,
        endDate: parseInt(showStartDate) + (7 * 24 * 60 * 60 * 1000),
        groupId: showGroup
      }
      const payload = JSON.stringify(payloadObj)
      //console.log(payload)
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("documentReadyForDownloadResponse", data => {
        setWaitingForDataExport(false)
        window.location.href = data.downloadUrl
        unsubscribe()
      })
    }
  }


  const getEndWeekDate = (timestamp: string) => {
    const endWeekDate = parseInt(timestamp) + (6 * 24 * 60 * 60 * 1000)
    const formattedDate = `${getDate(endWeekDate, 'dayDateMonth')}`
    return formattedDate
  }


  const isFirstOrLastCheck = (start_date_timestamp: number, checkPeriod: ObjectStringKeyAnyValue) => {
    const checksInSamePeriod = checkPeriodsByDay[start_date_timestamp]
    let numberOfChecksInSamePeriod = checksInSamePeriod ? checksInSamePeriod.length : 1
    let checkIndex = 1
    let isFirstCheck = false
    let isLastCheck = false
    checksInSamePeriod && checksInSamePeriod.forEach((check: any) => {
      if (check.checkId === checkPeriod.checkId) {
        if (checkIndex === 1) {
          isFirstCheck = true
        }
        if (checkIndex === checksInSamePeriod.length) {
          isLastCheck = true
        }
      }
      checkIndex++
    })
    return { numberOfChecksInSamePeriod, isFirstCheck, isLastCheck }
  }




  return <div className={`flex flex-col items-center`}>


    <SubNavigation
      appName={'fridges'}
      fullWidthStrip={true}
    />


    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-center`}>
      <div className={`w-full flex flex-row justify-between items-center`}>
        <h2 className={`text-3xl  font-bold font-righteous text-brandblue`}>
          Fridge/Freezer temperature readings
        </h2>


        {showGroup && fridgesInGroup && !waitingForDataExport && Object.keys(fridgesInGroup).length > 0 && <Button
          onClick={exportData}
          text={'Export to PDF'}
          variant={'primary'}
          fullwidth={false}
        />}


        {waitingForDataExport && <div className={`bg-brandblue  text-white font-bold py-1 px-4 rounded hover:opacity-90`}><Spinner size={'small'}><p>Exporting...</p></Spinner></div>}


      </div>

      <div className={`w-full flex flex-row items-end gap-6`}>

        {tableData && tableData.Groups && Object.keys(tableData.Groups).length > 0 && <div className={`flex flex-col items-start gap-2 `}>
          <p className={`text-xs`}>Show group:</p>
          <select
            className={`w-full bg-white border border-gray-300 text-gray-900 text-left rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 text-sm`}
            value={showGroup || ''}
            onChange={(e) => setShowGroup(e.target.value)}
          >
            <option value={''}>Please select</option>
            {Object.keys(tableData.Groups).map((GroupId: string, index: number) => {
              return <option key={index} value={GroupId}>
                {tableData.Groups[GroupId].GroupName}
              </option>
            })}
          </select>
        </div>}

        <div className={`flex flex-col items-start gap-2`}>
          <p className={`text-xs`}>Date range:</p>
          <select
            className={`w-full bg-white border border-gray-300 text-gray-900 text-left rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 text-sm`}
            value={showStartDate || ''}
            onChange={(e) => setShowStartDate(e.target.value)}
          >
            {datesForDropdown.map((timestamp: string, index: number) => {
              return <option value={timestamp} key={index}>
                {getDate(timestamp, 'dayDateMonth')} - {getEndWeekDate(timestamp)}
              </option>
            })}
          </select>
        </div>


        {showStartDate !== datesForDropdown[0] && <Button
          onClick={() => setShowStartDate(datesForDropdown[0])}
          text={'Back to this week'}
          variant={'primary'}
          fullwidth={false}
        />}
      </div>



  
      {tableData && showGroup && !tableData.Checks && <Spinner>Loading...</Spinner>}


      {tableData && !showGroup && <div className={`w-full rounded p-4 bg-white flex flex-row items-center gap-2`}>
        <img src='/tip-icon.png' alt='' className={`h-6`} />
        <p>Please select a group and date range to display</p>
      </div>}

      {tableData && showGroup && fridgesInGroup && Object.keys(fridgesInGroup).length === 0 && <div className={`w-full rounded p-4 bg-white flex flex-row items-center gap-2`}>
        <img src='/tip-icon.png' alt='' className={`h-6`} />
        <p>No fridges to display in this group</p>
      </div>}
    </div>

    {tableData && tableData.Checks && fridgesInGroup && showGroup && Object.keys(fridgesInGroup).length > 0 && <div
      className={`px-3`}
      style={{ maxWidth: '100%' }}
    >
      <div className={`border border-gray-200 p-4 bg-white rounded shadow`}>

        <p className={`mb-4 text-center`}>
          Click on any reading to view further details
          </p>

        {/* <p>{canScrollLeft ? 'can left' : 'cant left'}</p>
        <p>{canScrollRight ? 'can Right' : 'cant Right'}</p> */}

        <div className={`w-full flex flex-row gap-2 items-center`}>

          <button
            className={`${canScrollLeft ? 'bg-brandblue text-white' : 'bg-gray-300 text-gray-600'} rounded p-2 font-bold`}
            onClick={() => handleScroll(-50)}
            disabled={!canScrollLeft}>
            &lt;</button>

          <div className={`border border-2 border-brandblue relative overflow-hidden rounded`} ref={scrollContainerRef}>
            <table className={`w-full`}>
              <tbody>
                <tr className={`p-2 bg-brandblue font-bold text-white`}>
                  <td><p className={`text-sm text-center`}>Date</p></td>
                  <td className={`p-2`}><p className={`text-sm text-center`}>Time</p></td>
                  {fridgesInGroup && Object.keys(fridgesInGroup).map((key, index) => {
                    return <td key={index} className={`p-2`}>
                      <p className={`text-sm text-center`}>{fridgesInGroup[key].AssetName}</p>
                      <p className={`text-xs text-center font-normal`}>{key}</p>
                    </td>
                  })}

                </tr>


                {checkPeriodsToShow.map((checkPeriod: any, index: any) => {
                  const startTimeStamp = Date.parse(checkPeriod.startTime)
                  const endTimeStamp = Date.parse(checkPeriod.endTime)
                  const start_day = getDate(startTimeStamp, 'day')
                  const start_month = getDate(startTimeStamp, 'month')
                  const start_year = getDate(startTimeStamp, 'year')
                  const end_day = getDate(endTimeStamp, 'day')
                  const start_date = new Date(`${start_day} ${start_month} ${start_year}`)
                  const start_date_timestamp = start_date.getTime()
                  const { numberOfChecksInSamePeriod, isFirstCheck, isLastCheck } = isFirstOrLastCheck(start_date_timestamp, checkPeriod)
                  const nowTimestamp = Date.now()
                  const todayStart = new Date();
                  todayStart.setHours(0, 0, 0, 0);
                  const todayStartTimestamp = todayStart.getTime()
                  const thisPeriodStartTimestamp = Math.floor(start_date.getTime())
                  const isToday = thisPeriodStartTimestamp === todayStartTimestamp ? true : false
                  const isFuture = nowTimestamp < startTimeStamp ? true : false
                  const columns = Object.keys(fridgesInGroup).length + 2
                  const isInProgress = nowTimestamp >= startTimeStamp && nowTimestamp <= endTimeStamp ? true : false


                  let date_label = []
                  if (start_day === end_day) {
                    // e.g. 0900-18:00
                    date_label = [`${getDate(startTimeStamp, 'justHoursAndMinutes')} - ${getDate(endTimeStamp, 'justHoursAndMinutes')}`]
                  } else {
                    // e.g. 23:00 - sun 11 jan 02:00
                    date_label = [`${getDate(startTimeStamp, 'shortDateAndTime')}`, `${getDate(endTimeStamp, 'shortDateAndTime')}`]
                  }


                  return <tr key={index} className={`p-2 border-t border-gray-400`}>
                    {isFirstCheck && <td
                      className={`bg-gray-200  text-gray-700 w-24 p-2 text-md whitespace-nowrap`}
                      rowSpan={numberOfChecksInSamePeriod}
                    >
                      <span className={`font-bold font-righteous`}>{getDate(startTimeStamp, 'dayDateMonth')}</span>

                      {isToday && <div className={`mt-2 rounded p-1 bg-brandblue text-white text-center text-xs`}>Today</div>}

                    </td>}
                    <td className={`bg-gray-200  text-gray-700 w-24 p-2 border border-gray-400 text-gray-700`}>
                      {date_label.map((text, index) => {
                        return <p key={index} className={`text-sm text-center whitespace-nowrap`}>{text}</p>
                      })}

                      {isInProgress && <div className={`mt-2 rounded p-1 bg-brandblue text-white text-center text-xs`}>In progress</div>}
                    </td>
                    <DateRow
                      fridgesInGroup={fridgesInGroup}
                      checkPeriod={checkPeriod}
                      isToday={isToday}
                      isFuture={isFuture}
                    ></DateRow>
                  </tr>
                })}
              </tbody>
            </table>
          </div>


          <button
            className={`${canScrollRight ? 'bg-brandblue text-white' : 'bg-gray-300 text-gray-600'} rounded p-2 font-bold`}
            onClick={() => handleScroll(50)}
            disabled={!canScrollRight}>
            &gt;</button>

        </div>



      </div>

    </div>}





  </div >
}
export default ListReadings