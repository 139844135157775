
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'



type Props = {
    children: any
}

function FooterNavButtonFrame({
    children
}: Props) {

    const {
        isVideoMode
    } = useContext(GlobalContext)


    return <div className={`
  ${isVideoMode ? '' : ' border-t-2 border-gray-300 bg-white '} 
  fixed bottom-0 w-full flex flex-col items-center justify-center `}>
        <div className={`max-w-3xl w-full px-5 py-3 flex flex-row gap-5`}>
            {children}
        </div>
    </div>
}
export default FooterNavButtonFrame