import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Text from '../formfields/Text'
import { validateForm } from '../utils/validateForm'
import { removeNonAlphanumeric } from '../utils/removeNonAlphanumeric'
import Dropdown from '../formfields/Dropdown'
import YellowAlert from '../ui-elements/YellowAlert'
import AssociatedItems from '../ui-elements/AssociatedItems'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import AddButton from '../formfields/AddButton'
import Card from '../ui-elements/Card'
import DeleteButton from '../formfields/DeleteButton'
import NextSteps from './NextSteps'

type Props = {
    setFormFields: React.Dispatch<React.SetStateAction<any>>
    setFormErrors: React.Dispatch<React.SetStateAction<any>>
    formFields: ObjectStringKeyAnyValue
    stageId: string,
    nextStageId: string | null,
    formErrors: ObjectStringKeyAnyValue
    isFirst: boolean
    isLast: boolean
    repositionItem: AnyFunction
}

function AddEditStage({
    setFormFields,
    setFormErrors,
    formFields,
    stageId,
    nextStageId,
    formErrors,
    isFirst,
    isLast,
    repositionItem
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)

    const thisStage = formFields && formFields['Stages'] && formFields['Stages'][stageId]
    const thisStageErrors = formErrors && formErrors['Stages'] && formErrors['Stages'][stageId]
    const nextStage = nextStageId && formFields['Stages'][nextStageId]
    const [showNextStepsBuilder, setShowNextStepsBuilder] = useState(false)
    const [showNextStageDropdown, setShowNextStageDropdown] = useState(false)
    const [checklistToAdd, setChecklistToAdd] = useState<string>('')

    const deleteStage = (stageId: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        delete newFieldValues['Stages'][stageId]
        setFormFields(newFieldValues)
    }

    const handleToggleChecklist = (checklistId: string) => {
        if (checklistId) {
            const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
            if (!newFieldValues['Stages'][stageId]['Checklists']) {
                newFieldValues['Stages'][stageId]['Checklists'] = []
            }
            if (
                newFieldValues['Stages'] &&
                newFieldValues['Stages'][stageId] &&
                newFieldValues['Stages'][stageId]['Checklists'] &&
                newFieldValues['Stages'][stageId]['Checklists'].includes(checklistId)) {
                newFieldValues['Stages'][stageId]['Checklists'] = newFieldValues['Stages'][stageId]['Checklists'].filter((id: string) => id !== checklistId)
            }
            else {
                newFieldValues['Stages'][stageId]['Checklists'].push(checklistId)
            }
            setFormFields(newFieldValues)
            setChecklistToAdd('')
        }

    }



    const handleChangeStage = (key: string, value: any) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        newFieldValues['Stages'][stageId][key] = value
        if (key === 'Behaviour') {
            delete newFieldValues['Stages'][stageId]['NextSteps']
            delete newFieldValues['Stages'][stageId]['NextStage']
        }
        if (key === 'Behaviour' && value === 'fixed') {
            newFieldValues['Stages'][stageId]['RepeatAfterMinutes'] = null
        }
        setFormFields(newFieldValues)
    }


    const addNextStep = () => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        const newAnswerActionId = `ns-${Date.now()}-${Math.round(Math.random() * 1000)}`
        if (!newFieldValues['Stages'][stageId]['NextSteps']) {
            newFieldValues['Stages'][stageId]['NextSteps'] = {}
        }
        newFieldValues['Stages'][stageId]['NextSteps'][newAnswerActionId] = {}
        setFormFields(newFieldValues)
    }


    return <Card fullwidth={true}>

        <div className={`w-full flex flex-col justify-between items-start gap-4`}>

            <div className={`w-full justify-between items-center flex flex-row gap-1`}>

                <h4 className={`font-righteous text-2xl font-brandblue`}>Edit stage</h4>
                <div className={`justify-end text-xs text-white flex flex-row gap-1 font-bold`}>

                    {!(isFirst && isLast) && <div className={`flex flex-row gap-1`}>

                        <div
                            className={`${isFirst ? `opacity-50` : ` hover:opacity-80 cursor-pointer `}
                        w-full flex flex-col items-center justify-center bg-gray-500 rounded px-2 py-1 `}
                            onClick={() => !isFirst && repositionItem(stageId, 'up')}
                        >▲</div>
                        {/* <div className={`rounded px-1 py-1 text-gray-500`}>
                        {thisStage['Order']}
                    </div> */}
                        <div
                            className={`${isLast ? `opacity-50` : ` hover:opacity-80 cursor-pointer `}
                        w-full flex flex-col items-center justify-center bg-gray-500 rounded px-2 py-1 `}
                            onClick={() => !isLast && repositionItem(stageId, 'down')}
                        >▼</div>
                    </div>}
                    <DeleteButton
                        deleteFunction={deleteStage}
                        functionParams={stageId}
                        fullwidth={false}
                        size='small'
                    />
                </div>
            </div>


            <div className={`w-full flex flex-col items-start gap-4`}>


                <div className={`w-full flex flex-col gap-2`}>
                    <p className={`font-bold`}>Stage Name</p>
                    <input
                        className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                        type='text'
                        name={`DisplayName`}
                        id={`DisplayName`}
                        value={thisStage[`DisplayName`] ? thisStage[`DisplayName`] : ''}
                        onChange={(e) => handleChangeStage(`DisplayName`, e.target.value)}
                    />

                    {formErrors[`stage-${stageId}-DisplayName`] && <RedAlert size={`small`} alignment='left' fullwidth={true}>{formErrors[`stage-${stageId}-DisplayName`]}</RedAlert>}
                </div>








                <div className={`w-full flex flex-col gap-2`}>
                    <p className={`font-bold`}>Behaviour</p>

                    <div className={`w-full flex flex-row gap-2`}>
                        <Button
                            onClick={(e: any) => handleChangeStage(`Behaviour`, 'fixed')}
                            text={`Move to next stage when questions complete`}
                            variant={thisStage['Behaviour'] === 'fixed' ? `primary` : `gray`}
                        />
                        <Button
                            onClick={(e: any) => handleChangeStage(`Behaviour`, 'ongoing')}
                            text={`Remain in this stage (ongoing)`}
                            variant={thisStage['Behaviour'] === 'ongoing' ? `primary` : `gray`}
                        />
                    </div>


                    {formErrors[`stage-${stageId}-Behaviour`] && <RedAlert size={`small`} alignment='left' fullwidth={true}>{formErrors[`stage-${stageId}-Behaviour`]}</RedAlert>}
                </div>





                <div className={`w-full flex flex-col gap-2`}>
                    <p className={`font-bold`}>Checklists in this stage</p>


                    <div className={`w-full flex flex-col md:flex-row gap-2 items-start`}>
                        <div className={`md:w-1/3 w-full flex flex-row gap-2`}>
                            <select
                                className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                                onChange={(e) => setChecklistToAdd(e.target.value)}
                            >

                                <option value={''}>Please select</option>
                                {tableData && tableData.Checklists && Object.keys(tableData.Checklists).map((checklistId: string, index: number) => {
                                    const thisChecklist = tableData.Checklists[checklistId]
                                    if (thisChecklist.isChecklistActive === false) return null
                                    if (formFields['Stages'][stageId] && formFields['Stages'][stageId]['Checklists'] && formFields['Stages'][stageId]['Checklists'].includes(checklistId)) return null
                                    return <option key={index} value={checklistId}> {thisChecklist.ChecklistName || 'Unnamed checklist'}</option>
                                })}
                            </select>
                            <Button
                                onClick={(e: any) => handleToggleChecklist(checklistToAdd)}
                                text={`Add`}
                                fullwidth={false}
                                disabled={!checklistToAdd}
                                variant={checklistToAdd ? `primary` : `gray`}
                            />
                        </div>

                        <div className={`flex flex-col gap-2`}>
                            {formFields['Stages'][stageId] && formFields['Stages'][stageId]['Checklists'] && formFields['Stages'][stageId]['Checklists'].map((checklistId: string, index: number) => {
                                const thisChecklist = tableData.Checklists[checklistId]
                                return <div key={index}
                                    className={`w-full flex flex-row items-center gap-3 text-sm pl-1 pr-2 pr-1 py-1 bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded`
                                    }>
                                    < button
                                        onClick={(e: any) => handleToggleChecklist(checklistId)}
                                        className={`cursor-pointer rounded bg-gray-300 w-6 h-6 font-white flex items-center justify-center hover:opacity-80`}
                                    >✕</button>
                                    {thisChecklist && thisChecklist.ChecklistName || 'Unnamed checklist'}
                                </div>
                            })}
                        </div>

                    </div>

                    {formErrors[`stage-${stageId}-Checklists`] && <RedAlert size={`small`} alignment='left' fullwidth={true}>{formErrors[`stage-${stageId}-Checklists`]}</RedAlert>}
                </div>

                {thisStage['Behaviour'] === 'ongoing' && <div className={`w-full flex flex-col gap-2`}>
                    <p className={`font-bold`}>Carry out checks every:</p>
                    <div className={`w-full flex flex-row flex-wrap gap-2`}>
                        <Button
                            onClick={(e: any) => handleChangeStage(`RepeatAfterMinutes`, 30)}
                            text={`Half hour`}
                            variant={parseInt(thisStage['RepeatAfterMinutes']) === 30 ? `primary` : `gray`}
                            fullwidth={false}
                        />
                        <Button
                            onClick={(e: any) => handleChangeStage(`RepeatAfterMinutes`, 60)}
                            text={`Hour`}
                            variant={parseInt(thisStage['RepeatAfterMinutes']) === 60 ? `primary` : `gray`}
                            fullwidth={false}
                        />
                        <Button
                            onClick={(e: any) => handleChangeStage(`RepeatAfterMinutes`, 120)}
                            text={`Two hours`}
                            variant={parseInt(thisStage['RepeatAfterMinutes']) === 120 ? `primary` : `gray`}
                            fullwidth={false}
                        />
                    </div>
                    {formErrors[`stage-${stageId}-RepeatAfterMinutes`] && <RedAlert size={`small`} alignment='left' fullwidth={true}>{formErrors[`stage-${stageId}-RepeatAfterMinutes`]}</RedAlert>}
                </div>}

                <div className={`w-full flex flex-col gap-2`}>
                    <p className={`font-bold`}>Next step(s):</p>

                    {/* {JSON.stringify(thisStage['NextSteps'])} */}
                    {!thisStage['NextSteps'] && !thisStage['NextStage'] && !showNextStageDropdown && <div className={`w-full flex flex-row flex-wrap gap-4 items-center`}>

                        <p>
                            By default, this stage will
                            {nextStage && ` proceed to stage: ${nextStage['DisplayName'] || `next stage in sequence`}.`}
                            {!nextStage && ` complete the process.`}
                        </p>


                        {thisStage['Behaviour'] === 'ongoing' && <Button
                            onClick={(e: any) => {
                                addNextStep()
                            }}
                            text={`Customise`}
                            fullwidth={false}
                        />}

                        {thisStage['Behaviour'] !== 'ongoing' && <Button
                            onClick={(e: any) => setShowNextStageDropdown(true)}
                            text={`Select a different stage`}
                            fullwidth={false}
                        />}
                    </div>}

                    {(showNextStageDropdown || thisStage['NextStage'])&& <div className={`w-full flex flex-row gap-2 items-end`}>
                        <div className={`flex flex-col gap-2`}>
                            <p className={`text-sm`}>Proceed to the following stage:</p>
                            <select
                                className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                                onChange={(e) => handleChangeStage(`NextStage`, e.target.value)}
                                value={thisStage && thisStage['NextStage'] || ''}
                            >
                                <option value={''}>Please select:</option>
                                {formFields['Stages'] && Object.keys(formFields['Stages']).map((thisStageId: string, index: number) => {
                                    const displayName = formFields['Stages'][thisStageId]['DisplayName'] || `Stage Id ${thisStageId}`
                                    if (thisStageId === stageId) {
                                        return null
                                    }
                                    return <option key={index} value={thisStageId}>{displayName || thisStageId}</option>
                                })}
                            </select>
                        </div> 
                    </div>}




                    {thisStage['NextSteps'] && <NextSteps
                        formFields={formFields}
                        formErrors={formErrors}
                        stageId={stageId}
                        thisStage={thisStage}
                        setFormFields={setFormFields}
                        setShowNextStepsBuilder={setShowNextStepsBuilder}
                        setFormErrors={setFormErrors}
                        addNextStep={addNextStep}
                    />}
                </div>






            </div>


        </div >
    </Card >

}
export default AddEditStage