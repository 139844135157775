export const startNewQuestion = (
  checklistId: any,
  setChecklistQuestionFields: any,
  navigate: any,
  parentQuestionId: any = null,
  actionId: any = null
) => {
  const randomId = `question-${Date.now()}-${Math.ceil(Math.random() * 10000)}`


  setChecklistQuestionFields(null)

  let redirectUrl = `/checklists/${checklistId}/questions/${randomId}/`
  if (parentQuestionId && actionId) {
    redirectUrl = `/checklists/${checklistId}/questions/${randomId}/${parentQuestionId}/${actionId}/`
  }
  navigate(redirectUrl)
}

