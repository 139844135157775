export const getThingsThatUseThisItem = (keyName, itemId, tableData, itemType) => {
    // console.log(`keyName: ${keyName}, itemId: ${itemId}, tableData: ${tableData}`)
    const items = []
    if (keyName && itemId && tableData) {

        if (itemType === 'checklist' && tableData.Checklists) {
            Object.keys(tableData.Checklists).forEach(checklistId => {
                const thisChecklist = tableData.Checklists[checklistId]
                const checklistName = thisChecklist?.ChecklistName || ''
                if (thisChecklist && thisChecklist[keyName] === itemId && !items.find((itemInArray) => itemInArray.Id === checklistId)) {
                    items.push({ Id: checklistId, DisplayName: checklistName })
                }
            })
            console.log(items)
        }

        if (itemType === 'checklistQuestion' && tableData.Checklists && tableData.ChecklistQuestions) {
            Object.keys(tableData.ChecklistQuestions).forEach(checklistQuestionId => {
                const thisQuestion = tableData.ChecklistQuestions[checklistQuestionId]
                const checklistId = thisQuestion.ChecklistId
                const thisChecklist = tableData.Checklists[checklistId]
                const checklistName = thisChecklist?.ChecklistName || ''
                if (checklistName && thisQuestion[keyName] === itemId && !items.find((itemInArray) => itemInArray.Id === checklistId)) {
                    items.push({ Id: checklistId, DisplayName: checklistName })
                }
            })
        }

        if (itemType === 'asset' && tableData.Assets){
            Object.keys(tableData.Assets).forEach(assetId => {
                const thisAsset = tableData.Assets[assetId]
                const assetName = thisAsset?.AssetName || ''
                if (assetName && thisAsset[keyName] === itemId && !items.find((itemInArray) => itemInArray.Id === assetId)) {
                    items.push({ Id: assetId, DisplayName: assetName })
                }
            })

        }


    }
    return items
}