

export const checkifAnswerIsDuplicated = (answerActionId, thisAnswerAction, allAnswerActions) => {
    let isAnswerDuplicated = false
    if (
        thisAnswerAction['ActionForAnswer'] &&
        thisAnswerAction['ActionToTake']
    ) {
        for (const answerActionIdToCheck in allAnswerActions) {
            const answerActionToCheck = allAnswerActions[answerActionIdToCheck]
            if (
                (
                    (answerActionToCheck['ActionToTake'] === 'followUpQuestion' &&
                        thisAnswerAction['ActionToTake'] === 'followUpQuestion')
                    ||
                    (answerActionToCheck['ActionToTake'] !== 'followUpQuestion' &&
                        thisAnswerAction['ActionToTake'] !== 'followUpQuestion')
                ) &&
                answerActionIdToCheck !== answerActionId &&
                answerActionToCheck['ActionForAnswer'] &&
                answerActionToCheck['ActionToTake'] &&
                Object.keys(thisAnswerAction['ActionForAnswer']).length > 0 &&
                Object.keys(answerActionToCheck['ActionForAnswer']).length > 0 &&
                JSON.stringify(thisAnswerAction['ActionForAnswer']) === JSON.stringify(answerActionToCheck['ActionForAnswer'])

            ) {
                isAnswerDuplicated = true
            }
        }
    }
    return isAnswerDuplicated
}



export const validateChecklistQuestionsForm = (questionsFormFields, setQuestionsFormErrors) => {
    let isFormValid = true
    //console.log('validating')
    const newQuestionsFormErrors = {}
    const isQuestionFormatNumeric = questionsFormFields['QuestionFormat'] === 'PhotoAndReading' || questionsFormFields['QuestionFormat'] === 'FridgeTemperature' || questionsFormFields['QuestionFormat'] === 'Number' ? true : false

    // check if there is a valid checklist text
    if (
        !questionsFormFields['QuestionFormat']
    ) {
        newQuestionsFormErrors['QuestionFormat'] = 'Please select a question format'
        isFormValid = false
    }

    // check if there is a valid checklist text (not required if the question format is a fridge temperature)
    if (
        !questionsFormFields['QuestionText'] &&
        (
            questionsFormFields['QuestionFormat'] !== 'AssetReading' && 
            questionsFormFields['QuestionFormat'] !== 'FridgeTemperature' && 
            questionsFormFields['QuestionFormat'] !== 'PhotoAndReading'
        )
    ) {
        newQuestionsFormErrors['QuestionText'] = 'Please enter some text'
        isFormValid = false
    }

    // if format is multi choice, ensure there are at least two options
    if (
        (!questionsFormFields['QuestionOptions'] || questionsFormFields['QuestionOptions'].length < 2) &&
        (questionsFormFields['QuestionFormat'] === 'SelectOne' || questionsFormFields['QuestionFormat'] === 'SelectMultiple')
    ) {
        newQuestionsFormErrors['QuestionOptions'] = 'Please add at least 2 options'
        isFormValid = false
    }

    

    // if it's a temperature check, ensure there is a fridge id
    if (
        !questionsFormFields['FridgeId'] &&
        (
            questionsFormFields['QuestionFormat'] === 'FridgeTemperature' 

        )
    ) {
        newQuestionsFormErrors['FridgeId'] = 'Please select a fridge'
        isFormValid = false
    }
    

    // if it's an asset question, ensure there is an asset id
    if (
        !questionsFormFields['AssetId'] &&
        (
            questionsFormFields['QuestionFormat'] === 'AssetReading' || 
            questionsFormFields['QuestionFormat'] === 'PhotoAndReading'

        )
    ) {
        newQuestionsFormErrors['AssetId'] = 'Please select an asset'
        isFormValid = false
    }



    // if it's a custom data format, ensure there is a fridge id
    if (
        !questionsFormFields['CustomDataFormatId'] &&
      questionsFormFields['QuestionFormat'] === 'CustomDataFormat'
    ) {
        newQuestionsFormErrors['CustomDataFormatId'] = 'Please select a custom data format'
        isFormValid = false
    }


    // if there are answer actions
    if (
        questionsFormFields['AnswerActions'] &&
        Object.keys(questionsFormFields['AnswerActions']).length > 0
    ) {



        for (const answerActionId in questionsFormFields['AnswerActions']) {
            const thisAnswerAction = questionsFormFields['AnswerActions'][answerActionId]
            const errorFieldName = `AnswerAction-${answerActionId}`


            // check it doesn't match any other actions
            const isAnswerDuplicated = checkifAnswerIsDuplicated(answerActionId, thisAnswerAction, questionsFormFields['AnswerActions'])
            if (isAnswerDuplicated) {
                newQuestionsFormErrors[errorFieldName] = `
                ${newQuestionsFormErrors[errorFieldName] || ''}
                There is more than one action for this answer. `
                isFormValid = false
            }


            // if it's a fridge temp, check that there is a min and max
            if (
                (
                    questionsFormFields['QuestionFormat'] === 'FridgeTemperature' || 
                    questionsFormFields['QuestionFormat'] === 'PhotoAndReading'
                )
                     &&
                (
                    !thisAnswerAction['ActionForAnswer'] ||
                    !thisAnswerAction['ActionForAnswer']['min'] ||
                    !thisAnswerAction['ActionForAnswer']['max'])
            ) {
                newQuestionsFormErrors[errorFieldName] = `
                ${newQuestionsFormErrors[errorFieldName] || ''}
                Please include a minimum and maximum temperature. `
                isFormValid = false
            }

            // if it's a numeric, check that maximum temp exceeds minimum
            if (
                isQuestionFormatNumeric &&
                thisAnswerAction['ActionForAnswer'] &&
                thisAnswerAction['ActionForAnswer']['min'] &&
                thisAnswerAction['ActionForAnswer']['max'] &&
                (parseInt(thisAnswerAction['ActionForAnswer']['min']) >= parseInt(thisAnswerAction['ActionForAnswer']['max']))
            ) {
                newQuestionsFormErrors[errorFieldName] = `
                ${newQuestionsFormErrors[errorFieldName] || ''}
                Maximum temperature must be greater than minimum temperature.`
                isFormValid = false
            }

            // if it's not numeric, check that an answer is selected
            if (
                !isQuestionFormatNumeric &&
                (
                    !thisAnswerAction['ActionForAnswer'] ||
                    !thisAnswerAction['ActionForAnswer']['equals'])
            ) {
                newQuestionsFormErrors[errorFieldName] = `
                ${newQuestionsFormErrors[errorFieldName] || ''}
                Please select an answer.`
                isFormValid = false
            }

            // ensure an action is selected
            if (
                (!thisAnswerAction['ActionToTake'])
            ) {
                newQuestionsFormErrors[errorFieldName] = `
                ${newQuestionsFormErrors[errorFieldName] || ''} 
                Please select a follow up action. `
                isFormValid = false
            }


        }


    }

    // if there is a repeat question 
    if (questionsFormFields['RepeatQuestion']) {

        // if time delay, ensure at least one is selected
        if (
            questionsFormFields['RepeatQuestion']['timeDelay'] &&
            questionsFormFields['RepeatQuestion']['timeDelay'].length < 1 
        ) {
            newQuestionsFormErrors['RepeatQuestion'] = 'Please select at least one time delay'
            isFormValid = false
        }

        // if time range, check there is a min and max and that min does not exceed max
        if (questionsFormFields['RepeatQuestion']['numberOfTimes']) {

            const maxNumberOfRepetitions = 40

            if (
                !questionsFormFields['RepeatQuestion']['numberOfTimes']['min'] ||
                !questionsFormFields['RepeatQuestion']['numberOfTimes']['max']
            ) {
                newQuestionsFormErrors['RepeatQuestion'] = 'Please enter the number of times the question can repeat'
                isFormValid = false
            } else if (
                questionsFormFields['RepeatQuestion']['numberOfTimes']['min'] > questionsFormFields['RepeatQuestion']['numberOfTimes']['max']
            ){
                newQuestionsFormErrors['RepeatQuestion'] = 'Minimum value must not exceed maximum value'
                isFormValid = false
            }

            if (
                questionsFormFields['RepeatQuestion']['numberOfTimes']['min'] > maxNumberOfRepetitions ||
                questionsFormFields['RepeatQuestion']['numberOfTimes']['max'] > maxNumberOfRepetitions
            ) {
                newQuestionsFormErrors['RepeatQuestion'] = `Max number of repetitions is ${maxNumberOfRepetitions}`
                isFormValid = false
            }

        }
    }

    // setQuestionsFormFields(questionsFormFields)
    setQuestionsFormErrors(newQuestionsFormErrors)
    // console.log(newQuestionsFormErrors)
    // return false
    return isFormValid
}
