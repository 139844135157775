export const questionFormats: ObjectStringKeyAnyValue = {
    // "AssetReading": { "displayName": "Asset reading" },
    "PhotoAndReading": { "displayName": "Asset photo and Reading" },
    "CustomDataFormat": { "displayName": "Custom data format" },
    "MultipleTextFields": { "displayName": "Multiple text fields" },
    "Number": { "displayName": "Number" },
    "Photo": { "displayName": "Photo" },
    "SelectMultiple": { "displayName": "Multi choice list (select multiple)" },
    "SelectOne": { "displayName": "Multi choice list (select one)" },
    "Text": { "displayName": "Text" },
    "YesOrNo": { "displayName": "Yes or No" },
}