import React, { useContext, useState, useEffect } from 'react'
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import AssetTypeSingleAction from './AssetTypeSingleAction'
import RedAlert from '../ui-elements/RedAlert'


type Props = {
    formFields: ObjectStringKeyAnyValue
    formErrors: ObjectStringKeyAnyValue
    setFormFields: React.Dispatch<React.SetStateAction<any>>
    setFormErrors: React.Dispatch<React.SetStateAction<any>>
    propertyKey: string
    // showCancelAnswerActionsButton: boolean
}

function AssetTypeActions({
    formFields,
    formErrors,
    setFormFields,
    setFormErrors,
    propertyKey
    // showCancelAnswerActionsButton
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)

    const [answerActionKeys, setShowAnswerActionKeys] = useState<string[]>([])

    useEffect(() => {
        let answerActionKeysArray = formFields['AnswerActions'] ? Object.keys(formFields['AnswerActions']) : []
        if (propertyKey) {
            answerActionKeysArray = answerActionKeysArray.filter((key: string) => {
                // console.log(`does ${formFields['AnswerActions'][key]['PropertyKey']} contain ${propertyKey}?`)
                return formFields['AnswerActions'][key]['PropertyKey'] === propertyKey
            })
        }
        setShowAnswerActionKeys(answerActionKeysArray)
    }, [formFields])



    const addAnswerAction = () => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        const newAnswerActionId = `aa-${Date.now()}-${Math.round(Math.random() * 1000)}`
        if (!newFieldValues['AnswerActions']) {
            newFieldValues['AnswerActions'] = {}
        }
        newFieldValues['AnswerActions'][newAnswerActionId] = {}
        if (propertyKey) {
            newFieldValues['AnswerActions'][newAnswerActionId]['PropertyKey'] = propertyKey
        }
        setFormFields(newFieldValues)
    }




    return <div className={`flex flex-col gap-2`}>
        <div className={`flex flex-row items-center gap-2 justify-between mb-2`}>
            <h3 className={`font-righteous text-brandblue text-lg`}>Answer actions:</h3>

            <div className={`flex flex-row items-center gap-2 justify-between mb-2`}>
                <Button
                    onClick={addAnswerAction}
                    text={`Add`}
                    variant={`primary`}
                    fullwidth={false}
                />
            </div>
        </div>




        {
            answerActionKeys && answerActionKeys.length > 0 &&
            <div className={`w-full flex flex-row flex-wrap gap-4 mb-4`}>
                {answerActionKeys.map((actionId: string, index: number) => {
                    if (formFields['AnswerActions'][actionId]) {
                        return <AssetTypeSingleAction
                            key={index}
                            actionId={actionId}
                            formFields={formFields}
                            formErrors={formErrors}
                            setFormFields={setFormFields}
                            setFormErrors={setFormErrors}
                            propertyKey={propertyKey}
                        />
                    }
                })}
            </div>
        }
        {formErrors['AnswerActions'] && <RedAlert alignment='left'>{formErrors['AnswerActions']}</RedAlert>}
    </div>
}



export default AssetTypeActions