import { useContext } from "react"
import { GlobalContext } from '../GlobalContext'

type Props = {
  thisCheckPeriod: ObjectStringKeyAnyValue
  hideAssetId?: boolean
}

function FormatChecklistTimeWindow({
  thisCheckPeriod,
  hideAssetId=false
}: Props) {

    const {
      tableData,
    } = useContext(GlobalContext)
    
  if (!thisCheckPeriod || !thisCheckPeriod.StartHoursMinsDay || !thisCheckPeriod.EndHoursMins) {
    return null
  }

  function getNameForAsset(assetId: string) {
    if (tableData && tableData.Assets && tableData.Assets[assetId] && tableData.Assets[assetId].AssetName) {
      return tableData.Assets[assetId].AssetName
    }
    return assetId
  }
  return <>
    {` ${`${thisCheckPeriod.StartHoursMinsDay.hours}`.padStart(2, '0')}`}:
    {`${thisCheckPeriod.StartHoursMinsDay.minutes}`.padStart(2, '0')} - {`${thisCheckPeriod.EndHoursMins.hours}`.padStart(2, '0')}:
    {`${`${thisCheckPeriod.EndHoursMins.minutes}`.padStart(2, '0')} `}
    {!hideAssetId && thisCheckPeriod.AssetId && <><br/> {getNameForAsset(thisCheckPeriod.AssetId)} </>}
  </>
}
export default FormatChecklistTimeWindow