import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Spinner from '../ui-elements/Spinner'
import DeleteButton from '../formfields/DeleteButton'
import { isFunctionExpression } from 'typescript'
import Card from '../ui-elements/Card'
import YellowAlert from '../ui-elements/YellowAlert'
import AssociatedItems from '../ui-elements/AssociatedItems'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'

type Props = {
}

function AssetTypeList({
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)

    const { checklistId } = useParams()
    const navigate = useNavigate()


    const deleteItem = (Id: string) => {
        setShowModal({ "spinner": 'Deleting...' })
        const payload = JSON.stringify({
            action: "assets",
            subAction: "deleteAssetType",
            Id: Id
        })
        sendMessageToWebsocket(payload)
        const unsubscribe = subscribe("assetTypeDeleted", data => {
            if (data.Id === Id) {
                setShowModal(null)
            }
            unsubscribe()
        })

    }



    return <div className={`w-full flex flex-col items-center`}>
        <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <h3 className={`font-righteous text-3xl font-brandblue`}>Asset Types</h3>


                <div className={`flex flex-row gap-2 justify-center items-center`}>
                    <Button
                        internalLinkUrl={`/asset-type`}
                        text={`Add type`}
                        variant={`primary`}
                        // size={`big`}
                        fullwidth={false}
                    />
                    <Button
                        internalLinkUrl={`/assets`}
                        text={`Manage assets`}
                        variant={`primary`}
                        // size={`big`}
                        fullwidth={false}
                    />
                </div>
            </div>




            {(!tableData.AssetTypes || Object.keys(tableData.AssetTypes).length === 0) && <p className={`w-full`}>No asset types have been set up yet. <Link to={`/asset-type/`} className={`underline hover:opacity-80`}>Add a new type.</Link></p>}


            {tableData && tableData.AssetTypes && Object.keys(tableData.AssetTypes).length > 0 && <div className={`w-full gap-2 flex md:flex-row flex-col flex-wrap`}>
                {Object.keys(tableData.AssetTypes).map((AssetTypeId: string, index: number) => {
                    const AssetType = tableData.AssetTypes[AssetTypeId]

                    return <Card
                        key={index}
                        fullwidth={false}
                    ><div
                        className={`h-full md:w-48 w-full flex flex-col gap-4 justify-between`}>

                            <div className={`flex flex-col gap-2`}>


                                <p className={`font-bold`}>{AssetType.DisplayName}</p>


                                <AssociatedItems
                                    keyName={`AssetTypeId`}
                                    itemId={AssetTypeId}
                                    itemType={`asset`}
                                />

                                <AssociatedItems
                                    keyName={`AssetTypeId`}
                                    itemId={AssetTypeId}
                                    itemType={`checklistQuestion`}
                                    shortText={`Used by questions in  `}
                                    longText={`Used by questions in the following `}
                                />

                                <AssociatedItems
                                    keyName={`AssetTypeId`}
                                    itemId={AssetTypeId}
                                    itemType={`checklist`}
                                    shortText={`Used by `}
                                    longText={`Used by the following `}
                                />

                            </div>
                            <div className={`flex flex-row gap-2`}>


                                <DeleteButton
                                    deleteFunction={deleteItem}
                                    functionParams={AssetTypeId}
                                    fullwidth={true}
                                />


                                <Button
                                    internalLinkUrl={`/asset-type/${AssetTypeId}`}
                                    text={`Edit`}
                                    variant={`primary`}
                                    // size={`big`}
                                    fullwidth={true}
                                />

                            </div>

                        </div>
                    </Card>

                })} </div>}

        </div>
    </div>

}
export default AssetTypeList