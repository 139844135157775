
import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Button from '../formfields/Button'

type Props = {
    children: any
}


function WhiteSubheader({
    children
}: Props) {

    return <div className={`w-full bg-white flex flex-row gap-4 items-center justify-center border-b-2 border-gray-300`}>
        <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}>
            {children}
        </div>
    </div>
}

export default WhiteSubheader