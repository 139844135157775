import React, { useEffect, useState, useContext, useRef } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams,
    useSearchParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import DeleteButton from '../formfields/DeleteButton'
import QuestionEditDeleteReposition from './QuestionEditDeleteReposition'
import FormatAssetName from './FormatAssetName'
import { questionFormats } from './checklist-utils/questionFormats'
import { tableData } from '../tests/testTableData'
import ButtonMenu from '../ui-elements/ButtonMenu'
import { subscribe } from '../utils/pubsub'


type Props = {
    question: ObjectStringKeyAnyValue
    questionId: string
    isDragging: boolean

}


function DraggableQuestionDetail({
    question,
    questionId,
    isDragging
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)

    const { checklistId } = useParams()
    const [searchParams] = useSearchParams()
    const scrollTo = searchParams.get("scrollto")
    const questionRef: any = useRef(null)


    useEffect(() => {
        if (questionRef && scrollTo && questionId && scrollTo === questionId) {
            requestAnimationFrame(() => {
                setTimeout(() => {
                    questionRef.current?.scrollIntoView({ behavior: "smooth" });
                }, 0)
            })
        } 
    }, [scrollTo])


    const deleteQuestion = (questionId: string) => {
        if (questionId) {
            setShowModal({ "spinner": 'Deleting...' })
            const payload = JSON.stringify({
                action: "checklists",
                subAction: "deleteQuestion",
                questionId: questionId
            })
            sendMessageToWebsocket(payload)
            const unsubscribe = subscribe("checklistQuestionDeletedResponse", data => {
                setShowModal(null)
                unsubscribe()
            })
        }
    }

    const duplicateQuestion = (questionId: string) => {
        if (questionId && checklistId) {
            setShowModal({ "spinner": 'Duplicating...' })
            const payload = JSON.stringify({
                action: "checklists",
                subAction: "duplicateQuestion",
                questionId: questionId,
                checklistId: checklistId
            })
            sendMessageToWebsocket(payload)
            const unsubscribe = subscribe("checklistQuestionDuplicated", data => {
                if (data && data.originalQuestionId === questionId) {
                    setShowModal(null)
                }
                unsubscribe()
            })
        }
    }



    return <div className={`bg-gray-200 relative w-full rounded flex flex-row gap-3 justify-between
        ${isDragging ? ' animate-pulse ' : ' '} 
        `}
        id={questionId}
        ref={questionRef}
    >


        <div className={`w-full flex flex-col px-2 py-2 text-sm`}>
            <div className={`w-full flex flex-row justify-between gap-2 `}>




                {!question.AssetId && !question.FridgeId && <h5 className={`text-lg font-righteous text-brandblue`}>{question.QuestionText}</h5>}

                {question.AssetId && <h5 className={`text-lg font-righteous text-brandblue`}>Reading for <FormatAssetName assetId={question.AssetId} /> </h5>}


            </div>



            {questionFormats && <p>
                {questionFormats[question['QuestionFormat']] ? questionFormats[question['QuestionFormat']].displayName : ''}

                {tableData &&
                    question['QuestionFormat'] === 'CustomDataFormat' &&
                    question['CustomDataFormatId'] &&
                    tableData['CustomDataFormats'][question['CustomDataFormatId']] &&
                    ` (${tableData['CustomDataFormats'][question['CustomDataFormatId']].RegexName})`}
            </p>}

            {question['QuestionOptions'] && question['QuestionOptions'].length > 0 &&
                <div className={`flex flex-row flex-wrap gap-1`}>
                    Options:
                    {question['QuestionOptions'].map((option: string, index: number) => {
                        return <p key={index}>{option}{(index + 1) < question['QuestionOptions'].length && ','}</p>
                    })}
                </div>}

            {question['QuestionInstructions'] &&
                <p>Instructions: {question['QuestionInstructions']}</p>}


            {
                question['RepeatQuestion']
                && question['RepeatQuestion']['timeDelay']
                && question['RepeatQuestion']['timeDelay'].length > 0
                &&
                <div className={`flex flex-row gap-1`}>
                    <p>Repeat question:</p>
                    {question['RepeatQuestion']['timeDelay'].map((option: string, index: number) => {
                        return <p key={index}>
                            {parseInt(option) < 60 ? `${option} minutes` : `${parseInt(option) / 60} hours`}
                            {(index + 1) < question['RepeatQuestion']['timeDelay'].length && ','}
                        </p>
                    })}
                </div>}


            {
                question['RepeatQuestion']
                && question['RepeatQuestion']['numberOfTimes']
                && question['RepeatQuestion']['numberOfTimes']['min']
                && question['RepeatQuestion']['numberOfTimes']['max']
                && question['RepeatQuestion']['numberOfTimes']['min'] === question['RepeatQuestion']['numberOfTimes']['max']
                &&
                <p>Question repeats {question['RepeatQuestion']['numberOfTimes']['min']} time(s)</p>}

            {
                question['RepeatQuestion']
                && question['RepeatQuestion']['numberOfTimes']
                && question['RepeatQuestion']['numberOfTimes']['min']
                && question['RepeatQuestion']['numberOfTimes']['max']
                && question['RepeatQuestion']['numberOfTimes']['min'] !== question['RepeatQuestion']['numberOfTimes']['max']
                &&
                <p>Question repeats {question['RepeatQuestion']['numberOfTimes']['min']} - {question['RepeatQuestion']['numberOfTimes']['max']} time(s)</p>}


            {question['CustomResultsSpreadsheetMapping'] &&
                Object.keys(question['CustomResultsSpreadsheetMapping']).length > 0 &&
                <p>
                    {question['CustomResultsSpreadsheetMapping']['SpreadsheetCellReference'] && ` Spreadsheet cell reference: ${question['CustomResultsSpreadsheetMapping']['SpreadsheetCellReference']}. `}

                    {question['CustomResultsSpreadsheetMapping']['SpreadsheetCellColumns'] && ` Columns: ${question['CustomResultsSpreadsheetMapping']['SpreadsheetCellColumns']}. `}
                </p>}


        </div>




        {/* {question.QuestionOrder} */}
        <ButtonMenu>
            <Button
                internalLinkUrl={`/checklists/${checklistId}/questions/${questionId}/`}
                text={`Edit`}
            />
            <Button
                onClick={() => duplicateQuestion(questionId)}
                text={`Duplicate`}
            />
            <DeleteButton
                deleteFunction={deleteQuestion}
                functionParams={questionId}
            />
        </ButtonMenu>




    </div>
}
export default DraggableQuestionDetail