import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Text from '../formfields/Text'
import { validateForm } from '../utils/validateForm'
import { removeNonAlphanumeric } from '../utils/removeNonAlphanumeric'
import Dropdown from '../formfields/Dropdown'
import YellowAlert from '../ui-elements/YellowAlert'
import AssociatedItems from '../ui-elements/AssociatedItems'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import AddButton from '../formfields/AddButton'
import AddEditStage from './AddEditStage'
import FooterNavButtonFrame from '../ui-elements/FooterNavButtonFrame'
import ProcessReportingOptionsStage from './ProcessReportingOptionsStage'
import JsonDebug from '../ui-elements/JsonDebug'
import ProcessReportingIndividual from './ProcessReportingIndividual'

type Props = {
}

function ProcessReportingOptions({
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)

    const { processDefinitionId } = useParams()
    const navigate = useNavigate()

    const initialFormData = { "DisplayName": "" }
    const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
    const [formErrors, setFormErrors] = useState<ObjectStringKeyAnyValue>({})
    const [sortedStageIds, setSortedStageIds] = useState<string[]>([])
    const thisProcess = processDefinitionId && tableData && tableData.ProcessDefinitions && tableData.ProcessDefinitions[processDefinitionId]



    useEffect(() => {
        // populate fields with existing data
        if (thisProcess) {
            const newFieldValues: ObjectStringKeyAnyValue = { ...thisProcess }
            setFormFields(newFieldValues)
            if (thisProcess.Stages) {
                const updatedArrayOfStageIds = Object.keys(thisProcess.Stages).sort((a: string, b: string) => {
                    return thisProcess.Stages[a].Order - thisProcess.Stages[b].Order
                }) || []
                setSortedStageIds(updatedArrayOfStageIds)
            }
        }
    }, [tableData])




    const addNewReportingOption = () => {
        if (processDefinitionId) {
            const newReportingOptionId = `RO_${processDefinitionId}_${Date.now()}`
            setShowModal({ "spinner": 'Adding...' })
            const payload: ObjectStringKeyAnyValue = {
                action: "processesAdmin",
                subAction: "addNewReportingOption",
                processDefinitionId: processDefinitionId,
                newReportingOptionId: newReportingOptionId
            }
            sendMessageToWebsocket(JSON.stringify(payload))
            const unsubscribe = subscribe("reportingOptionAdded", data => {
                if (data.newReportingOptionId === newReportingOptionId) {
                    setShowModal(null)
                    navigate(`/process/${processDefinitionId}/reporting/${newReportingOptionId}`)
                }
                unsubscribe()
            })
        }
    }




    if ((!tableData.ProcessDefinitions || !thisProcess)) {
        return <div className={`w-full flex flex-col items-center`}>
            <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>
                <Spinner>Loading...</Spinner>
            </div>
        </div>
    }

    return <div className={`w-full flex flex-col items-center mb-6`}>
        <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <h3 className={`font-righteous text-3xl font-brandblue`}>
                    Reports for process: {thisProcess.DisplayName}
                </h3>
            </div>





            <div className={`w-full flex flex-col items-center gap-4`}>

                {(!formFields.Reports || Object.keys(formFields.Reports).length === 0) && <YellowAlert fullwidth={true}>
                    <div>No reports have been added yet. <span onClick={addNewReportingOption} className={`cursor-pointer underline hover:opacity-80`}>Add a new report</span></div>
                </YellowAlert>}

                {formFields.Reports && Object.keys(formFields.Reports).length > 0 && <div className={`w-full flex flex-col items-start gap-4`}>
                    {Object.keys(formFields.Reports).map((reportingOptionId: string, index: number) => {
                        const thisReport = formFields.Reports[reportingOptionId]
                        if (!thisReport) {
                            return null
                        }
                        return <ProcessReportingIndividual
                            key={index}
                            thisReport={thisReport}
                            thisProcess={thisProcess}
                            reportingOptionId={reportingOptionId}
                            formFields={formFields}
                            setFormFields={setFormFields}
                        />
                    })}
                </div>}




                {/* <JsonDebug jsonobject={formFields.Reports} /> */}

                <FooterNavButtonFrame>
                    <Button
                        internalLinkUrl={`/processes`}
                        text={`Back`}
                        variant={`gray`}
                        size='big'
                    />
                    <Button
                        onClick={addNewReportingOption}
                        text={`Add new`}
                        size='big'
                    />
                </FooterNavButtonFrame>
            </div>

        </div>
    </div>

}
export default ProcessReportingOptions