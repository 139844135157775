
import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import DeleteButton from '../formfields/DeleteButton'
import { startNewQuestion } from './checklist-utils/startNewQuestion'
import QuestionEditDeleteReposition from './QuestionEditDeleteReposition'
import IndividualQuestionDetail from './IndividualQuestionDetail'
import { sortQuestionIdsByOrder } from './checklist-utils/sortQuestionIdsByOrder'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import DroppableStrictMode from '../ui-elements/DroppableStrictMode'
import DraggableQuestionDetail from './DraggableQuestionDetail'

type Props = {
    question: ObjectStringKeyAnyValue
    handleOnDragEnd?: AnyFunction
    questionsData?: ObjectStringKeyAnyValue
}
type SingleAnswerActionProps = {
    question: ObjectStringKeyAnyValue,
    actionId: string
    handleOnDragEnd?: AnyFunction
    questionsData?: ObjectStringKeyAnyValue
}


function SingleAnswerAction({
    question,
    actionId,
    handleOnDragEnd,
    questionsData
}: SingleAnswerActionProps) {

    const {
        tableData,
        setChecklistQuestionFields,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const { checklistId } = useParams()
    const navigate = useNavigate()
    const answerAction = question['AnswerActions'][actionId]
    const parentQuestionId = question['Id']
    const actionToTake = answerAction['ActionToTake']
    let actionAnswer = ''
    if (answerAction && answerAction['ActionForAnswer'] && answerAction['ActionForAnswer']['equals']) {
        actionAnswer = answerAction['ActionForAnswer']['equals']
    } else if (answerAction && answerAction['ActionForAnswer'] && answerAction['ActionForAnswer']['min'] && answerAction['ActionForAnswer']['max']) {
        actionAnswer = `Under ${answerAction['ActionForAnswer']['min']} or over ${answerAction['ActionForAnswer']['max']}`
    }


    const actionOptions = tableData && tableData['Organisation'] && tableData['Organisation']['ChecklistOptions'] && tableData['Organisation']['ChecklistOptions']['AlertTypes'] ? tableData['Organisation']['ChecklistOptions']['AlertTypes'] : null
    let actionToTakeDisplayName = actionOptions && actionOptions[actionToTake] && actionOptions[actionToTake]['displayName'] ? actionOptions[actionToTake]['displayName'] : actionToTake
    if (actionToTakeDisplayName === 'followUpQuestion') { actionToTakeDisplayName = `Follow up question(s)` }


    const followUpQuestionsForThisParent: ObjectStringKeyAnyValue = {}
    if (tableData && tableData['ChecklistQuestions']) {
        for (const questionId in tableData['ChecklistQuestions']) {
            const thisQuestion = tableData['ChecklistQuestions'][questionId]
            if (thisQuestion['parentQuestionId'] === parentQuestionId && thisQuestion['parentActionId'] === actionId) {
                followUpQuestionsForThisParent[questionId] = thisQuestion
            }
        }
    }
    const sortedFollowUpQuestions = sortQuestionIdsByOrder(followUpQuestionsForThisParent)



    return <div className={`w-full bg-white px-0 py-1 rounded flex flex-col gap-1`}>

        <div className={`w-full flex flex-row items-center gap-2 justify-between pb-1`}>


            <p className={``}>
                If answer is <span className={`font-bold`}>{actionAnswer}</span>: {actionToTakeDisplayName}
            </p>

            {actionToTake === 'followUpQuestion' &&
                <Button
                    onClick={() => startNewQuestion(checklistId, setChecklistQuestionFields, navigate, parentQuestionId, actionId)}
                    text={`Add question`}
                    variant={`primary`}
                    size={`small`}
                    fullwidth={false}
                />
            }

        </div>

        {/* {actionToTake === 'followUpQuestion' && sortedFollowUpQuestions.length > 0 &&
            <div className={`w-full flex flex-col gap-1`}>
                {sortedFollowUpQuestions.map((followUpQuestionId: string, index: number) => {
                    const followUpQuestion = tableData['ChecklistQuestions'][followUpQuestionId]
                    return <IndividualQuestionDetail
                        key={index}
                        question={followUpQuestion}
                        questionId={followUpQuestionId}
                        showEditButton={true}
                        isFirst={index === 0 ? true : false}
                        isLast={(index + 1) === sortedFollowUpQuestions.length ? true : false}
                    />
                })}
            </div>} */}


        {questionsData && handleOnDragEnd && actionToTake === 'followUpQuestion' && sortedFollowUpQuestions.length > 0 &&
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <DroppableStrictMode droppableId="questions">
                    {(provided) => (
                        <div className={`flex flex-col gap-2`}
                            {...provided.droppableProps} ref={provided.innerRef}>
                            {sortedFollowUpQuestions.map((questionId, index) => {
                                if (!questionsData[questionId]) return null
                                return <Draggable key={questionId} draggableId={questionId} index={index}>
                                        {(provided, snapshot) => (

                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <DraggableQuestionDetail
                                                    questionId={questionId}
                                                    question={questionsData[questionId]}
                                                    isDragging={snapshot.isDragging}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </DroppableStrictMode>
            </DragDropContext>}



    </div>
}





function AnswerActionSummary({
    question,
    handleOnDragEnd,
    questionsData
}: Props) {

    const {
        tableData
    } = useContext(GlobalContext)

    const { checklistId } = useParams()
    const [showAll, setShowAll] = useState(true)

    return <div className={`flex flex-row items-start gap-2 pt-2 pb-2`}>






        <div onClick={() => setShowAll(!showAll)}
            className={`hover:opacity-80 cursor-pointer`}>
            {showAll ? '⬇️' : '➡️'}
        </div>


        <div className={`flex flex-col gap-1 w-full`}>


            <p className={`hover:opacity-80 cursor-pointer font-bold`} onClick={() => setShowAll(!showAll)}>
                {Object.keys(question['AnswerActions']).length} answer action{Object.keys(question['AnswerActions']).length > 1 && 's'}
            </p>

            {showAll && Object.keys(question['AnswerActions']).map((actionId: string, index: number) => {
                return <SingleAnswerAction
                    key={index}
                    actionId={actionId}
                    question={question}
                    handleOnDragEnd={handleOnDragEnd}
                    questionsData={questionsData}
                />
            })}</div>



    </div>
}

export default AnswerActionSummary