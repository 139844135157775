import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import Spinner from '../ui-elements/Spinner'
import { useParams, useNavigate } from 'react-router-dom'
import DeleteButton from '../formfields/DeleteButton'
import EnableOrDisableChecklist from './EnableOrDisableChecklist'
import Modal from '../ui-elements/Modal'
import YellowAlert from '../ui-elements/YellowAlert'
import ButtonMenu from '../ui-elements/ButtonMenu'

type Props = {
}

function ListOfChecklists({
}: Props) {

  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields,
    setShowModal
  } = useContext(GlobalContext)

  const { checklistId } = useParams()
  const navigate = useNavigate()
  const [savingProgress, setSavingProgress] = useState(false)
  const currentDate = new Date()



  const deleteChecklist = (checklistId: string) => {
    if (checklistId) {
      setShowModal({ "spinner": 'Saving...' })
      const payload = JSON.stringify({
        action: "checklists",
        subAction: "deleteChecklist",
        checklistId: checklistId
      })
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("checklistDeletedResponse", data => {
        setShowModal(null)
        unsubscribe()
      })
    }
  }

  const duplicateChecklist = (checklistId: string) => {
    if (checklistId) {
      setShowModal({ "spinner": 'Duplicating...' })
      const payload = JSON.stringify({
        action: "checklists",
        subAction: "duplicateChecklist",
        checklistId: checklistId
      })
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("checklistDuplicated", data => {
        if (data && data.originalChecklistId === checklistId) {
          setShowModal(null)
          navigate(`/checklists/${data.newChecklistId}`)
        }
        unsubscribe()
      })
    }
  }

  // console.log('tableData', tableData)


  return <div className={`flex md:flex-row flex-col flex-wrap gap-2`}>
    {tableData && tableData.Checklists && Object.keys(tableData.Checklists).map((checklistKey, index) => {
      const thisChecklist = tableData.Checklists[checklistKey]

      const associatedAssetType = thisChecklist['AssetTypeId'] && tableData['AssetTypes'] && tableData['AssetTypes'][thisChecklist['AssetTypeId']]



      return <Card key={index}>
        <div className={`md:w-80 h-full w-full flex flex-col gap-4 justify-between items-center`}>



          <div className={`w-full text-xs flex flex-col gap-1`}>




            <h4 className={`text-base font-righteous text-brandblue text-lg`}>{thisChecklist['ChecklistName'] || 'unnamed checklist'}</h4>

            {associatedAssetType && <YellowAlert alignment='left' size='small'>
              {associatedAssetType.DisplayName ?
                `Checklist for asset type: ${associatedAssetType.DisplayName}` :
                `Checklist for asset type Id: ${associatedAssetType.Id}`
              }
            </YellowAlert>}


            <p>{
              thisChecklist['Schedule'] &&
                thisChecklist['Schedule']['CheckWindows'] &&
                Object.keys(thisChecklist['Schedule']['CheckWindows']).length > 0 ?
                `${Object.keys(thisChecklist['Schedule']['CheckWindows']).length} check window(s)` :
                'No check windows'
            }
            </p>

            <p>
              {
                tableData['ChecklistQuestions'] &&
                Object.values(tableData['ChecklistQuestions']).filter((question: any) => question.ChecklistId === checklistKey).length} question(s)
            </p>

          </div>



          <Button
            internalLinkUrl={`/checklists/${thisChecklist['Id']}/results/${currentDate.getFullYear()}/${currentDate.getMonth() + 1}`}
            text={`View results`}
            variant={`primary`}
            fullwidth={true}
          //size={`small`}
          />
        </div>


        <ButtonMenu>

          <EnableOrDisableChecklist
            thisChecklist={thisChecklist}
          // savingProgress={savingProgress}
          // setSavingProgress={setSavingProgress}
          />
          <Button
            internalLinkUrl={`/checklists/${thisChecklist['Id']}`}
            text={`Edit settings`}
            variant={`primary`}
            fullwidth={true}
          //size={`small`}
          />
          <Button
            internalLinkUrl={`/checklists/${thisChecklist['Id']}/questions`}
            text={`Edit questions`}
            variant={`primary`}
            // size={`big`}
            fullwidth={false}
          />
          {/* <Button
            internalLinkUrl={`/checklists/${thisChecklist['Id']}/results/${currentDate.getFullYear()}/${currentDate.getMonth() + 1}`}
            text={`View results`}
            variant={`primary`}
            fullwidth={true}
          size={`small`}
          /> */}
          <Button
            onClick={() => duplicateChecklist(checklistKey)}
            text={`Duplicate`}
            variant={`primary`}
            fullwidth={true}
          />
          <DeleteButton
            deleteFunction={deleteChecklist}
            functionParams={checklistKey}
            fullwidth={true}
          //size={`small`}
          />
        </ButtonMenu>

      </Card>

    })}
  </div>

}
export default ListOfChecklists