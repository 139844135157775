import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Spinner from "../ui-elements/Spinner"
import ListOfChecklists from '../checklists/ListOfChecklists'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import YellowAlert from '../ui-elements/YellowAlert'

type Props = {
}


function ChecklistDashboard({

}: Props) {

  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const numberOfChecklists = tableData && tableData.Checklists && Object.keys(tableData.Checklists).length

  return <Card fullwidth={true}>
    <div className={`w-full h-full flex flex-col gap-2 justify-between`}>

      <div className={`w-full flex flex-col gap-2`}>
        <h3 className={`text-2xl font-righteous text-brandblue`}>Checklists</h3>
        {numberOfChecklists === 0 && <YellowAlert>
          No checklists currently set up</YellowAlert>}
        {numberOfChecklists > 0 && <p>{numberOfChecklists} checklist{numberOfChecklists === 1 ? '' : 's'} found</p>}
      </div>

      <div className={`w-full flex flex-col gap-2`}>
        <Button
          internalLinkUrl={`/checklists/`}
          text={`Manage checklists`}
          fullwidth={false}
        />
        <Button
          internalLinkUrl={`/custom-data-formats`}
          text={`Manage custom data formats`}
          fullwidth={false}
        />
      </div>
    </div>
  </Card>
}
export default ChecklistDashboard