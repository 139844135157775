import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import { useParams } from 'react-router-dom'
import Button from '../../formfields/Button'
import Card from '../../ui-elements/Card'


type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
}


type IndividualAssetProps = {
    selectedAssetId: string | null
    setSelectedAssetId: React.Dispatch<React.SetStateAction<any>>
    thisAsset: ObjectStringKeyAnyValue
    disabled: boolean
}

function IndividualAsset({
    selectedAssetId,
    setSelectedAssetId,
    thisAsset,
    disabled
}: IndividualAssetProps) {


    return <div
        className={`
      ${!disabled && selectedAssetId === thisAsset.Id && ' bg-brandblue border-brandblue text-white '}
      ${!disabled && selectedAssetId !== thisAsset.Id && ' bg-white border-gray-400'}
      ${disabled ? ' border-gray-400 bg-gray-300 text-gray-700 ' : ' hover:opacity-80 cursor-pointer '}
      rounded py-2 px-4 border`}
        onClick={() => { !disabled && setSelectedAssetId(thisAsset.Id) }}
    >
        <p className={`font-bold`}>{thisAsset.AssetName}</p>
        <p className={`text-xs`}>ID: #{thisAsset.Id}</p>
    </div>

}


function SelectAsset({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)

    const { checklistId, questionId } = useParams()

    const assets = tableData['Assets']
    const assetTypes = tableData['AssetTypes']
    const thisAssetTypeId = questionsFormFields['AssetId'] && assets && assets[questionsFormFields['AssetId']] && assets[questionsFormFields['AssetId']].AssetTypeId || ''
    const thisAssetTypePropertyId = questionsFormFields['AssetTypePropertyId'] || ''
    const [unselectedAssetIds, setUnselectedAssetIds] = useState<Array<string>>([])
    const [alreadySelectedAssetIds, setAlreadySelectedAssetIds] = useState<Array<string>>([])
    const [showAssetType, setshowAssetType] = useState(thisAssetTypeId)
    const [showPropertyType, setshowPropertyType] = useState(thisAssetTypePropertyId)




    useEffect(() => {
        // get all the asset ids for this asset type
        const unselectedAssetIdsArray: Array<string> = []
        const alreadySelectedAssetIdsArray: Array<string> = []
        for (const thisAssetId in assets) {
            const thisAsset = assets[thisAssetId]
            if (thisAsset.AssetTypeId === showAssetType) {
                if (hasAssetBeenUsedBeforeInThisChecklist(thisAssetId)) {
                    alreadySelectedAssetIdsArray.push(thisAssetId)
                } else {
                    unselectedAssetIdsArray.push(thisAssetId)
                }

            }
        }
        setUnselectedAssetIds(unselectedAssetIdsArray)
        setAlreadySelectedAssetIds(alreadySelectedAssetIdsArray)
    }, [showAssetType])

    const hasAssetBeenUsedBeforeInThisChecklist = (assetId: string) => {
        let hasBeenUsed = false
        for (const thisQuestionId in tableData['ChecklistQuestions']) {
            const thisQuestion = tableData['ChecklistQuestions'][thisQuestionId]
            if (
                thisQuestionId !== questionId &&
                thisQuestion.ChecklistId === checklistId &&
                thisQuestion.AssetId === assetId
            ) {
                hasBeenUsed = true
            }
        }
        return hasBeenUsed
    }

    const handleChange = (value: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        newFieldValues['AssetId'] = value
        newFieldValues['AssetId'] = value
        setQuestionsFormFields(newFieldValues)
    }

    const selectAssetProperty = (propertyId: string, assetTypeId: string) => {
        setshowAssetType(assetTypeId)
        setshowPropertyType(propertyId)
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        delete newFieldValues['AssetId']
        newFieldValues['AssetTypePropertyId'] = propertyId
        newFieldValues['AssetTypeId'] = assetTypeId
        setQuestionsFormFields(newFieldValues)
    }


    // console.log('🐙')
    // console.log(showAssetType)
    // console.log(alreadySelectedAssetIds)
    // console.log(unselectedAssetIds)


    return <div className={`flex flex-col gap-2`}>
        <h3 className={`font-righteous text-brandblue text-lg`}>Select asset property:</h3>


        {(!assetTypes || Object.keys(assetTypes).length === 0) && <RedAlert>No asset types have been set up</RedAlert>}

        {(!assets || Object.keys(assets).length === 0) && <RedAlert>No assets have been set up</RedAlert>}

        {assets && Object.keys(assets).length > 0 && Object.keys(assets).length === alreadySelectedAssetIds.length ?
            <RedAlert alignment='left'>All assets have already have checks as part of this checklist</RedAlert> :
            <> {questionsFormErrors['AssetId'] && <RedAlert alignment='left'>{questionsFormErrors['AssetId']}</RedAlert>}</>
        }


        {assetTypes && Object.keys(assetTypes).length > 0 && <div className={`flex flex-row flex-wrap gap-2 mb-4`}>
            {Object.keys(assetTypes).map((assetTypeId, index) => {
                const thisAssetType = assetTypes[assetTypeId]
                const propertyIds = thisAssetType.Properties ? Object.keys(thisAssetType.Properties) : []
                return <Card
                    key={index}
                ><div className={`w-48 flex flex-col gap-2`}>
                        <p className={`font-bold`}>{thisAssetType.DisplayName}</p>

                        {propertyIds.length === 0 && <RedAlert size={`small`}>No properties found for this asset type</RedAlert>}

                        {propertyIds.length > 0 && <div className={`flex flex-row flex-wrap gap-2`}>

                
                            {propertyIds.map((propertyId, index) => {

                                return <Button key={index}
                                    onClick={() => selectAssetProperty(propertyId, assetTypeId)}
                                    text={thisAssetType.Properties[propertyId].DisplayName}
                                    variant={showPropertyType === propertyId ? 'primary' : 'gray'}
                                />

                            })}
                        </div>}

                    </div>
                </Card>
            })}

        </div>}



        {showAssetType && unselectedAssetIds.length === 0 && alreadySelectedAssetIds.length === 0 && <RedAlert>No assets found for this asset type</RedAlert>}




        {showAssetType && (unselectedAssetIds.length > 0 || alreadySelectedAssetIds.length > 0) && <div className={`flex flex-col gap-2`}>
            <h3 className={`font-righteous text-brandblue text-lg`}>Select asset:</h3>
            <div className={`flex flex-row flex-wrap gap-2 mb-4`}>
                {unselectedAssetIds.map((assetId, index) => {
                    const thisAsset = assets[assetId]
                    return <IndividualAsset
                        key={index}
                        selectedAssetId={questionsFormFields['AssetId']}
                        setSelectedAssetId={handleChange}
                        thisAsset={thisAsset}
                        disabled={false}
                    />
                })}

                {alreadySelectedAssetIds.map((assetId, index) => {
                    const thisAsset = assets[assetId]
                    return <IndividualAsset
                        key={index}
                        selectedAssetId={questionsFormFields['AssetId']}
                        setSelectedAssetId={handleChange}
                        thisAsset={thisAsset}
                        disabled={true}
                    />
                })}

            </div>
        </div>}

    </div>
}



export default SelectAsset