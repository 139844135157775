import React, { useContext, useState, useEffect } from 'react'
import { GlobalContext } from '../GlobalContext'
import RedAlert from '../ui-elements/RedAlert'
import Button from '../formfields/Button'
import NextStep from './NextStep'


type Props = {
    formFields: ObjectStringKeyAnyValue
    formErrors: ObjectStringKeyAnyValue
    setFormFields: React.Dispatch<React.SetStateAction<any>>
    setShowNextStepsBuilder: React.Dispatch<React.SetStateAction<boolean>>
    setFormErrors: React.Dispatch<React.SetStateAction<any>>
    stageId: string
    addNextStep: AnyFunction
    thisStage: ObjectStringKeyAnyValue
}

function NextSteps({
    formFields,
    formErrors,
    setFormFields,
    setShowNextStepsBuilder,
    setFormErrors,
    stageId,
    addNextStep,
    thisStage
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)




const revertToDefault = () => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    delete newFieldValues['Stages'][stageId]['NextSteps']
    setFormFields(newFieldValues)
}

const thisStageBehaviour = stageId && formFields && formFields['Stages'] && formFields['Stages'][stageId] && formFields['Stages'][stageId]['Behaviour'] ? formFields['Stages'][stageId]['Behaviour'] : null

    return <div className={`w-full flex flex-col gap-2`}>
        <div className={`w-full flex flex-row items-center gap-2 justify-between mb-2`}>
            {/* <h3 className={`font-righteous text-brandblue text-lg`}>Next steps:</h3> */}

            <div className={`flex flex-row items-center gap-2 justify-between mb-2`}>
                <Button
                    onClick={addNextStep}
                    text={thisStageBehaviour === 'ongoing' ? `Add step` : `Choose next stage`}
                    variant={`primary`}
                    fullwidth={false}
                />

                <Button
                    onClick={revertToDefault}
                    text={`Revert to default`}
                    variant={`gray`}
                    fullwidth={false}
                />

            </div>
        </div>

        {
            formFields &&
            formFields['Stages'] &&
            formFields['Stages'][stageId] &&
            formFields['Stages'][stageId]["NextSteps"] &&
            Object.keys(formFields['Stages'][stageId]["NextSteps"]).length > 0 &&
            <div className={`w-full flex flex-row flex-wrap gap-2 mb-4`}>
                {Object.keys(formFields['Stages'][stageId]["NextSteps"]).map((nextStepId: string, index: number) => {
   
                    return <NextStep
                        key={index}
                        nextStepId={nextStepId}
                        formFields={formFields}
                        formErrors={formErrors}
                        setFormFields={setFormFields}
                        setFormErrors={setFormErrors}
                        stageId={stageId}
                    />
                })}
            </div>
        }

        {formErrors['NextSteps'] && <RedAlert alignment='left'>{formErrors['NextSteps']}</RedAlert>}
    </div>
}



export default NextSteps