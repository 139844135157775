
import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Button from '../formfields/Button'

type Props = {
    children: any
    setDisplayItems: React.Dispatch<React.SetStateAction<any>>
    arrayToPageThrough: any[]
    itemsPerPage: number
    maxPageNumbersToDisplay?: number
}


function Paginate({
    children,
    setDisplayItems,
    arrayToPageThrough,
    itemsPerPage = 5,
    maxPageNumbersToDisplay = 5
}: Props) {

    const [page, setPage] = useState(1)
    const lengthOfArray = arrayToPageThrough.length
    const numberOfPages = Math.ceil(lengthOfArray / itemsPerPage)
    const pageNumbers = Array.from({ length: numberOfPages }, (_, i) => i + 1)



    useEffect(() => {
        const indexOfLastItem = page * itemsPerPage
        const indexOfFirstItem = indexOfLastItem - itemsPerPage
        setDisplayItems({ indexOfFirstItem, indexOfLastItem })
    }, [page])


    return <div className={`w-full flex flex-col gap-2`}>

        {pageNumbers && pageNumbers.length > 1 && <div className={`w-full flex flex-row flex-wrap gap-1 justify-end`}>

            {maxPageNumbersToDisplay > pageNumbers.length && pageNumbers.map((pageNumber) => {
                return <Button
                    key={pageNumber}
                    onClick={() => setPage(pageNumber)}
                    text={`${pageNumber}`}
                    variant={page === pageNumber ? 'gray' : 'primary'}
                    disabled={page === pageNumber ? true : false}
                    fullwidth={false}
                    size='small'
                />
            })}




            {maxPageNumbersToDisplay <= pageNumbers.length && <div className={`flex flex-row gap-1`}>
                <Button
                    onClick={() => {page > 1 && setPage(page - 1)}}
                    text={`◀`}
                    variant={page > 1 ? 'primary' : 'gray'}
                    disabled={page > 1 ? false : true}
                    fullwidth={false}
                    size='small'
                />
                <Button
                    text={`${page}`}
                    variant={'gray'}
                    disabled={true}
                    fullwidth={false}
                    size='small'
                />
                <Button
                    onClick={() => {page < pageNumbers.length && setPage(page + 1)}}
                    text={`▶`}
                    variant={page < pageNumbers.length ? 'primary' : 'gray'}
                    disabled={page < pageNumbers.length ? false : true}
                    fullwidth={false}
                    size='small'
                />

            </div>}




        </div>}
        <div className={`w-full flex flex-col gap-2`}>
            {children}
        </div>
    </div>
}

export default Paginate