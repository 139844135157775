import React, { useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { checkifAnswerIsDuplicated } from '../checklists/checklist-utils/validateChecklistQuestionsForm'
import DeleteButton from '../formfields/DeleteButton'
import Card from '../ui-elements/Card'
import RedAlert from '../ui-elements/RedAlert'



type Props = {
    actionId: string
    formFields: ObjectStringKeyAnyValue
    formErrors: ObjectStringKeyAnyValue
    setFormFields: React.Dispatch<React.SetStateAction<any>>
    setFormErrors: React.Dispatch<React.SetStateAction<any>>
    propertyKey: string
}

function AssetTypeSingleAction({
    actionId,
    formFields,
    formErrors,
    setFormFields,
    setFormErrors,
    propertyKey
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const actionOptions = tableData && tableData['Organisation'] && tableData['Organisation']['ChecklistOptions'] && tableData['Organisation']['ChecklistOptions']['AlertTypes'] ? tableData['Organisation']['ChecklistOptions']['AlertTypes'] : null


    const deleteAction = () => {
        const newFormFields: ObjectStringKeyAnyValue = { ...formFields }
        delete newFormFields['AnswerActions'][actionId]
        setFormFields(newFormFields)
    }


    const handleChangeActionForAnswer = (value: string, operator: string) => {
        // console.log('handle change')
        const newFormFields: ObjectStringKeyAnyValue = { ...formFields }
        if (value && operator) {
            newFormFields['AnswerActions'][actionId]['ActionForAnswer'] =
                { ...newFormFields['AnswerActions'][actionId]['ActionForAnswer'], [operator]: value }
        } else if (operator) {
            delete newFormFields['AnswerActions'][actionId]['ActionForAnswer'][operator]
        }
        const isAnswerDuplicated = checkifAnswerIsDuplicated(actionId, formFields['AnswerActions'][actionId], formFields['AnswerActions'])
        const newErrorFields: ObjectStringKeyAnyValue = {}
        if (isAnswerDuplicated) {
            newErrorFields[`AnswerAction-${actionId}`] = 'There is already an action for this answer.'
        } else {
            delete newErrorFields[`AnswerAction-${actionId}`]
        }
        setFormErrors(newErrorFields)
        setFormFields(newFormFields)
    }

    const handleChangeActionToTake = (value: string) => {
        const newFormFields: ObjectStringKeyAnyValue = { ...formFields }
        if (value) {
            newFormFields['AnswerActions'][actionId]['ActionToTake'] = value
        } else {
            delete newFormFields['AnswerActions'][actionId]['ActionToTake']
        }
        const isAnswerDuplicated = checkifAnswerIsDuplicated(actionId, formFields['AnswerActions'][actionId], formFields['AnswerActions'])
        const newErrorFields: ObjectStringKeyAnyValue = {}
        if (isAnswerDuplicated) {
            newErrorFields[`AnswerAction-${actionId}`] = 'There is already an action for this answer.'
        } else {
            delete newErrorFields[`AnswerAction-${actionId}`]
        }
        setFormErrors(newErrorFields)
        setFormFields(newFormFields)
    }

    return <div className={`w-full flex flex-col gap-2`}> 
        <div className={`w-full flex flex-row items-end gap-2`}>

 

            <div className={`w-1/5`}>
                <p className={`text-sm`}>If answer is below:</p>

                <input
                    className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                    type='number'
                    onChange={(e) => handleChangeActionForAnswer(e.target.value, 'min')}
                    value={
                        formFields['AnswerActions'][actionId]['ActionForAnswer'] &&
                            formFields['AnswerActions'][actionId]['ActionForAnswer']['min'] ?
                            formFields['AnswerActions'][actionId]['ActionForAnswer']['min'] :
                            ''}
                />
            </div>



            <div className={`w-1/5`}>
                <p className={`text-sm`}>Or above:</p>

                <input
                    className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                    type='number'
                    onChange={(e) => handleChangeActionForAnswer(e.target.value, 'max')}
                    value={
                        formFields['AnswerActions'][actionId]['ActionForAnswer'] &&
                            formFields['AnswerActions'][actionId]['ActionForAnswer']['max'] ?
                            formFields['AnswerActions'][actionId]['ActionForAnswer']['max'] :
                            ''}
                />
            </div>

            <div className={`w-2/5`}>
                <p className={`text-sm`}>Take the following action:</p>
                <select
                    className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                    onChange={(e) => handleChangeActionToTake(e.target.value)}
                    value={formFields['AnswerActions'][actionId]['ActionToTake'] || ''}
                >
                    <option value={''}>Please select:</option>
                    <option value={`followUpQuestion`}>Follow up question(s)</option>
                    {actionOptions && Object.keys(actionOptions).map((optionId: string, index: number) => {
                        const displayName = actionOptions[optionId]['displayName']
                        return <option key={index} value={optionId}>{displayName || optionId}</option>
                    })}
                </select>
            </div>
            <div className={`w-1/5`}>
                <DeleteButton
                    deleteFunction={deleteAction}
                    functionParams={null}
                />
            </div>
        </div>

        {formErrors[`AnswerAction-${actionId}`] && <div className={`w-full`}><RedAlert size={`small`} alignment='left'>{formErrors[`AnswerAction-${actionId}`]}</RedAlert></div>}

    </div >
}



export default AssetTypeSingleAction