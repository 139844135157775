import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import { Value } from 'sass'
import SettingsDetails from './SettingsDetails'
import QuestionsDetails from './QuestionsDetails'
import ChecklistWrapper from './wrapper/ChecklistWrapper'
import QuickStartQRDetails from './QuickStartQRDetails'
import UploadResultsSpreadsheet from './UploadResultsSpreadsheet'
import { startNewQuestion } from './checklist-utils/startNewQuestion'

type Props = {
}

function ChecklistDetails({
}: Props) {

  const {
    tableData,
    setChecklistQuestionFields
  } = useContext(GlobalContext)


  const { checklistId } = useParams()
  const navigate = useNavigate()
  const [prevUrl, setPrevUrl] = useState(`/checklists/`)
  const [nextUrl, setNextUrl] = useState(`/checklists/${checklistId}/settings/checklist-name`)
  const [savingProgress, setSavingProgress] = useState(false)
  const [questionsData, setQuestionsData] = useState<null | ObjectStringKeyAnyValue>(null)

  useEffect(() => {
    if (tableData) {
      // save questions 
      const newChecklistQuestions: ObjectStringKeyAnyValue = {}
      for (const questionKey in tableData['ChecklistQuestions']) {
        if (tableData['ChecklistQuestions'][questionKey]['ChecklistId'] === checklistId) {
          newChecklistQuestions[questionKey] = tableData['ChecklistQuestions'][questionKey]
        }
      }
      setQuestionsData(newChecklistQuestions)
    }


  }, [tableData, checklistId])



  const addQuestion = () => {
    startNewQuestion(checklistId, setChecklistQuestionFields, navigate)
  }


  return <ChecklistWrapper
    title={'Checklist details'}
    // subtitle={''}
    // prevUrl={prevUrl}
    // nextUrl={nextUrl}
    nextButtonAction={{'function': addQuestion, 'params': null}}
    nextButtonText='Add new question'
    savingProgress={savingProgress}
    setSavingProgress={setSavingProgress}
  >


    <div className={`flex flex-row gap-4 items-center justify-between mb-4 `}>
      <h3 className={`font-righteous text-3xl font-brandblue`}>{checklistId && tableData && tableData.Checklists && tableData.Checklists[checklistId] && tableData.Checklists[checklistId].ChecklistName ?
        tableData.Checklists[checklistId].ChecklistName :
        "New Checklist"
      }

      </h3>


      <div className={`flex flex-row gap-1`}>
        <Button
          internalLinkUrl={`/checklists/${checklistId}/`}
          text={`Checklist settings`}
          variant={`primary`}
          // size={`big`}
          fullwidth={false}
        />

        <Button
          internalLinkUrl={`/checklists`}
          text={`All checklists`}
          variant={`primary`}
          // size={`big`}
          fullwidth={false}
        />

      </div>
    </div>

    {tableData && checklistId && tableData && checklistId && tableData.Checklists && tableData.Checklists[checklistId] &&
      <div className={`flex flex-col gap-2`}>

        <QuestionsDetails
          savingProgress={savingProgress}
          setSavingProgress={setSavingProgress}
          questionsData={questionsData}
        />

      </div>}


  </ChecklistWrapper>
}
export default ChecklistDetails