
export const validateCustomTemplateFieldsForm = (settingsFormFields, setSettingsFormErrors) => {
    let isFormValid = true
    const newSettingsFormErrors = {}


    if (settingsFormFields.CustomTemplateFields) {
        for (const customTemplateFieldId in settingsFormFields.CustomTemplateFields) {
            const thisField = settingsFormFields.CustomTemplateFields[customTemplateFieldId]
            // check if there is a value type and a cell reference
            if (
                !thisField['ValueType'] ||
                !thisField['SpreadsheetCellReference']
            ) {
                newSettingsFormErrors[`CustomTemplateFields-${customTemplateFieldId}`] = 'Please select a value type and enter a cell reference'
                isFormValid = false
            }

        }
    }


    setSettingsFormErrors(newSettingsFormErrors)
    return isFormValid
    //return false
}
