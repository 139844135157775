import { useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from "../formfields/Button"


type Props = {
    setShowResults: React.Dispatch<React.SetStateAction<any>>
}

function ResultsSummary({
    setShowResults
}: Props) {


    const {
        tableData,
        setShowModal,
        sendMessageToWebsocket
    } = useContext(GlobalContext)


    const { resultType, processDefinitionId, year, month, day } = useParams()
    const processDefinition = processDefinitionId && tableData.ProcessDefinitions && tableData.ProcessDefinitions[processDefinitionId]



    return <div className={`w-full flex flex-row gap-2 items-center justify-center`}>

        {processDefinition && <div className={`flex flex-row gap-1 items-center`}>
            <p className={`font-bold`}>Process:</p>
            <p className={``}>{processDefinition && processDefinition.DisplayName}  </p>
        </div>}
        <div className={`flex flex-row gap-1 items-center`}>
            <p className={`font-bold`}>Date:</p>
            <p className={``}>{day}/{month}/{year}</p>
        </div>

        <Button
            text={`Edit`}
            fullwidth={false}
            variant={`primary`}
            onClick={() => setShowResults(false)}
            size={`small`}
        />
    </div>
}
export default ResultsSummary