import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from "../formfields/Button"
import ResultsSelector from "./ResultsSelector"
import ResultsSummary from "./ResultsSummary"
import ProcessResultsList from "./ProcessResultsList"
import { unsubscribe } from "diagnostics_channel"
import WhiteSubheader from "../ui-elements/WhiteSubheader"


type Props = {
}

function Results({
}: Props) {


    const {
        tableData,
        setShowModal,
        sendMessageToWebsocket,
        processData,
        selectedResultsDate
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const { resultType, processDefinitionId, year, month, day } = useParams()
    const [showResults, setShowResults] = useState(false)
    const [noRecordsFound, setNoRecordsFound] = useState(false)
    const [requestedDates, setRequestedDates] = useState<string[]>([])
    const [datesWithNoResults, setDatesWithNoResults] = useState<string[]>([])

    useEffect(() => {
        const haveWeGotAllTheVariables = resultType && processDefinitionId && year && month && day ? true : false
        setShowResults(haveWeGotAllTheVariables)
        fetchData()

    }, [resultType, processDefinitionId, year, month, day])

    useEffect(() => {
        setRequestedDates([])
    }, [])

    useEffect(() => {
        if (
            !tableData.ChecklistPeriods ||
            !tableData.ProcessRecords ||
            !tableData.ProcessDefinitions
        ) {
            if (processDefinitionId && year && month && day) {
                fetchData()
            } else {
                //console.log('⛔️ not fetching')
            }
        } else {
            //console.log('🚨 not fetching')
        }
    }, [tableData, processDefinitionId, year, month, day])



    const fetchData = () => {
        if (processDefinitionId && year && month && day) {
            const cacheKey = `${processDefinitionId}-${year}-${month}-${day}`
            setRequestedDates([...requestedDates, cacheKey])
            if (datesWithNoResults.includes(cacheKey)) {
                console.log('🚨 already know there are no results')
                setNoRecordsFound(true)
                return
            }
            if (requestedDates.includes(cacheKey)) {
                setNoRecordsFound(false)
                console.log('🚨 already requested this data')
                return
            }
            console.log('🐕 fetching data')
            const payload = JSON.stringify({
                "action": "checklists",
                "subAction": "fetchResults",
                processDefinitionId: processDefinitionId,
                year: year,
                month: month,
                day: day
            })
            setNoRecordsFound(false)
            console.log("subscribing to noRecordsToShow")
            const unsubscribe = subscribe("noRecordsToShow", data => {
                console.log("noRecordsToShow recieved")
                if (data &&
                    data.processDefinitionId === processDefinitionId &&
                    data.year === year &&
                    data.month === month &&
                    data.day === day
                ) {
                    console.log('noRecordsToShow and data matches')
                    setDatesWithNoResults([...datesWithNoResults, cacheKey])
                    setNoRecordsFound(true)
                } else {
                    console.log('⛔️ noRecordsToShow but process definition id or dates do not match:', data)
                }
                unsubscribe()
            })
            sendMessageToWebsocket(payload)
            return unsubscribe
        } else {
            return null
        }
    }




    return <div className={`w-full flex flex-col items-center justify-center`}>

        <WhiteSubheader>
            <h3 className={`font-righteous text-3xl font-brandblue`}>Results</h3>
            {showResults && <ResultsSummary setShowResults={setShowResults} />}
        </WhiteSubheader>
        
        <div className={`max-w-3xl w-full p-4 flex flex-col items-center justify-center`}>

            {/*selectedResultsDate && <p>{selectedResultsDate.day}/{selectedResultsDate.month}/{selectedResultsDate.year}</p>*/}
            {!showResults && <ResultsSelector setShowResults={setShowResults} />}

            {showResults && <ProcessResultsList
                processDefinitionId={processDefinitionId}
                noRecordsFound={noRecordsFound}
            />}

        </div>
    </div>
}
export default Results