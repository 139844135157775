import React, { useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import RedAlert from '../ui-elements/RedAlert'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import DeleteButton from '../formfields/DeleteButton'
import { checkifAnswerIsDuplicated } from '../checklists/checklist-utils/validateChecklistQuestionsForm'
 


type Props = {
    nextStepId: string
    formFields: ObjectStringKeyAnyValue
    formErrors: ObjectStringKeyAnyValue
    setFormFields: React.Dispatch<React.SetStateAction<any>>
    setFormErrors: React.Dispatch<React.SetStateAction<any>>
    stageId: string
}

function  NextStep({
    nextStepId,
    formFields,
    formErrors,
    setFormFields,
    setFormErrors,
    stageId
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const thisNextStep = formFields && ['Stages'] && formFields['Stages'][stageId] && formFields['Stages'][stageId]['NextSteps'] && formFields['Stages'][stageId]['NextSteps'][nextStepId]
  

    const deleteAction = () => {
        const newQuestionsFormFields: ObjectStringKeyAnyValue = { ...formFields }
        delete newQuestionsFormFields['Stages'][stageId]['NextSteps'][nextStepId]
        setFormFields(newQuestionsFormFields)
    }

    const handleChangeStep = (key: string, value: string) => {
        console.log(`next step if: ${nextStepId} stage id: ${stageId} key: ${key} value: ${value}`)
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        console.log(newFieldValues['Stages'][stageId])
        
        if (!newFieldValues['Stages'][stageId]['NextSteps']) {
            newFieldValues['Stages'][stageId]['NextSteps'] = {}
        }
        
        if (!newFieldValues['Stages'][stageId]['NextSteps'][nextStepId]) {
            newFieldValues['Stages'][stageId]['NextSteps'][nextStepId] = {}
        }
       newFieldValues['Stages'][stageId]['NextSteps'][nextStepId][key] = value
        setFormFields(newFieldValues)
    }
 

    return <div className={`w-full bg-gray-200 rounded px-2 py-2 w-full flex flex-col gap-2`}>

       
<div className={`w-full flex flex-row items-end gap-2`}>
        <div className={`w-full flex flex-col gap-2`}>
                    <p className={`font-bold`}>Event name:</p>
                    <input
                        className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                        type='text'
                        name={`NextStepName`}
                        id={`NextStepName`}
                        value={thisNextStep && thisNextStep['NextStepName'] || ''}
                        onChange={(e) => handleChangeStep(`NextStepName`, e.target.value)}
                    />

                </div>
 

                <div className={`w-full flex flex-col gap-2`}>
                <p className={`text-sm`}>Proceed to the following stage:</p>
                <select
                    className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                    onChange={(e) => handleChangeStep(`NextStage`, e.target.value)}
                    value={thisNextStep && thisNextStep['NextStage'] || ''}
                >
                    <option value={''}>Please select:</option>
                    {formFields['Stages'] && Object.keys(formFields['Stages']).map((thisStageId: string, index: number) => {
                        const displayName = formFields['Stages'][thisStageId]['DisplayName'] || `Stage Id ${thisStageId}`
                        if (thisStageId === stageId) {
                            return null
                        }
                        return <option key={index} value={thisStageId}>{displayName || thisStageId}</option>
                    })}
                </select>
            </div>
            {Object.keys(formFields['Stages'][stageId]["NextSteps"]).length > 1 && <div className={`w-1/5`}>
                <DeleteButton
                    deleteFunction={deleteAction}
                    functionParams={null}
                />
            </div>}
      </div>

        {formErrors[`stage-${stageId}-NextStep-${nextStepId}`] && <div className={`w-full`}><RedAlert size={`small`} alignment='left' fullwidth={true}>{formErrors[`stage-${stageId}-NextStep-${nextStepId}`]}</RedAlert></div>}

      </div>
}



export default  NextStep