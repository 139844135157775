import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Text from '../formfields/Text'
import { validateForm } from '../utils/validateForm'
import { removeNonAlphanumeric } from '../utils/removeNonAlphanumeric'
import AddButton from '../formfields/AddButton'
import Card from '../ui-elements/Card'
import AssetTypeActions from './AssetTypeActions'
import DeleteButton from '../formfields/DeleteButton'
import AssociatedItems from '../ui-elements/AssociatedItems'
import RedAlert from '../ui-elements/RedAlert'


type Props = {
}

function AssetTypeEdit({
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)

    const { Id } = useParams()
    const navigate = useNavigate()

    const thisAssetType = Id && tableData && tableData.AssetTypes && tableData.AssetTypes[Id]
    const initialFormData = {
        "DisplayName": ""
    }
    const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
    const [formErrors, setFormErrors] = useState<ObjectStringKeyAnyValue>({})




    useEffect(() => {
        if (thisAssetType) {
            setFormFields(thisAssetType)
        }
    }, [thisAssetType])

    const handleChange = (key: string, value: any) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        newFieldValues[key] = value
        setFormFields(newFieldValues)
    }

    const handleChangeProperty = (propertyKey: string, questionKey: string, value: any) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        if (!newFieldValues['Properties'][propertyKey]) {
            newFieldValues['Properties'][propertyKey] = {}
        }
        newFieldValues['Properties'][propertyKey][questionKey] = value
        setFormFields(newFieldValues)
    }


    const addPropertyToCheck = () => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        const propertyKey = `prp-${Date.now()}`
        if (!newFieldValues['Properties']) {
            newFieldValues['Properties'] = {}
        }
        newFieldValues['Properties'][propertyKey] = {}
        newFieldValues['Properties'][propertyKey][`DisplayName`] = ''
        setFormFields(newFieldValues)
    }

    const deletePropertyToCheck = (assetTypePropertyId: string) => {
        // if it's a new asset type, just delete it from the form fields. If an existing type, send a request to lambda to check if it can be deleted
        if (Id) {
            setShowModal({ "spinner": 'Deleting...' })
            const payload: ObjectStringKeyAnyValue = {
                action: "assets",
                subAction: "deleteAssetTypeProperty",
                assetTypeId: Id,
                assetTypePropertyId: assetTypePropertyId
            }
            sendMessageToWebsocket(JSON.stringify(payload))
            const unsubscribe = subscribe("assetTypePropertyDeleted", data => {
                if (data.assetTypePropertyId === assetTypePropertyId) {
                    setShowModal(null)
                    deletePropertyFromForm(assetTypePropertyId)
                    //navigate(`/asset-types`)
                }
                unsubscribe()
            })
        }
        else {
            deletePropertyFromForm(assetTypePropertyId)
        }
    }

    const deletePropertyFromForm = (assetTypePropertyId: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        delete newFieldValues['Properties'][assetTypePropertyId]
        for (const answerActionKey in newFieldValues['AnswerActions']) {
            if (newFieldValues['AnswerActions'][answerActionKey]['PropertyKey'] === assetTypePropertyId) {
                delete newFieldValues['AnswerActions'][answerActionKey]
            }
        }
        setFormFields(newFieldValues)
    }




    const validateForm = () => {
        let isValid = true
        let newFormErrors: ObjectStringKeyAnyValue = {}
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        if (newFieldValues['DisplayName'] === '') {
            isValid = false
            newFormErrors['DisplayName'] = 'Please enter a value'
        }

        if (newFieldValues['Properties'] && !newFormErrors['Properties']) {
            newFormErrors['Properties'] = {}
        }

        // for each property
        for (const propertyKey in newFieldValues['Properties']) {
            if (newFieldValues['Properties'][propertyKey]) {
                if (!newFormErrors['Properties'][propertyKey]) {
                    newFormErrors['Properties'][propertyKey] = {}
                }
                // check if display name is empty
                if (!newFieldValues['Properties'][propertyKey]['DisplayName']) {
                    console.log('no display name')
                    isValid = false
                    newFormErrors['Properties'][propertyKey]['DisplayName'] = "Please enter a value"
                }


                // if there are answer actions
                if (
                    formFields['AnswerActions'] &&
                    Object.keys(formFields['AnswerActions']).length > 0
                ) {



                    for (const answerActionId in formFields['AnswerActions']) {
                        const thisAnswerAction = formFields['AnswerActions'][answerActionId]
                        const errorFieldName = `AnswerAction-${answerActionId}`


                        // check it doesn't match any other actions
                        //         const isAnswerDuplicated = checkifAnswerIsDuplicated(answerActionId, thisAnswerAction, formFields['AnswerActions'])
                        //         if (isAnswerDuplicated) {
                        //             newFormErrors[errorFieldName] = `
                        // ${newFormErrors[errorFieldName] || ''}
                        // There is more than one action for this answer. `
                        //             isValid = false
                        //         }


                        // check that there is a min and max
                        if (

                            !thisAnswerAction['ActionForAnswer'] ||
                            !thisAnswerAction['ActionForAnswer']['min'] ||
                            !thisAnswerAction['ActionForAnswer']['max']
                        ) {
                            newFormErrors[errorFieldName] = `
                ${newFormErrors[errorFieldName] || ''}
                Please include a minimum and maximum temperature. `
                            isValid = false
                        }

                        // check that maximum temp exceeds minimum
                        if (
                            thisAnswerAction['ActionForAnswer'] &&
                            thisAnswerAction['ActionForAnswer']['min'] &&
                            thisAnswerAction['ActionForAnswer']['max'] &&
                            (parseInt(thisAnswerAction['ActionForAnswer']['min']) >= parseInt(thisAnswerAction['ActionForAnswer']['max']))
                        ) {
                            newFormErrors[errorFieldName] = `
                ${newFormErrors[errorFieldName] || ''}
                Maximum temperature must be greater than minimum temperature.`
                            isValid = false
                        }


                        // ensure an action is selected
                        if (
                            (!thisAnswerAction['ActionToTake'])
                        ) {
                            newFormErrors[errorFieldName] = `
                ${newFormErrors[errorFieldName] || ''} 
                Please select a follow up action. `
                            isValid = false
                        }


                    }


                }


            }
        }
        setFormErrors(newFormErrors)
        setFormFields(newFieldValues)
        return isValid
    }


    const saveItem = () => {
        const isValid = validateForm()
        if (isValid) {
            setShowModal({ "spinner": 'Saving...' })
            let item_id = Id
            // generate a new Id if this is a new asset type
            if (!thisAssetType) {
                const item_name = formFields['DisplayName']['value'] || 'AS'
                item_id = `${removeNonAlphanumeric(item_name).substring(0, 10)}-${Date.now()}`
            }
            const payload: ObjectStringKeyAnyValue = {
                action: "assets",
                subAction: "saveAssetType",
                formFields: formFields,
                Id: item_id
            }
            sendMessageToWebsocket(JSON.stringify(payload))
            const unsubscribe = subscribe("assetTypeSaved", data => {
                if (data.Id === item_id) {
                    setShowModal(null)
                    navigate(`/asset-types`)
                }
                unsubscribe()
            })
        }
    }





    return <div className={`w-full flex flex-col items-center`}>
        <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <h3 className={`font-righteous text-3xl font-brandblue`}>
                    {thisAssetType ?
                        `Edit Asset Type: ${thisAssetType.DisplayName}` :
                        `Add new asset type`}
                </h3>
            </div>


            <div className={`w-full flex flex-col items-center`}>



                <div className={`w-full flex flex-col gap-2`}>
                    <p className={``}>Display Name</p>
                    <input
                        className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full text-sm px-3 py-2`}
                        type='text'
                        name={`DisplayName`}
                        value={formFields['DisplayName'] ? formFields['DisplayName'] : ''}
                        onChange={(e) => handleChange('DisplayName', e.target.value)}
                    />
                    {formErrors['DisplayName'] && <RedAlert size={`small`} alignment='left'>Please enter a value</RedAlert>}
                </div>


            </div>


            <div className={`w-full flex flex-row items-center gap-4 justify-between`}>
                <p className={`font-bold`}>Properties to check</p>

                <Button
                    onClick={addPropertyToCheck}
                    text={`Add`}
                    variant={`primary`}
                    fullwidth={false}
                />
            </div>




            {Id && <AssociatedItems
                keyName={`AssetTypeId`}
                itemId={Id}
                itemType={`checklist`}
                shortText={`Changing the properties and answer actions for this asset type will affect `}
                longText={`Changing the properties and answer actions for this asset type will affect the following `}
            />}



            {formFields && formFields.Properties && Object.keys(formFields.Properties).length > 0 &&
                <div className={`w-full flex flex-col gap-4 justify-between items-center`}>
                    {Object.keys(formFields.Properties).map((propertyKey, index) => {
                        if (!formFields.Properties[propertyKey]) return null
                        return <Card key={index} fullwidth={true}>
                            <div className={`w-full flex flex-col gap-2`}>

                                <div className={`w-full flex flex-col gap-2`}>

                                    <div className={`w-full flex flex-row gap-2 items-center justify-between`}>
                                        <h3 className={`font-righteous text-brandblue text-lg`}>Property name:</h3>
                                        <DeleteButton
                                            deleteFunction={deletePropertyToCheck}
                                            functionParams={propertyKey}
                                            fullwidth={false}
                                        />
                                    </div>

                                    <input
                                        className={`bg-gray-200 drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full text-sm px-3 py-2`}
                                        type='text'
                                        name={`DisplayName`}
                                        value={formFields.Properties && formFields.Properties[propertyKey] && formFields.Properties[propertyKey]['DisplayName'] ? formFields.Properties[propertyKey]['DisplayName'] : ''}
                                        onChange={(e) => handleChangeProperty(propertyKey, 'DisplayName', e.target.value)}
                                    />


                                    {formErrors && formErrors.Properties && formErrors.Properties[propertyKey] && formErrors.Properties[propertyKey]['DisplayName'] && <RedAlert size={`small`} alignment='left'>Please enter a value</RedAlert>}



                                </div>

                                <AssetTypeActions
                                    formErrors={formErrors}
                                    formFields={formFields}
                                    setFormFields={setFormFields}
                                    setFormErrors={setFormErrors}
                                    propertyKey={propertyKey}
                                // showCancelAnswerActionsButton={showCancelAnswerActionsButton}
                                />


                            </div>
                        </Card>
                    })}
                </div>}



            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <Button
                    internalLinkUrl={`/asset-types`}
                    text={`Back`}
                    variant={`gray`}
                    size={`big`}
                />
                <Button
                    onClick={saveItem}
                    text={`Save`}
                    size={`big`}
                />
            </div>


            {/* <p className={`mt-3 text-xs`}>{JSON.stringify(formFields).replaceAll(',', ', ')}</p> */}

        </div>
    </div>

}
export default AssetTypeEdit