import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link, useNavigate } from "react-router-dom"



type Props = {
  progress: number | undefined
  variant?: 'primary' | 'warning' | 'secondary'
  size?: 'small' | 'normal'
}

function ProgressBar({
  progress,
  variant = 'secondary',
  size = 'normal'
}: Props) {


  let backgroundColor = ''

  switch (variant) {
    case 'primary':
      backgroundColor = `rgba(22, 28, 46,1)`
      break
    case 'warning':
      backgroundColor = `rgba(183, 28, 28, 1)`
      break
    default:
      backgroundColor = `rgba(65, 88, 152,1)`
      break
  }

  return <div className={`w-full rounded bg-gray-300 
  ${size == 'normal' ? ' p-1 ' : ''}
  `}>
    <div className={`w-full rounded bg-white 
  ${size == 'normal' ? ' p-1 ' : ''}
  ${size == 'small' ? ' border border-gray-400 ' : ''}
  `}>
      <div className={`w-full bg-white p-1
      ${size == 'normal' ? ' h-5 ' : ''}
      ${size == 'small' ? ' h-4 ' : ''}
       ${progress && progress < 100 ? 'rounded-l' : 'rounded'}`}
        style={{
          backgroundColor: backgroundColor,
          width: `${progress !== undefined && progress > 100 ? 100 : progress}%`,
          minWidth: '10px'
        }}>
      </div>
    </div>
  </div>
}
export default ProgressBar