import { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Spinner from "../ui-elements/Spinner"
import ListOfChecklists from '../checklists/ListOfChecklists'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import YellowAlert from '../ui-elements/YellowAlert'

type Props = {
}


function ProcessesDashboard({

}: Props) {

    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket
    } = useContext(GlobalContext)


    return <Card fullwidth={true}>
        <div className={`w-full h-full flex flex-col gap-2 justify-between`}>


            <h3 className={`text-2xl font-righteous text-brandblue`}>Processes</h3>


            <div className={`w-full flex flex-col gap-2`}>
                <Button
                    internalLinkUrl={`/processes`}
                    text={`Manage processes`}
                    fullwidth={false}
                />
                <Button
                    internalLinkUrl={`/results/process`}
                    text={`Process results`}
                    fullwidth={false}
                />
            </div>
        </div>
    </Card>
}
export default ProcessesDashboard