import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from "../formfields/Button"
import Card from "../ui-elements/Card"
import RedAlert from "../ui-elements/RedAlert"
import FormatChecklistTimeWindow from "../checklists/FormatChecklistTimeWindow"


type Props = {
    checklistPeriodId: string
    checklistPeriod: ObjectStringKeyAnyValue
}

function ChecklistPeriodSummary({
    checklistPeriodId,
    checklistPeriod
}: Props) {


    const {
        tableData,
        setShowModal,
        sendMessageToWebsocket,
        processData
    } = useContext(GlobalContext)
    

    const checklistId = checklistPeriod && checklistPeriod.ChecklistId
    const checklist = checklistId && tableData && tableData.Checklists[checklistId]
    const checklistName = checklist && checklist.ChecklistName
    const progress = checklistPeriod && checklistPeriod.Progress


    const fetchCustomChecklistResults = () => {
        setShowModal({"spinner": "Downloading PDF..."})
        const payload = JSON.stringify({
            action: "document",
            subAction: "get_results",
            checklistId: checklistId,
            checkPeriodId: checklistPeriodId,
            customTemplateFileId: checklist && checklist.CustomTemplateFileId
        })
        sendMessageToWebsocket(payload)

        const unsubscribe = subscribe("getResultsResponse", data => {
            setShowModal(null)
            window.location.href = data.downloadUrl
            unsubscribe()
        })
    }


    if (!checklistPeriod) return <RedAlert size={`small`}>No checklist period found for {checklistPeriodId}</RedAlert>

    return <Card>
        <div className={`w-48 flex flex-col gap-1 items-center justify-center text-center`}>
            <p>{checklistName}</p>
            <p className={`text-xs`}>Check period started at: {`${checklistPeriod.StartHoursMinsDay.hours}`.padStart(2, '0')}:{`${checklistPeriod.StartHoursMinsDay.minutes}`.padStart(2, '0')}</p>



            {progress && progress.progress_description && <p className={`text-xs`}>{progress.progress_description}</p>}


            {checklist && checklist.CustomTemplateFileId &&
                <div
                    onClick={fetchCustomChecklistResults}
                    className={`underline text-xs hover:opacity-80 cursor-pointer`}>
                    ⬇️ Download PDF summary
                </div>}
                

            {/* {progress && parseInt(`${progress.progress_percentage}`) === 100 && <p className={`text-xs`}>✅ All  {progress.total_answers} questions completed</p>}
            {progress && parseInt(`${progress.progress_percentage}`) < 100 && <p className={`text-xs`}>⏰ {progress.completed_answers} of {progress.total_answers} questions completed</p>} */}
        </div>
    </Card>
}
export default ChecklistPeriodSummary