import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Text from '../formfields/Text'
import { validateForm } from '../utils/validateForm'
import { removeNonAlphanumeric } from '../utils/removeNonAlphanumeric'
import Dropdown from '../formfields/Dropdown'
import YellowAlert from '../ui-elements/YellowAlert'
import AssociatedItems from '../ui-elements/AssociatedItems'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import AddButton from '../formfields/AddButton'
import AddEditStage from './AddEditStage'
import FooterNavButtonFrame from '../ui-elements/FooterNavButtonFrame'
import JsonDebug from '../ui-elements/JsonDebug'
import ProcessReportingOptionsChecklist from './ProcessReportingOptionsChecklist'

type Props = {
    stageId: string
    stage: ObjectStringKeyAnyValue | null
    formFields: ObjectStringKeyAnyValue
    setFormFields: React.Dispatch<React.SetStateAction<any>>
    formErrors: ObjectStringKeyAnyValue
    setFormErrors: React.Dispatch<React.SetStateAction<any>>
}

function ProcessReportingOptionsStage({
    stageId,
    stage,
    formFields,
    setFormFields,
    formErrors,
    setFormErrors
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)


    if (!stage) {
        return null
    }
    console.log("processReportingOptionsStage stage", stage)
    console.log(stage.Checklists)
    console.log("Length:", stage.Checklists.length)
    console.log(stage.Checklists && stage.Checklists.length > 0 ? "stage.Checklists" : "No checklists for this stage")

    return <div className={`w-full flex flex-col gap-2`}>
        <h3 className={`font-righteous text-xl font-brandblue`}>{stage.DisplayName}</h3>

        {stage.Checklists && stage.Checklists.length > 0 ?
            <div className={`w-full flex flex-col gap-2`}>
                {stage.Checklists.map((checklistId: any, index: number) => {
                    return <ProcessReportingOptionsChecklist
                        key={index}
                        checklist={tableData.Checklists[checklistId]}
                        checklistId={checklistId}
                        stageId={stageId}
                        formFields={formFields}
                        setFormFields={setFormFields}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                    />
                })}
            </div>
            :
            <p>No checklists for this stage</p>
        }

    </div>

}
export default ProcessReportingOptionsStage