import React, { useContext } from 'react'
import { GlobalContext } from '../../GlobalContext'
import RedAlert from '../../ui-elements/RedAlert'
import { questionFormats } from '../checklist-utils/questionFormats'

type Props = {
    questionsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    questionsFormErrors: ObjectStringKeyAnyValue
    setQuestionsFormFields: React.Dispatch<React.SetStateAction<any>>
}

function QuestionFormat({
    questionsFormFields,
    thisChecklist,
    questionsFormErrors,
    setQuestionsFormFields
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)


    const handleChange = (value: string) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...questionsFormFields }
        newFieldValues['QuestionFormat'] = value

        // delete any answer actions as these depend on the question format
        delete newFieldValues['AnswerActions']

        // delete irrelevant data when a new format is selected
        
        if (
            value === `FridgeTemperature` || 
            value === `PhotoAndReading` ||
            value === `AssetReading`
        ) {
            delete newFieldValues['QuestionText']
        }

        if (value !== `FridgeTemperature` && value !== `AssetReading` && value !== `PhotoAndReading`) {
            delete newFieldValues['AssetId']
            delete newFieldValues['AssetTypePropertyId']
            delete newFieldValues['AssetTypeId']
            delete newFieldValues['FridgeId']
        }

        if (value !== `SelectOne` && value !== `SelectMultiple`) {
            delete newFieldValues['QuestionOptions']
        }

        if (value !== `CustomDataFormat`) {
            delete newFieldValues['CustomDataFormatId']
        }

        if (value !== `Number`) {
            delete newFieldValues['MeasurementUnit']
        }

        setQuestionsFormFields(newFieldValues)
    }

    return <div className={`flex flex-col gap-2`}>
        <h3 className={`font-righteous text-brandblue text-lg`}>Question format:</h3>
        <select
            className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}

            name={`QuestionFormat`}
            value={questionsFormFields['QuestionFormat'] || ''}
            onChange={(e) => handleChange(e.target.value)}

        >
            <option value={``}>Please select</option>

            {questionFormats && Object.keys(questionFormats).map((formatId, index) => {
                return <option key={index} value={formatId}>{questionFormats[formatId].displayName}</option>
            })}

        </select>
        {questionsFormErrors['QuestionFormat'] && <RedAlert alignment='left'>{questionsFormErrors['QuestionFormat']}</RedAlert>}
    </div>
}



export default QuestionFormat