



import { useContext, useEffect, useState } from "react"
import Button from "../formfields/Button"
import AddButton from "../formfields/AddButton"
import DeleteButton from "../formfields/DeleteButton"
import XButton from "../formfields/XButton"
import RedAlert from "../ui-elements/RedAlert"
import GreenAlert from "../ui-elements/GreenAlert"
import Card from "../ui-elements/Card"
import { GlobalContext } from "../GlobalContext"
import { subscribe } from "../utils/pubsub"
import { useNavigate, useParams } from "react-router-dom"
import Modal from "../ui-elements/Modal"
import { tableData } from "../tests/testTableData"
import Spinner from "../ui-elements/Spinner"


type Props = {


}

type IndividualBlockProps = {
    blockId: string
    thisBlock: ObjectStringKeyAnyValue
    regexObject: ObjectStringKeyAnyValue
    setRegexObject: React.Dispatch<React.SetStateAction<any>>
    turnBlockDataIntoStringSummary: AnyFunction
    blockNumber: number
    formErrors: ObjectStringKeyAnyValue
}



const presetCharacterOptions = ['Uppercase letter', 'Lowercase letter', 'Number', 'Space']




function IndividualBlock({
    blockId,
    thisBlock,
    regexObject,
    setRegexObject,
    turnBlockDataIntoStringSummary,
    blockNumber,
    formErrors
}: IndividualBlockProps) {

    const [characterOrStringToAdd, setCharacterOrStringToAdd] = useState('')
    const [showOptions, setShowOptions] = useState(true)
    const blockSummary = `${turnBlockDataIntoStringSummary(thisBlock)}`

    const setCharacterOption = (blockId: string, value: string) => {
        const newRegexObject: ObjectStringKeyAnyValue = { ...regexObject }
        const thisBlock = newRegexObject['Blocks'][blockId]
        if (thisBlock.options.includes(value)) {
            thisBlock.options = thisBlock.options.filter((option: string) => option !== value)
        } else {
            thisBlock.options.push(value)
        }
        setRegexObject(newRegexObject)
    }



    const setRepetitionType = (blockId: string, value: string) => {
        const newRegexObject: ObjectStringKeyAnyValue = { ...regexObject }
        const thisBlock = newRegexObject['Blocks'][blockId]
        thisBlock.repetitions = {
            type: value
        }
        setRegexObject(newRegexObject)
    }

    const setRepetitionRangeOrNumber = (blockId: string, key: string, value: number) => {
        const newRegexObject: ObjectStringKeyAnyValue = { ...regexObject }
        const thisBlock = newRegexObject['Blocks'][blockId]
        thisBlock.repetitions[key] = parseInt(`${value}`)
        setRegexObject(newRegexObject)
    }

    const deleteBlock = (blockId: string) => {
        if (Object.keys(regexObject['Blocks']).length > 1) {
            const newRegexObject: ObjectStringKeyAnyValue = { ...regexObject }
            delete newRegexObject['Blocks'][blockId]
            setRegexObject(newRegexObject)
        }
    }


    return <div className={`w-full relative bg-white rounded p-4 flex flex-col gap-4`}>


        <div className={``}>
            <h4 className={`font-righteous text-xl font-brandblue`}>Block {blockNumber}</h4>
            {blockSummary && <div className={`flex flex-row gap-2`}>
                <p className={``}>{blockSummary}</p>

                <div
                    className={`cursor-pointer hover:opacity-80`}
                    onClick={() => setShowOptions(!showOptions)}
                >
                    {showOptions ? '▶️' : '🔽'}
                </div>
            </div>}
        </div>


        <div className={`absolute right-0 top-0 m-1`}>
            {Object.keys(regexObject['Blocks']).length > 1 && <XButton
                clickFunction={() => deleteBlock(blockId)}
            />}
        </div>

        {showOptions && <div className={`flex flex-col gap-4`}>
            <div className={`flex flex-col gap-2`}>
                <p className={`font-bold`}>Characters to match:</p>

                <div className={`flex flex-row flex-wrap gap-2`}>
                    {thisBlock.options.map((option: string, index: number) => {

                        return <Button
                            key={index}
                            text={option}
                            onClick={() => setCharacterOption(blockId, option)}
                            size={`small`}
                            fullwidth={false}
                        />

                    })}
                </div>



                <div className={`w-full rounded border border-gray-300 px-2 py-1 flex flex-row gap-2 items-center justify-between`}>
                    <div className={`w-full flex flex-row items-center gap-2 whitespace-nowrap`}><p className={`text-sm`}>Character or string:</p>

                        <input
                            type="text"
                            className={`w-full rounded border border-gray-400 px-2 py-1 bg-gray-200 `}
                            onChange={(e) => setCharacterOrStringToAdd(e.target.value)}
                            value={characterOrStringToAdd}
                        />
                    </div>
                    {characterOrStringToAdd && !thisBlock.options.includes(characterOrStringToAdd) ? <div className={`hover:opcity-80 rounded bg-green-300 w-6 h-6 flex flex-col items-center justify-center`}
                        onClick={() => {
                            setCharacterOrStringToAdd('')
                            setCharacterOption(blockId, characterOrStringToAdd)
                        }}
                    >✚</div> :
                        <div className={`hover:opcity-80 rounded bg-gray-300 w-6 h-6 flex flex-col items-center justify-center`}>✚</div>
                    }
                </div>
                <div className={`flex flex-row flex-wrap gap-2`}>
                    {presetCharacterOptions.map((option, index) => {
                        if (!thisBlock.options.includes(option)) {
                            return <AddButton
                                key={index}
                                text={option}
                                onClick={() => setCharacterOption(blockId, option)}
                                size={`small`}
                                fullwidth={false}
                            />
                        }
                    })}
                </div>


            </div>



            <div className={`flex flex-col gap-2`}>
                <p className={`font-bold`}>Repetitions:</p>


                <div className={`flex flex-row gap-2 flex-wrap`}>
                    <Button
                        text={`None`}
                        onClick={() => setRepetitionType(blockId, 'none')}
                        variant={thisBlock.repetitions.type === 'none' ? 'primary' : 'gray'}
                        size={`small`}
                        fullwidth={false}
                    />
                    <Button
                        text={`Zero or more`}
                        onClick={() => setRepetitionType(blockId, 'Zero or more')}
                        variant={thisBlock.repetitions.type === 'Zero or more' ? 'primary' : 'gray'}
                        size={`small`}
                        fullwidth={false}
                    />
                    <Button
                        text={`One or more`}
                        onClick={() => setRepetitionType(blockId, 'One or more')}
                        variant={thisBlock.repetitions.type === 'One or more' ? 'primary' : 'gray'}
                        size={`small`}
                        fullwidth={false}
                    />
                    <Button
                        text={`Set number`}
                        onClick={() => setRepetitionType(blockId, 'Set number')}
                        variant={thisBlock.repetitions.type === 'Set number' ? 'primary' : 'gray'}
                        size={`small`}
                        fullwidth={false}
                    />
                    <Button
                        text={`Number range`}
                        onClick={() => setRepetitionType(blockId, 'Number range')}
                        variant={thisBlock.repetitions.type === 'Number range' ? 'primary' : 'gray'}
                        size={`small`}
                        fullwidth={false}
                    />
                </div>


                {thisBlock.repetitions.type === 'Set number' && <div className={`flex flex-row gap-2`}>
                    Enter number: <input
                        type="number"
                        className={`w-16 rounded bg-gray-200 border border-gray-400 px-2 py-1`}
                        onChange={(e) => setRepetitionRangeOrNumber(blockId, 'number', parseInt(e.target.value))}
                        value={thisBlock.repetitions.number || ''}
                    />
                </div>}


                {thisBlock.repetitions.type === 'Number range' && <div className={`flex flex-row gap-2`}>
                    <div className={``}>
                        Min: <input
                            type="number"
                            className={`w-16 rounded bg-gray-200 border border-gray-400 px-2 py-1`}
                            onChange={(e) => setRepetitionRangeOrNumber(blockId, 'min', parseInt(e.target.value))}
                            value={thisBlock.repetitions.min || ''}
                        />
                    </div>
                    <div className={``}>
                        Max: <input
                            type="number"
                            className={`w-16 rounded bg-gray-200 border border-gray-400 px-2 py-1`}
                            onChange={(e) => setRepetitionRangeOrNumber(blockId, 'max', parseInt(e.target.value))}
                            value={thisBlock.repetitions.max || ''}
                        />
                    </div>
                </div>}
            </div>

        </div>}


        {formErrors[blockId] && <RedAlert>{formErrors[blockId]}</RedAlert>}

    </div>
}



function RegexBuilder({

}: Props) {

    const navigate = useNavigate()
    const { regexId } = useParams()
    const [regexObject, setRegexObject] = useState<ObjectStringKeyAnyValue>({ "Blocks": {} })
    const [regexStrings, setRegexStrings] = useState<Array<string>>([])
    const [testString, setTestString] = useState('')
    const [formErrors, setFormErrors] = useState<ObjectStringKeyAnyValue>({})
    const [showTestModal, setShowTestModal] = useState(false)
    const finalRegexString = `^${regexStrings[regexStrings.length - 1]}$`
    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)


    useEffect(() => {
        //setFormErrors({})
        if (!regexId && Object.keys(regexObject['Blocks']).length === 0) {
            addBlock()
        }
        if (Object.keys(regexObject['Blocks']).length > 0) {
            convertRegexObjectToStrings()
        }
    }, [regexObject, regexId])

    useEffect(() => {
        if (regexId && tableData && tableData.CustomDataFormats && tableData.CustomDataFormats[regexId]) {
            const newRegexObject: ObjectStringKeyAnyValue = {}
            newRegexObject['Blocks'] = tableData.CustomDataFormats[regexId]['Blocks']
            newRegexObject['RegexName'] = tableData.CustomDataFormats[regexId]['RegexName']
            setRegexObject(newRegexObject)

        }
    }, [regexId, tableData])

    const doesTestStringMatchRegex = (regexString: string) => {
        let result = ''
        try {
            const rx = new RegExp(regexString)
            const isValid = rx.test(testString)
            if (isValid) {
                result = 'valid'
            } else {
                result = 'invalid'
            }
        } catch (e) {
            result = 'error'
            console.error(`⛔️ Error: ${e}`)
        }
        return result
    }

    const sortKeysByOrder = (obj: ObjectStringKeyAnyValue) => {
        const keys = Object.keys(obj)
        const sortedKeys = keys.sort((a, b) => parseInt(a) - parseInt(b))
        return sortedKeys
    }

    const addBlock = () => {
        const newRegexObject: ObjectStringKeyAnyValue = { ...regexObject }
        const blockId = Date.now()
        newRegexObject['Blocks'][blockId] = {
            options: [],
            repetitions: {}
        }
        setRegexObject(newRegexObject)
    }


    const convertRegexObjectToStrings = () => {
        let regexStrings: string[] = []
        sortKeysByOrder(regexObject['Blocks']).map((blockId) => {
            let regexString = ''
            const thisBlock = regexObject['Blocks'][blockId]

            // set the options
            // if ANY of the options are a multiple character string, wrap them in a group
            let groupPrefix = "["
            let groupSuffix = "]"
            let separator = ""
            const characterStrings = thisBlock.options.filter((option: string) => option.length > 1 && !presetCharacterOptions.includes(option))
            if (characterStrings.length > 0) {
                if (thisBlock.options.length > 1) {
                    groupPrefix = "(?:"
                } else {
                    groupPrefix = "("
                }
                groupSuffix = ")"
                separator = "|"
            }

            if (thisBlock.options.length > 0) {
                regexString += groupPrefix
                thisBlock.options.forEach((option: any, index: number) => {

                    if (presetCharacterOptions.includes(option)) {
                        switch (option) {
                            case 'Uppercase letter':
                                if (characterStrings.length > 0) {
                                    regexString += '[A-Z]'
                                } else {
                                    regexString += 'A-Z'
                                }
                                break
                            case 'Lowercase letter':
                                if (characterStrings.length > 0) {
                                    regexString += '[a-z]'
                                } else {
                                    regexString += 'a-z'
                                }
                                break
                            case 'Number':
                                regexString += '\\d'
                                break
                            case 'Space':
                                regexString += '\\s'
                                break
                        }
                    } else {
                        const specialCharacters = ['\\', '/', '^', '$', '.', '*', '+', '?', '|', '-', '_', '<', '>', '{', '}', '[', ']', '(', ')']
                        // if any of the characters in 'option' are special, add a backslash
                        for (let i = 0; i < option.length; i++) {
                            if (specialCharacters.includes(option[i])) {
                                regexString += '\\'
                            }
                            regexString += option[i]
                        }

                    }
                    // add a separator if not the last option
                    if (index + 1 < thisBlock.options.length) regexString += separator
                })
                regexString += groupSuffix
            }

            // set the repetitions
            if (thisBlock.repetitions.type === 'Zero or more') {
                regexString += '*'
            }
            else if (thisBlock.repetitions.type === 'One or more') {
                regexString += '+'
            }
            else if (thisBlock.repetitions.type === 'Set number' && thisBlock.repetitions.number) {
                regexString += `{${thisBlock.repetitions.number}}`
            }
            else if (thisBlock.repetitions.type === 'Number range' && thisBlock.repetitions.min && thisBlock.repetitions.max) {
                regexString += `{${thisBlock.repetitions.min},${thisBlock.repetitions.max}}`
            }

            if (regexString) {
                const previousString = regexStrings[regexStrings.length - 1]
                if (previousString) {
                    regexString = previousString + regexString
                }
                regexStrings.push(regexString)
            }
        })
        setRegexStrings(regexStrings)
    }


    const turnBlockDataIntoStringSummary = (thisBlock: ObjectStringKeyAnyValue) => {
        let string = ''
        if (thisBlock.options.length > 0) {

            string = 'Match '
            if (thisBlock.repetitions.type === 'Zero or more') {
                string += ' zero or more '
            }
            else if (thisBlock.repetitions.type === 'One or more') {
                string += ' one or more '
            }
            else if (thisBlock.repetitions.type === 'Set number' && thisBlock.repetitions.number) {
                string += ` ${thisBlock.repetitions.number} `
            }
            else if (thisBlock.repetitions.type === 'Number range' && thisBlock.repetitions.min && thisBlock.repetitions.max) {
                string += ` ${thisBlock.repetitions.min} to ${thisBlock.repetitions.max} `
            } else {
                string += ' exactly one'
            }

            thisBlock.options.length > 1 ? string += ' instances of the following: ' : string += '  '

            let optionsString = ''
            thisBlock.options.forEach((option: string, index: number) => {
                const showSeparator = index + 1 < thisBlock.options.length
                const isPreset = presetCharacterOptions.includes(option)
                optionsString += isPreset ? `${option.toLowerCase()}` : `"${option}"`
                optionsString += showSeparator ? ', ' : ''
            })
            string += optionsString
        }
        return string
    }


    const setRegexName = (value: string) => {
        const newRegexObject: ObjectStringKeyAnyValue = { ...regexObject }
        if (value) {
            newRegexObject['RegexName'] = value
        } else {
            delete newRegexObject['RegexName']
        }
        setRegexObject(newRegexObject)
    }


    const validateForm = () => {
        const newErrorsObject: ObjectStringKeyAnyValue = {}
        const existingNames: string[] = []
        if (tableData && tableData.CustomDataFormats) {
            Object.keys(tableData.CustomDataFormats).forEach((key) => {
                if (key !== regexId) {
                    existingNames.push(tableData.CustomDataFormats[key].RegexName)
                }
            })
        }

        if (!regexObject['RegexName']) {
            newErrorsObject['RegexName'] = 'Please enter a name for this custom data format'
        }

        if (existingNames.includes(regexObject['RegexName'])) {
            newErrorsObject['RegexName'] = 'Please enter a unique name'
        }


        if (!regexObject['Blocks'] || Object.keys(regexObject['Blocks']).length === 0) {
            newErrorsObject['Blocks'] = 'Please add at least one block'
        }


        sortKeysByOrder(regexObject['Blocks']).forEach((blockId) => {
            let errorString = ''
            const thisBlock = regexObject['Blocks'][blockId]
            if (!thisBlock.options || thisBlock.options.length === 0) {
                errorString += 'Please select or enter at least one character to match. '
            }
            if (thisBlock.repetitions && thisBlock.repetitions.type && thisBlock.repetitions.type === 'Set number' && !thisBlock.repetitions.number) {
                errorString += 'Please enter a number of repetitions. '
            }
            if (thisBlock.repetitions && thisBlock.repetitions.type && thisBlock.repetitions.type === 'Number range' && (!thisBlock.repetitions.min || !thisBlock.repetitions.max)) {
                errorString += 'Please enter a minimum and maximum number of repetitions. '
            }
            if (thisBlock.repetitions && thisBlock.repetitions.type && thisBlock.repetitions.type === 'Number range' && thisBlock.repetitions.min && thisBlock.repetitions.max && thisBlock.repetitions.min >= thisBlock.repetitions.max) {
                errorString += 'Please ensure maximum number of repetitions is greater than the minimum. '
            }
            if (errorString != '') {
                newErrorsObject[blockId] = errorString
            }
        })

        setFormErrors(newErrorsObject)
        if (Object.keys(newErrorsObject).length === 0) {
            return true
        } else {
            return false
        }
    }

    const saveRegex = () => {
        if (validateForm()) {
            setShowModal({ "spinner": 'Saving...' })
            const Id = regexId ? regexId : `rx-${Date.now()}-${Math.floor(10000 + Math.random() * 90000)}`
            const formData = {
                ...regexObject,
                Id: Id,
                RegexString: finalRegexString
            }
            const payload = JSON.stringify({
                action: "checklists",
                subAction: "saveRegex",
                payload: formData
            })
            sendMessageToWebsocket(payload)
            const unsubscribe = subscribe("regexSaved", data => {
                if (data.Id === Id) {
                    setShowModal(null)
                    navigate('/custom-data-formats')
                }
                unsubscribe()
            })
        }

    }




    return <div className={`w-full flex flex-col items-center`}>
        <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-start`}>


            <h3 className={`font-righteous text-3xl font-brandblue`}>Build custom data format </h3>

   

            {tableData && <div className={`w-full flex flex-col gap-4 items-start`}>
                <Card fullwidth={true}>
                    <div className={`w-full flex flex-col gap-1`}>
                        <h4 className={`font-righteous text-xl font-brandblue`}>Custom data format name: </h4>
                        <input type="text"
                            className={`w-full rounded bg-white border border-gray-400 px-2 py-1`}
                            onChange={(e) => setRegexName(e.target.value)}
                            value={regexObject.RegexName || ''}
                        />
                        {formErrors['RegexName'] && <RedAlert>{formErrors['RegexName']}</RedAlert>}
                    </div>
                </Card>

                {sortKeysByOrder(regexObject['Blocks']).map((blockId, index) => {
                    const thisBlock = regexObject['Blocks'][blockId]
                    return <IndividualBlock
                        key={index}
                        blockNumber={index + 1}
                        blockId={blockId}
                        thisBlock={thisBlock}
                        regexObject={regexObject}
                        setRegexObject={setRegexObject}
                        turnBlockDataIntoStringSummary={turnBlockDataIntoStringSummary}
                        formErrors={formErrors}
                    />
                })}

                {/* <p className={`text-xs text-gray-500`}>{JSON.stringify(regexObject).replaceAll(',', ', ')}</p> */}
                {/* <p className={`text-xs text-gray-500`}>{JSON.stringify(regexStrings)}</p>  */}




                {/* <div className={`flex flex-col gap-1`}>
                <p className={`font-bold`}>Regex: </p>
                <input type="text"
                    className={`w-full rounded bg-white border border-gray-400 px-2 py-1`}
                    value={`${finalRegexString}`}
                />
            </div> */}



                <AddButton
                    text={"Add a new block"}
                    fullwidth={true}
                    onClick={addBlock}
                />


                {formErrors['Blocks'] && <RedAlert>{formErrors['Blocks']}</RedAlert>}



                {showTestModal && <Modal showCloseButton={true} setShowModal={setShowTestModal}>

                    <div className={`flex flex-col gap-4`}>
                        <div className={`flex flex-col gap-1`}>
                            <h4 className={`font-righteous text-xl font-brandblue`}>String to test: </h4>
                            <div className={`flex flex-row gap-2 items-center`}>
                                <input type="text"
                                    className={`w-full rounded bg-white border border-gray-400 px-2 py-1`}
                                    onChange={(e) => setTestString(e.target.value)}
                                    value={testString}
                                />
                            </div>
                        </div>


                        {regexStrings.length > 0 && testString && <div className={`flex flex-col gap-1`}>

                            {regexStrings.length > 1 && regexStrings.map((string, index) => {
                                let regexString = `^${string}`
                                let blockname = `Block ${index + 1}`
                                let validity = doesTestStringMatchRegex(regexString)
                                return <div className={``} key={index}>
                                    {doesTestStringMatchRegex(regexString) === 'error' && <RedAlert icon={true} alignment="left" size="small">{blockname}: Error</RedAlert>}
                                    {doesTestStringMatchRegex(regexString) === 'invalid' && <RedAlert icon={true} alignment="left" size="small">{blockname}</RedAlert>}
                                    {doesTestStringMatchRegex(regexString) === 'valid' && <GreenAlert icon={true} alignment="left" size="small">{blockname}</GreenAlert>}
                                </div>

                            })}

                            <div className={``}>
                                {doesTestStringMatchRegex(finalRegexString) === 'error' && <RedAlert icon={true} alignment="left" size="small">Error</RedAlert>}
                                {doesTestStringMatchRegex(finalRegexString) === 'invalid' && <RedAlert icon={true} alignment="left" size="small">String does not match the entire pattern</RedAlert>}
                                {doesTestStringMatchRegex(finalRegexString) === 'valid' && <GreenAlert icon={true} alignment="left" size="small">String matches the entire pattern</GreenAlert>}
                            </div>

                        </div>}
                    </div>
                </Modal>}



                <div className={`flex flex-row gap-2`}>
                    <Button
                        text={"Test"}
                        fullwidth={false}
                        onClick={() => setShowTestModal(true)}
                        variant={`gray`}
                        size="big"
                    />
                    <Button
                        text={"Save custom data format"}
                        fullwidth={false}
                        onClick={saveRegex}
                        size="big"
                    />
                </div>

            </div>}

        </div>

    </div>


}

export default RegexBuilder