import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from '../utils/pubsub'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import YellowAlert from '../ui-elements/YellowAlert'


type Props = {
    checklistData: ObjectStringKeyAnyValue
}

function QuickStartQRDetails({
    checklistData
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const { checklistId } = useParams()
    const [savingProgress, setSavingProgress] = useState(false)
    const [generatingCode, setGeneratingCode] = useState(false)
    const [showInactiveChecklistModal, setShowInactiveChecklistModal] = useState(false)



    const generateQrCode = () => {
        setGeneratingCode(true)
        setShowInactiveChecklistModal(false)
        setSavingProgress(false)
        const payload = JSON.stringify({
            action: "checklists",
            subAction: "generateQrCode",
            checklistId: checklistId
        })
        sendMessageToWebsocket(payload)
        const unsubscribe = subscribe("qrCodeGenerated", data => {
            // console.log(data)
            if (data.checklistId === checklistId) {
                setGeneratingCode(false)
                if (data.downloadUrl) {
                    console.log(data.downloadUrl)
                    window.location.href = data.downloadUrl
                }
                unsubscribe()
            }
        })
    }

    const setChecklistActive = () => {
        if (checklistData) {
            setGeneratingCode(false)
            setShowInactiveChecklistModal(false)
            setSavingProgress(true)
            const payload = JSON.stringify({
                action: "checklists",
                subAction: "addOrUpdate",
                payload: {
                    "Id": checklistData['Id'],
                    "isChecklistActive": true
                }
            })
            // console.log(payload)
            sendMessageToWebsocket(payload)
            const unsubscribe = subscribe("tableUpdateItemModified", data => {
                console.log(data)
                console.log(`table update modified. Component ID: ${checklistData['Id']} and data ID: ${data.newRecord['Id']}`)
                if (
                    data.newRecord &&
                    data.newRecord['Id'] &&
                    data.newRecord['Id'] === checklistData['Id']
                ) {
                    setSavingProgress(false)
                    generateQrCode()
                    unsubscribe()
                }
            })
        }
    }



    return <Card fullwidth={true}>
        <div className={`md:w-48 w-full h-full flex flex-col gap-4 justify-between`}>
                <div className={`w-full flex flex-col gap-2`}>
                    <h4 className={`font-righteous text-xl font-brandblue`}>Quick start QR codes</h4>
                    <p>Generate a QR code so you and your team can quickly scan the code to start the checklist.</p>
                </div>


                    <Button
                        text={`Create code`}
                        onClick={checklistData && checklistData['isChecklistActive'] ?
                            generateQrCode
                            :
                            () => setShowInactiveChecklistModal(true)
                        }
                        variant='primary'
                        fullwidth={true}
                    // size={'small'}
                    />
            </div>

            {showInactiveChecklistModal && <Modal>
            <p className='text-center mb-4'>Checklist must be enabled before a QR code can be generated. Do you want to enable the checklist & generate the code now?</p>


            <div className={`flex flex-row gap-2`}>
                <Button
                    text={`Cancel`}
                    onClick={() => setShowInactiveChecklistModal(false)}
                    variant='gray'
                    fullwidth={true}
                // size={'small'}
                />
                <Button
                    text={`Enable`}
                    onClick={setChecklistActive}
                    variant='primary'
                    fullwidth={true}
                // size={'small'}
                />
            </div>

        </Modal>}

        {generatingCode && <Modal
            showCloseButton={true}
            setShowModal={setGeneratingCode}
        ><Spinner><p>Generating code...</p></Spinner>
        </Modal>}

        {savingProgress && <Modal
            showCloseButton={true}
            setShowModal={setSavingProgress}
        ><Spinner><p>Updating checklist...</p></Spinner>
        </Modal>}

        </Card>
}
export default QuickStartQRDetails