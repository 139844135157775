import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { subscribe } from "../utils/pubsub"
import CloudformationOutputs from '../CloudformationOutputs.json'
import FormatQuestionAnswer from '../checklists/FormatQuestionAnswer'
import Button from '../formfields/Button'
import SubNavigation from '../shared-components/SubNavigation'
import { GlobalContext } from '../GlobalContext'
import Card from '../ui-elements/Card'
import GreenAlert from '../ui-elements/GreenAlert'
import RedAlert from '../ui-elements/RedAlert'
import { getDate } from '../utils/getDate'
import { getDateFromMonthYearDay } from '../utils/getDateFromMonthYearDay'
import { getDaysInMonth } from '../utils/getDaysInMonth'
import { getStartAndEndTimestampsForDay } from '../utils/getStartAndEndTimestampsForDay'
import { getStartAndEndTimestampsForMonth } from '../utils/getStartAndEndTimestampsForMonth'
import Spinner from '../ui-elements/Spinner'
import FormatChecklistTimeWindow from '../checklists/FormatChecklistTimeWindow'
import FormatQuestionAnsweredBy from '../checklists/FormatQuestionAnsweredBy'
import YellowAlert from '../ui-elements/YellowAlert'
import Modal from '../ui-elements/Modal'
import { sortChecklistDataByTimePeriod } from '../checklists/checklist-utils/sortChecklistDataByTimePeriod'
import ProgressBar from '../formfields/ProgressBar'



type CheckPeriodProps = {
    checkperiodId: string
}

type CheckPeriodDetailsProps = {
    checklistName: string | null
    thisCheckPeriod: ObjectStringKeyAnyValue
    setShowDetailsModal: React.Dispatch<React.SetStateAction<boolean>>
    answerNotes: ObjectStringKeyAnyValue | null
    answerAlerts: ObjectStringKeyAnyValue[] | null
}

function CheckPeriodDetails({
    checklistName,
    thisCheckPeriod,
    setShowDetailsModal,
    answerNotes,
    answerAlerts,
}: CheckPeriodDetailsProps) {

    const {
        tableData,
        currentOrganisation
    } = useContext(GlobalContext)

    const navigate = useNavigate()

    function getYearMonthDayFromTimestampMillis(timestamp: number) {
        const date = new Date(timestamp)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return [year, month, day]
    }


    function showFullResultsForCheckPeriod(thisCheckPeriod: ObjectStringKeyAnyValue) {
       // console.log("Show full results for check period", thisCheckPeriod)
        // need to navigate to e.g. /checklists/1741626266582/results/2025/3
        // checklists/:checklistId/results/:year/:month
        // checklist id, year and month are all in thisCheckPeriod
        const checklistId = thisCheckPeriod.ChecklistId
        const StartTimeStamp = thisCheckPeriod.StartTimeStamp
        console.log("Checklist id", checklistId)
        console.log("Start timestamp", StartTimeStamp)
        
        const [year,month,day] = getYearMonthDayFromTimestampMillis(StartTimeStamp)
        const path = `/checklists/${checklistId}/results/${year}/${month}/${day}`
        console.log("Path", path)
        navigate(path)
    }

    function getNameForAsset(assetId: string) {
        if(tableData && tableData.Assets && tableData.Assets[assetId] && tableData.Assets[assetId].AssetName) {
            return tableData.Assets[assetId].AssetName
        }
        return assetId
    }


    return <Modal setShowModal={setShowDetailsModal} showCloseButton={true}>

        <div className={`flex flex-col gap-6`}>

            <div className={`flex flex-col gap-1`}>
                <h3 className={`font-righteous text-3xl text-brandblue`}>
                    {checklistName ? checklistName : `Checklist #${thisCheckPeriod.ChecklistId}`} 
                     {thisCheckPeriod.AssetId && ` for ${getNameForAsset(thisCheckPeriod.AssetId)}`} 
                    </h3>
                    {thisCheckPeriod.AssetId && <i>asset Id: {thisCheckPeriod.AssetId}</i> }

                <p className={`text-sm`}>
                    Check period:
                    <FormatChecklistTimeWindow thisCheckPeriod={thisCheckPeriod}  />
                </p>
            </div>

            {/* <p className={`text-xs italic text-gray-600`}>{JSON.stringify(thisCheckPeriod)}</p> */}

            {thisCheckPeriod.Signoff && <div className={`flex flex-col gap-1`}>
                <h3 className={`font-righteous text-xl text-brandblue`}>Checklist signed off</h3>
                <p className={`text-xs italic text-gray-600`}>Signed off on {getDate(thisCheckPeriod.Signoff.TimeStamp, 'formattedDateAndTime')} by <FormatQuestionAnsweredBy questionAnswer={thisCheckPeriod.Signoff} /></p>
                {thisCheckPeriod.Signoff.Signature && <img
                    src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${thisCheckPeriod.Signoff.Signature}`}
                    alt={`Signoff signature`}
                    className={`border border-gray-400 mt-4 max-w-64 max-h-64 rounded`}
                />}
            </div>}

            <div className={`flex flex-col gap-1`}>
                <h3 className={`font-righteous text-xl text-brandblue`}>Alerts</h3>
                {thisCheckPeriod.alertsForCheckPeriod && thisCheckPeriod.alertsForCheckPeriod.length > 0 ?
                    <div className={`mt-2 flex flex-col gap-2`}>
                        {thisCheckPeriod.alertsForCheckPeriod.map((thisAlert: any, index: number) => {
                            const questionText = tableData && tableData.ChecklistQuestions && tableData.ChecklistQuestions[thisAlert.QuestionId] && tableData.ChecklistQuestions[thisAlert.QuestionId].QuestionText
                            return <RedAlert key={index}>
                                <div className={`flex flex-col gap-2 items-center justify-center`}>
                                    <p className={`font-bold`}>{questionText ?
                                        questionText :
                                        `Question Id: ${thisAlert.QuestionId}`
                                    }:</p>
                                    <p className={`text-sm`}>{thisAlert.message}</p>
                                </div>
                            </RedAlert>
                        })}
                    </div> :
                    <p>✅ No alerts</p>
                }</div>

            <div className={`flex flex-col gap-1`}>
                <h3 className={`font-righteous text-xl text-brandblue`}>Notes</h3>
                {thisCheckPeriod.notesForCheckPeriod && Object.keys(thisCheckPeriod.notesForCheckPeriod).length > 0 ?
                    <div className={`mt-2 flex flex-col gap-2`}>
                        {Object.keys(thisCheckPeriod.notesForCheckPeriod).map((noteId, index) => {
                            const thisNote = thisCheckPeriod.notesForCheckPeriod[noteId]
                            const questionText = tableData && tableData.ChecklistQuestions && tableData.ChecklistQuestions[thisNote.QuestionId] && tableData.ChecklistQuestions[thisNote.QuestionId].QuestionText
                            return <YellowAlert key={index}>
                                <div className={`flex flex-col gap-2 items-center justify-center`}>

                                    <p className={`font-bold`}>{questionText ?
                                        questionText :
                                        `Question Id: ${thisNote.QuestionId}`
                                    }:</p>

                                    {thisNote.PhotoNoteFileName && typeof thisNote.PhotoNoteFileName === 'string' && <img
                                        src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${thisNote.PhotoNoteFileName}`}
                                        alt=''
                                        className={`rounded bg-gray-300 min-w-12 min-h-12 max-w-36 max-h-36`}
                                    />}
                                    {thisNote.AnswerNote && <p>"{thisNote.AnswerNote}"</p>}
                                    <p className={`text-xs italic text-gray-600`}>Added by <FormatQuestionAnsweredBy questionAnswer={thisNote} /> on  {getDate(thisNote.Timestamp, 'formattedDateAndTime')}</p>
                                </div>
                            </YellowAlert>
                        })}
                    </div> :
                    <p>✅ No notes</p>
                }</div>
            <Button
                text={`Full results`}
                onClick={() => showFullResultsForCheckPeriod(thisCheckPeriod)}
                variant={'primary'}
                size={`small`}
                fullwidth={false}
            />
          {/*  <Button
                text={`Close`}
                onClick={() => setShowDetailsModal(false)}
                variant={`primary`}
                size={`small`}
                fullwidth={false}
            />*/
          }
        </div>
    </Modal>
}



function CheckPeriod({
    checkperiodId
}: CheckPeriodProps) {

    const {
        tableData,
        currentOrganisation
    } = useContext(GlobalContext)

    const [answerNotes, setAnswerNotes] = useState<ObjectStringKeyAnyValue | null>(null)
    const [answerAlerts, setAnswerAlerts] = useState<Array<ObjectStringKeyAnyValue> | null>(null)
    const [showDetailsModal, setShowDetailsModal] = useState(false)

    const thisCheckPeriod = tableData && tableData.ChecklistPeriods && tableData.ChecklistPeriods[checkperiodId] ? tableData.ChecklistPeriods[checkperiodId] : null
    const isChecklistComplete = thisCheckPeriod && thisCheckPeriod.numberOfAnswers > 0 && thisCheckPeriod.numberOfAnswers === thisCheckPeriod.numberOfCompletedAnswers ? true : false
    const checklistName = tableData && tableData.Checklists && thisCheckPeriod && thisCheckPeriod.ChecklistId && tableData.Checklists[thisCheckPeriod.ChecklistId] && tableData.Checklists[thisCheckPeriod.ChecklistId].ChecklistName
    const signoffRequired = tableData && tableData.Organisation && tableData.Organisation.ChecklistOptions && tableData.Organisation.ChecklistOptions.RequireSignoff

    
    function getNameForAsset(assetId: string) {
        if(tableData && tableData.Assets && tableData.Assets[assetId] && tableData.Assets[assetId].AssetName) {
            return tableData.Assets[assetId].AssetName
        }
        return assetId
    }




    return <div>
        {showDetailsModal && <CheckPeriodDetails
            thisCheckPeriod={thisCheckPeriod}
            checklistName={checklistName}
            setShowDetailsModal={setShowDetailsModal}
            answerNotes={answerNotes}
            answerAlerts={answerAlerts}
        />}

        <div
            className={`w-full flex flex-col justify-between items-start gap-1 px-2 py-1 rounded bg-gray-200 hover:opacity-80 cursor-pointer`}
            onClick={() => {
                setShowDetailsModal(true)
            }}
        >

            <div className={`w-full flex flex-col gap-1`}>
                <div className={`w-full flex flex-col flex-wrap items-start`}>
                    <p className={`text-sm`}>
                        {checklistName ? checklistName : `Checklist #${thisCheckPeriod.ChecklistId}`}
                        {thisCheckPeriod.AssetId && <> for {getNameForAsset(thisCheckPeriod.AssetId)} 
                      
                        </> }
                    </p>
                    <p className={`text-xs italic text-gray-600`}>
                        Check period:
                        <FormatChecklistTimeWindow thisCheckPeriod={thisCheckPeriod} hideAssetId={true}/>
                    </p>
                    {/* {thisCheckPeriod.Id} */}
                    {/* <p className={`text-xs italic text-gray-600`}>{thisCheckPeriod.Id}</p> */}

                    {/* {getDate(thisCheckPeriod.StartTimeStamp, 'formattedDateAndTime')} - {getDate(thisCheckPeriod.EndTimeStamp, 'formattedDateAndTime')} */}


                    {/* {thisCheckPeriod && JSON.stringify(thisCheckPeriod.Progress).replaceAll(',', ',\n')} */}

                    {thisCheckPeriod && thisCheckPeriod.Progress && thisCheckPeriod.Progress.progress_description ?
                        <p className={`text-xs italic text-gray-600`}>{thisCheckPeriod.Progress.progress_description}</p> : ''}


                </div>

                {thisCheckPeriod && thisCheckPeriod.Progress && thisCheckPeriod.Progress.progress_percentage && thisCheckPeriod.Progress.progress_percentage < 100 ? <ProgressBar size={'small'} progress={thisCheckPeriod.Progress.progress_percentage} /> : ''}

            </div>

            <div className={`flex flex-row gap-2`}>
                {thisCheckPeriod.alertsForCheckPeriod && thisCheckPeriod.alertsForCheckPeriod.length > 0 &&
                    <RedAlert size='small'> {thisCheckPeriod.alertsForCheckPeriod.length} alerts</RedAlert>}


                {thisCheckPeriod.notesForCheckPeriod && thisCheckPeriod.notesForCheckPeriod.length > 0 &&
                    <YellowAlert size='small'> {thisCheckPeriod.notesForCheckPeriod.length} notes</YellowAlert>}

             
            </div>
        </div>
    </div>
}
export default CheckPeriod