import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from "../formfields/Button"


type Props = {
    setShowResults: React.Dispatch<React.SetStateAction<any>>
}

function ResultsSelector({
    setShowResults
}: Props) {


    const {
        tableData,
        setShowModal,
        sendMessageToWebsocket,
        selectedResultsDate,
        setSelectedResultsDate
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const { resultType, processDefinitionId, year, month, day } = useParams()
    const [selectedProcessDefinitionId, setSelectedProcessDefinitionId] = useState(processDefinitionId)
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const currentDay = new Date().getDate()

    // get the year, month and day for yesterday
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).getDate()
    const yesterdayMonth = new Date(new Date().setDate(new Date().getDate() - 1)).getMonth() + 1
    const yesterdayYear = new Date(new Date().setDate(new Date().getDate() - 1)).getFullYear()
    const yesterdayDay = new Date(new Date().setDate(new Date().getDate() - 1)).getDate()
    const dateSelectorValue = selectedResultsDate ? `${selectedResultsDate.year}-${selectedResultsDate.month < 10 ? `0${selectedResultsDate.month}` : selectedResultsDate.month}-${selectedResultsDate.day < 10 ? `0${selectedResultsDate.day}` : selectedResultsDate.day}` : ""

    useEffect(() => {
        if (year && month && day) {
            setSelectedResultsDate({ "year": year, "month": month, "day": day })
        }
    }, [year, month, day])

    const selectDate = (dateDetails: ObjectStringKeyAnyValue) => {
        setSelectedResultsDate({ "year": dateDetails.year, "month": dateDetails.month, "day": dateDetails.day })
    }

    return <div className={`flex flex-col gap-4 items-center justify-center`}>


        {resultType === "process" && tableData.ProcessDefinitions && Object.keys(tableData.ProcessDefinitions).length > 0 &&
            <div className={`w-full flex flex-col gap-2 items-center justify-center`}>
                <h4 className={`font-righteous text-brandblue text-xl`}>Show results for process:</h4>
                <div className={`w-full flex flex-row gap-2 items-center justify-center`}>
                    {Object.keys(tableData.ProcessDefinitions).map((processDefinitionId: string, index: number) => {
                        const thisProcess = tableData.ProcessDefinitions[processDefinitionId]
                        return <Button
                            key={index}
                            onClick={() => setSelectedProcessDefinitionId(processDefinitionId)}
                            text={thisProcess.DisplayName}
                            variant={selectedProcessDefinitionId === processDefinitionId ? "primary" : "gray"}
                            fullwidth={false}
                        />
                    })}
                </div>
            </div>}

        <div className={`w-full flex flex-col gap-2 items-center justify-center`}>
            <h4 className={`font-righteous text-brandblue text-xl`}>And time period:</h4>
            <div className={`w-full flex flex-row gap-2 items-center justify-center`}>
                <Button
                    onClick={() => { selectDate({ "year": currentYear, "month": currentMonth, "day": currentDay }) }}
                    text={`Today`}
                    variant={
                        selectedResultsDate &&
                        parseInt(`${selectedResultsDate.year}`) === parseInt(`${currentYear}`) &&
                        parseInt(`${selectedResultsDate.month}`) === parseInt(`${currentMonth}`) &&
                        parseInt(`${selectedResultsDate.day}`) === parseInt(`${currentDay}`)
                            ? "primary"
                            : "gray"}
                    fullwidth={false}
                />
                <Button
                    onClick={() => { selectDate({ "year": yesterdayYear, "month": yesterdayMonth, "day": yesterdayDay }) }}
                    text={`Yesterday`}
                    variant={
                        selectedResultsDate &&
                            parseInt(`${selectedResultsDate.year}`) === parseInt(`${yesterdayYear}`) &&
                            parseInt(`${selectedResultsDate.month}`) === parseInt(`${yesterdayMonth}`) &&
                            parseInt(`${selectedResultsDate.day}`) === parseInt(`${yesterdayDay}`)
                            ? "primary"
                            : "gray"}
                    fullwidth={false}
                />

                <input
                    type="date"
                    className={`border-2 border-gray-300 py-1 px-2 rounded-md`}
                    value={dateSelectorValue}
                    onChange={(e) => {
                        const date = new Date(e.target.value)
                        selectDate({ "year": date.getFullYear(), "month": date.getMonth() + 1, "day": date.getDate() })
                    }} />

            </div>
        </div>



        {resultType === "process" && <Button
            onClick={() => {
                setShowResults(true)
                navigate(`/results/process/${selectedProcessDefinitionId}/${selectedResultsDate?.year}/${selectedResultsDate?.month}/${selectedResultsDate?.day}`)
            }}
            text={`Show process results`}
            variant={
                selectedProcessDefinitionId && selectedResultsDate &&
                    selectedResultsDate.year && selectedResultsDate.month && selectedResultsDate.day
                    ? "primary"
                    : "gray"
            }
            disabled={
                selectedProcessDefinitionId && selectedResultsDate &&
                    selectedResultsDate.year && selectedResultsDate.month && selectedResultsDate.day
                    ? false
                    : true
            }
            fullwidth={false}
            size={`big`}
        />}


    </div>
}
export default ResultsSelector