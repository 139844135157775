import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from "../formfields/Button"
import { getDate } from "../utils/getDate"
import ChecklistPeriodSummary from "./ChecklistPeriodSummary"
import YellowAlert from "../ui-elements/YellowAlert"
import Spinner from "../ui-elements/Spinner"
import Modal from "../ui-elements/Modal"


type Props = {
    processDefinitionId?: string
    noRecordsFound: boolean
}

function ProcessResultsList({
    processDefinitionId,
    noRecordsFound
}: Props) {


    const {
        tableData,
        setShowModal,
        sendMessageToWebsocket,
        processData,
        selectedResultsDate
    } = useContext(GlobalContext)
    const [savingProgress, setSavingProgress] = useState(false)

    const processDefinition = processDefinitionId && processData && processData[processDefinitionId]
    const oreredStageIds = processDefinition && processDefinition.OrderedStageIds
    const processRecords = processDefinition && processDefinition.ProcessRecords

    function start_report_download(reportId: string, processRecordId: string) {
        setSavingProgress(true)
        const payload = JSON.stringify({
            action: "document",
            subAction: "process_report_download",
            processDefinitionId: processDefinitionId,
            processRecordId: processRecordId,
            reportId: reportId
        })
        sendMessageToWebsocket(payload)

        const unsubscribe = subscribe("processReportDownloadResponse", data => {
            setSavingProgress(false)
            window.location.href = data.downloadUrl
            unsubscribe()
        })
    }



    //console.log(processDefinition)




    if (
        (!tableData.ChecklistPeriods || 
        !tableData.ProcessRecords || 
        !tableData.ProcessDefinitions || 
        !processData) && !noRecordsFound
    ){
        
        
        return <div>
            <Spinner>Loading results...</Spinner>
            {tableData && <> 
            {/* <p>{tableData.ChecklistPeriods ? '✅' : '⛔️'} Checklist periods</p>
            <p>{tableData.ProcessRecords ? '✅' : '⛔️'} Process Records</p>
            <p>{tableData.ProcessDefinitions ? '✅' : '⛔️'} Process Definitions</p>
            <p>{processData ? '✅' : '⛔️'} processData</p> */}
            </>}
            </div>
    }



    return <div className={`flex flex-col gap-4 items-center justify-center`}>
        {savingProgress && <Modal
            showCloseButton={true}
            setShowModal={setSavingProgress}
        ><Spinner><p>Generating PDF...</p></Spinner>
        </Modal>}


        {!noRecordsFound && (processRecords && Object.keys(processRecords).length === 0) && <Spinner>Loading...</Spinner>}

        {noRecordsFound && processRecords && Object.keys(processRecords).length === 0 && <YellowAlert size={`small`}>No records for this date: {selectedResultsDate.day}/{selectedResultsDate.month}/{selectedResultsDate.year}</YellowAlert>}

        {processRecords && Object.keys(processRecords).length > 0 && <div className={`w-full flex flex-col gap-8 items-center justify-center`}>
            {Object.keys(processRecords).map((processRecordId: string, index: number) => {
                const processRecord = processRecords[processRecordId]
                const isLast = index === Object.keys(processRecords).length - 1
                return <div key={index} className={`w-full flex flex-col gap-6 items-center justify-center pb-10
                ${!isLast ? ' border-b-2 border-gray-400 ' : ''}`}>

                    <div className={`w-full flex flex-col gap-3 items-center justify-center`}>
                        {processRecord.StartTimeStamp && <p className={`text-center text-xl font-righteous text-brandblue`}>Records for process started: {getDate(processRecord.StartTimeStamp, 'shortDateAndTime')}</p>}

                        {Object.entries(processDefinition.DownloadableReports).map((key_value: any) => {
                            const report = key_value[1]

                            return <div key={index} className={`w-full flex flex-row gap-2 items-center justify-center`}>
                                <Button
                                    text={`Download ${report.DisplayName}`}
                                    onClick={() => {
                                        start_report_download(report.ReportId, processRecordId)
                                    }}
                                    fullwidth={false}
                                />
                            </div>

                        })}
                    </div>

                    {oreredStageIds && <div className={`w-full flex flex-col gap-4 items-center justify-center`}>
                        {oreredStageIds.map((stageId: string, index: number) => {
                            const stage = processDefinition && processDefinition.Stages[stageId]
                            const stageName = stage && stage.DisplayName
                            const progressForThisStage = processRecord && processRecord.StageProgress[stageId]
                            const checklistPeriodIdsFromStageProgress = progressForThisStage && progressForThisStage.ChecklistPeriodIds
                            const checklistPeriodIdsArray = checklistPeriodIdsFromStageProgress && Object.values(checklistPeriodIdsFromStageProgress)

                            return <div key={index} className={`w-full flex flex-col gap-2 items-center justify-center`}>
                                <p className={`font-bold`}>{stageName}</p>



                                <div className={`w-full flex flex-row flex-wrap gap-2 items-center justify-center`}>
                                    {checklistPeriodIdsArray && checklistPeriodIdsArray.length > 0 && checklistPeriodIdsArray.map((checklistPeriodId: string, index: number) => {
                                        // const thisChecklistPeriod = processRecord && processRecord.CheckPeriods[checklistPeriodId]
                                        const thisChecklistPeriod = tableData && tableData.ChecklistPeriods && tableData.ChecklistPeriods[checklistPeriodId]
                                        return <ChecklistPeriodSummary
                                            key={index}
                                            checklistPeriodId={checklistPeriodId}
                                            checklistPeriod={thisChecklistPeriod}
                                        />
                                    })}

                                    {(!checklistPeriodIdsArray || checklistPeriodIdsArray.length === 0) && <p className={`text-center text-xs`}>No checklist periods found for this stage</p>}
                                </div>

                            </div>
                        })}
                    </div>}

                </div>
            })}
        </div>}


    </div>
}
export default ProcessResultsList