import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from '../utils/pubsub'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import YellowAlert from '../ui-elements/YellowAlert'
import { startNewQuestion } from './checklist-utils/startNewQuestion'
import ChecklistWrapper from './wrapper/ChecklistWrapper'
import XButton from '../formfields/XButton'
import DeleteButton from '../formfields/DeleteButton'
import JsonDebug from '../ui-elements/JsonDebug'
import RedAlert from '../ui-elements/RedAlert'


type Props = {
    settingsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    settingsFormErrors: ObjectStringKeyAnyValue
    setSettingsFormFields: React.Dispatch<React.SetStateAction<any>>
    setSettingsFormErrors: React.Dispatch<React.SetStateAction<any>>
}

function CustomTemplateFields({
    settingsFormFields,
    thisChecklist,
    settingsFormErrors,
    setSettingsFormFields,
    setSettingsFormErrors
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setChecklistQuestionFields
    } = useContext(GlobalContext)

    const { checklistId } = useParams()
    const navigate = useNavigate()


    const customTemplateFields = thisChecklist && settingsFormFields.CustomTemplateFields ? settingsFormFields.CustomTemplateFields : null


    const addField = () => {
        const newSettingsFormFields = { ...settingsFormFields }
        if (!newSettingsFormFields.CustomTemplateFields) {
            newSettingsFormFields.CustomTemplateFields = {}
        }
        const newId = `cf-${Date.now()}-${Math.floor(Math.random() * 1000)}`
        newSettingsFormFields.CustomTemplateFields[newId] = {
            ValueType: '',
            SpreadsheetCellReference: ''
        }
        setSettingsFormFields(newSettingsFormFields)
    }

    const deleteField = (customTemplateFieldId: string) => {
        const newSettingsFormFields = { ...settingsFormFields }
        delete newSettingsFormFields.CustomTemplateFields[customTemplateFieldId]
        setSettingsFormFields(newSettingsFormFields)
    }

    const handleChange = (customTemplateFieldId: string, key: string, value: string) => {
        console.log(`handleChange: `, customTemplateFieldId, key, value)
        const newSettingsFormFields: ObjectStringKeyAnyValue = { ...settingsFormFields }
        if (!newSettingsFormFields.CustomTemplateFields[customTemplateFieldId]) {
            newSettingsFormFields.CustomTemplateFields[customTemplateFieldId] = {}
        }
        newSettingsFormFields.CustomTemplateFields[customTemplateFieldId] = { ...newSettingsFormFields.CustomTemplateFields[customTemplateFieldId], [key]: value }
        console.log(`newSettingsFormFields: `, newSettingsFormFields)
        setSettingsFormFields(newSettingsFormFields)
    }



    return <div className={`w-full flex flex-col gap-4`}>
        <div className={`flex flex-row gap-4 items-center justify-between mb-4 `}>
            <h3 className={`font-righteous text-3xl font-brandblue`}>{checklistId && tableData && tableData.Checklists && tableData.Checklists[checklistId] && tableData.Checklists[checklistId].ChecklistName ?
                tableData.Checklists[checklistId].ChecklistName :
                "New Checklist"
            }

            </h3>

            <div className={`flex flex-row gap-1`}>

                <Button
                    internalLinkUrl={`/checklists/${checklistId}/`}
                    text={`Checklist settings`}
                    variant={`primary`}
                    // size={`big`}
                    fullwidth={false}
                />

                <Button
                    internalLinkUrl={`/checklists`}
                    text={`All checklists`}
                    variant={`primary`}
                    // size={`big`}
                    fullwidth={false}
                />

            </div>


        </div>


        {/* <JsonDebug jsonobject={customTemplateFields} /> */}

        <Card>

            <div className={`flex flex-row gap-4 items-center justify-between mb-4 `}>

                <h3 className={`font-righteous text-xl font-brandblue`}>
                    Custom template fields
                </h3>
                <Button
                    onClick={addField}
                    text={`Add`}
                    variant={`primary`}
                    // size={`big`}
                    fullwidth={false}
                />
            </div>



            {(!customTemplateFields || Object.keys(customTemplateFields).length === 0) &&
                <YellowAlert>
                    No custom template fields set up for this checklist
                </YellowAlert>}

            {customTemplateFields && Object.keys(customTemplateFields).length > 0 &&
                <div className={`flex flex-col gap-2`}>

                    {Object.keys(customTemplateFields).map((customTemplateFieldId: string, index: number) => {
                        const customTemplateField = customTemplateFields[customTemplateFieldId]
                        return <div key={index} className={`relative border border-gray-400 rounded py-2 pl-2 pr-2 flex flex-col gap-2`}>
                            <div className={`flex flex-row items-center justify-between gap-4`}>
                                <div className={`flex flex-row gap-4`}>
                                    <div className={`flex flex-col`}>
                                        <p className={`font-bold`}>Value type: </p>
                                        <select
                                            className={`w-40 bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                                            name={`ValueType`}
                                            value={customTemplateField.ValueType || ''}
                                            onChange={(e) => handleChange(customTemplateFieldId, `ValueType`, e.target.value)}
                                        >
                                            <option value=''>Please select</option>
                                            <option value='UserName'>User name</option>
                                            <option value='StartedDate'>Started date</option>
                                            <option value='SignoffSignature'>Signoff signature</option>
                                        </select>
                                    </div>
                                    <div key={index} className={`flex flex-col`}>
                                        <p className={`font-bold`}>Cell reference: </p>

                                        <input
                                            type={`text`}
                                            className={`w-40 bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                                            name={`SpreadsheetCellReference`}
                                            value={customTemplateField.SpreadsheetCellReference || ''}
                                            onChange={(e) => handleChange(customTemplateFieldId, `SpreadsheetCellReference`, e.target.value)}
                                        />

                                    </div>
                                </div>
                                <DeleteButton
                                    deleteFunction={deleteField}
                                    functionParams={customTemplateFieldId}
                                    fullwidth={false}
                                />

                            </div>

                            {settingsFormErrors && settingsFormErrors[`CustomTemplateFields-${customTemplateFieldId}`] && <RedAlert>{settingsFormErrors[`CustomTemplateFields-${customTemplateFieldId}`]}</RedAlert>}

                        </div>
                    })}

                </div>}
        </Card>
    </div>
}
export default CustomTemplateFields