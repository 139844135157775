import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Text from '../formfields/Text'
import { validateForm } from '../utils/validateForm'
import { removeNonAlphanumeric } from '../utils/removeNonAlphanumeric'
import Dropdown from '../formfields/Dropdown'
import YellowAlert from '../ui-elements/YellowAlert'
import AssociatedItems from '../ui-elements/AssociatedItems'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import AddButton from '../formfields/AddButton'
import AddEditStage from './AddEditStage'
import FooterNavButtonFrame from '../ui-elements/FooterNavButtonFrame'
import Card from '../ui-elements/Card'
import EnableOrDisableChecklist from '../checklists/EnableOrDisableChecklist'
import EnabledDisabled from '../formfields/EnabledDisabled'

type Props = {
    checklistId: string
    stageId: string
    checklist: ObjectStringKeyAnyValue | null
    formFields: ObjectStringKeyAnyValue
    setFormFields: React.Dispatch<React.SetStateAction<any>>
    formErrors: ObjectStringKeyAnyValue
    setFormErrors: React.Dispatch<React.SetStateAction<any>>
}

function ProcessReportingOptionsChecklist({
    checklistId,
    stageId,
    checklist,
    formFields,
    setFormFields,
    formErrors,
    setFormErrors
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)

    const { processDefinitionId, reportingOptionId } = useParams()
    const CustomTemplateFileId = checklist && checklist.CustomTemplateFileId

    const handleChangeReportingOption = (key: string, value: any) => {
        if (reportingOptionId && formFields['Reports'] && formFields['Reports'][reportingOptionId]) {
            const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
            const thisReportOptions = newFieldValues['Reports'][reportingOptionId]
            if (!thisReportOptions['StageOptions']) {
                thisReportOptions['StageOptions'] = {}
            }
            if (!thisReportOptions['StageOptions'][stageId]) {
                thisReportOptions['StageOptions'][stageId] = {}
            }
            if (!thisReportOptions['StageOptions'][stageId][checklistId]) {
                thisReportOptions['StageOptions'][stageId][checklistId] = {}
            }
            if (!value) {
                delete thisReportOptions['StageOptions'][stageId][checklistId][key]
            } else {
                thisReportOptions['StageOptions'][stageId][checklistId][key] = value
            }
            setFormFields(newFieldValues)
        } else {
            console.log('⛔️ Error')
        }
    }

    const toggleIncludeInReporting = (fieldName: string, value: boolean) => {
        if (reportingOptionId && formFields['Reports'] && formFields['Reports'][reportingOptionId]) {
            const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
            const newFormErrors: ObjectStringKeyAnyValue = { ...formErrors }
            const thisReportOptions = newFieldValues['Reports'][reportingOptionId]
            if (!value) {
                delete thisReportOptions['StageOptions'][stageId][checklistId]
            } else {
                if (!thisReportOptions['StageOptions']) {
                    thisReportOptions['StageOptions'] = {}
                }
                if (!thisReportOptions['StageOptions'][stageId]) {
                    thisReportOptions['StageOptions'][stageId] = {}
                }
                if (!thisReportOptions['StageOptions'][stageId][checklistId]) {
                    thisReportOptions['StageOptions'][stageId][checklistId] = {}
                }
            }
            delete newFormErrors[`${stageId}-${checklistId}`]
            setFormFields(newFieldValues)
            setFormErrors(newFormErrors)
        } else {
            console.log('⛔️ Error')
        }
    }


    if (!checklist) {
        return null
    }

    const optionsForThisChecklistId = reportingOptionId && formFields['Reports'] && formFields['Reports'][reportingOptionId] && formFields['Reports'][reportingOptionId]['StageOptions'] && formFields['Reports'][reportingOptionId]['StageOptions'][stageId] && formFields['Reports'][reportingOptionId]['StageOptions'][stageId][checklistId]


    return <Card fullwidth={true}>
        <h3 className={`font-righteous text-xl font-brandblue`}>{checklist.ChecklistName}</h3>
        {!CustomTemplateFileId && <p>No custom file uploaded</p>}

        {CustomTemplateFileId && <div className={`flex flex-col gap-2`}>

            <div className={`flex flex-row gap-2 items-center`}>
                <EnabledDisabled
                    handleChange={toggleIncludeInReporting}
                    enabled={optionsForThisChecklistId ? true : false}
                    fieldName='IncludeInReporting'
                />
                <p>Include this checklist in reporting?</p>
            </div>

            {optionsForThisChecklistId &&
                <div className={`w-full flex flex-col gap-3`}>

                    <div className={`w-full flex flex-col gap-1`}>
                        <p className={`font-bold`}>Place results at spreadsheet cell reference:</p>
                        <input
                            className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                            type='text'
                            name={`CellReference`}
                            id={`CellReference`}
                            value={optionsForThisChecklistId['CellReference'] || ''}
                            onChange={(e) => handleChangeReportingOption(`CellReference`, e.target.value)}
                        />

                    </div>

                    {formErrors[`${reportingOptionId}-${stageId}-${checklistId}`] && <RedAlert size={`small`} alignment='left' fullwidth={true}>{formErrors[`${reportingOptionId}-${stageId}-${checklistId}`]}</RedAlert>}
                </div>
            }




        </div>}

    </Card>

}
export default ProcessReportingOptionsChecklist