import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from "../utils/pubsub"
import DeleteButton from '../formfields/DeleteButton'
import { validateForm } from '../utils/validateForm'
import Button from '../formfields/Button'
import Radio from '../formfields/Radio'
import Text from '../formfields/Text'
import Card from '../ui-elements/Card'
import SubNavigation from '../shared-components/SubNavigation'
import YellowAlert from '../ui-elements/YellowAlert'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'
import AssociatedItems from '../ui-elements/AssociatedItems'

type ObjectStringKeyAnyValue = {
    [key: string]: any
}

type FridgeCardProps = {
    fridge: ObjectStringKeyAnyValue
}


function FridgeCard({
    fridge
}: FridgeCardProps) {

    const {
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)



    const [savingProgress, setSavingProgress] = useState(false)
    let displayFormat = `${fridge.DigitsBeforeDecimal === '2' ? 'XX' : 'X'}${fridge.DigitsAfterDecimal === '1' ? ':X' : ''}${fridge.DigitsAfterDecimal === '2' ? ':XX' : ''}`



    const deleteItem = (functionParams: ObjectStringKeyAnyValue) => {
        const { Id } = functionParams
        setShowModal({ "spinner": 'Deleting...' })
        const payload = JSON.stringify({
            action: "fridgetemps",
            fridgeAction: "deleteFridge",
            Id: Id
        })
        sendMessageToWebsocket(payload)
        const unsubscribe = subscribe("fridgeDeleted", data => {
            if (data.Id === Id) {
                setShowModal(null)
            }
            unsubscribe()
        })

    }



    const checklistsThatUseThisFridge = getThingsThatUseThisItem('FridgeId', fridge.Id, tableData)


    return <Card>
        <div className={`w-48 flex flex-col justify-between h-full`}>
            {savingProgress && <Modal
                showCloseButton={true}
                setShowModal={setSavingProgress}
            ><Spinner><p>Updating...</p></Spinner>
            </Modal>}

            <div className={`flex flex-col gap-2`}>
                <div>
                    <p className={'font-bold'}>{fridge.AssetName}</p>
                    {/* <p>Default value: {fridge.DefaultValue}</p> */}
                    <p className={`text-xs text-gray-600`}>ID: #{fridge.Id}</p>
                </div>


                {fridge['IsActive'] === false || fridge['IsActive'] === 'false' &&
                    <div className={`bg-red-100 rounded px-2 py-1 border border-red-200 text-red-700`}>
                        <p className={`text-xs text-center`}>Currently disabled</p>
                    </div>
                }


                {tableData && tableData.Groups && tableData!.Groups[fridge.GroupId] && fridge.GroupId && <p>Group: {tableData!.Groups[fridge.GroupId].GroupName}</p>}

                {/* {tableData && tableData.AssetProfiles && tableData!.AssetProfiles[fridge.ProfileId] && fridge.ProfileId && <p>Profile: {tableData!.AssetProfiles[fridge.ProfileId].ProfileName}</p>} */}


                <p className={`text-xs`}>Digital readout: {fridge.HasDigitalReadout ? 'Yes' : 'No'}</p>

                {fridge.DigitsBeforeDecimal >= 0 && fridge.DigitsAfterDecimal >= 0 && <p className={`text-xs`}>Display format: {displayFormat}</p>}

                <AssociatedItems
                    keyName={`FridgeId`}
                    itemId={fridge.Id}
                />

            </div>

            <div className={`mt-4 w-full flex flex-col gap-1 `}>


                <Button
                    internalLinkUrl={`/fridges/add-edit/${fridge.Id}`}
                    text={'Edit'}
                    variant={'primary'}
                    fullwidth={false}
                />



                <DeleteButton
                    layout={'vertical'}
                    deleteFunction={deleteItem}
                    functionParams={{
                        Id: fridge.Id
                    }}
                />
            </div>

        </div>
    </Card>
}


export default FridgeCard