
import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from "react-router-dom"
import YellowAlert from './YellowAlert'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'

type Props = {
    keyName: string,
    itemId: string
    itemType?: string
    shortText?: string
    longText?: string
}

function AssociatedItems({
    keyName,
    itemId,
    itemType = 'checklist',
    shortText,
    longText
}: Props) {

    const {
        tableData
    } = useContext(GlobalContext)


    const [showAll, setShowAll] = useState(false)


    const itemsAssociatedWithThis = getThingsThatUseThisItem(keyName, itemId, tableData, itemType)
    const itemTypeText = itemType === 'checklistQuestion' ? 'checklist' : itemType


    if (itemsAssociatedWithThis.length === 0) {
        return null
    }


    return <div className={`w-full`}>


        {!showAll && 
            <div
                className={`hover:opacity-80 cursor-pointer`}
                onClick={() => setShowAll(true)}
            >
                <YellowAlert icon={true} size={`small`} alignment={`left`}>
                    {shortText ? shortText : 'Used by'} {itemsAssociatedWithThis.length} {itemTypeText}{itemsAssociatedWithThis.length === 1 ? '' : 's'}
                </YellowAlert>
            </div>}



        {showAll && 
            <div
                className={`hover:opacity-80 cursor-pointer`}
                onClick={() => setShowAll(false)}
            >
                <YellowAlert icon={true} size={`small`} alignment={`left`}>
                    <div>
                        <p> {longText ? longText : 'Used by following'} {itemTypeText}{itemsAssociatedWithThis.length === 1 ? '' : 's'}:</p>
                        <p>
                            {itemsAssociatedWithThis.map((item: any, index: number) => {
                                return <span key={index}><Link
                                    className={`underline`}
                                    to={`/${itemType === 'checklist' ? 'checklists' : 'asset'}/${item.Id}`}>{item.DisplayName}</Link>{index + 1 < itemsAssociatedWithThis.length ? ', ' : ' '}</span>
                            })}</p>
                    </div>
                </YellowAlert>
            </div>}

    </div>
}
export default AssociatedItems