import React, { useEffect, useState, Children, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import XButton from '../formfields/XButton'

type Props = {
    children: any
}

function ButtonMenu({
    children
}: Props) {

    const [showMenu, setShowMenu] = useState(false)

    return <div className={`${showMenu ? 'z-10' : ''} absolute right-0 top-0 m-2`}>
        {!showMenu && <div className={`border-none bg-gray-400 rounded`}
            style={{ width: '36px', height: '36px', padding: '6px' }}>
            <img
                src='/hamburger.svg'
                alt='Menu'
                className={`w-full cursor-pointer`}
                onClick={() => setShowMenu(true)} />
        </div>}


        {showMenu && <div
            onMouseLeave={() => setShowMenu(false)}
            className={`w-full bg-white rounded border border-gray-400 px-3 py-3 shadow`}
            style={{ minWidth: '200px' }}
        >
            {/* <div className={`w-full flex justify-end`}>
                    <XButton clickFunction={() => setShowMenu(false)} />
                </div> */}
            <div className={`flex flex-col gap-1`}>
                {children}
            </div>
        </div>}
    </div>
}
export default ButtonMenu