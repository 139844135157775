import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Text from '../formfields/Text'
import { validateForm } from '../utils/validateForm'
import { removeNonAlphanumeric } from '../utils/removeNonAlphanumeric'
import Dropdown from '../formfields/Dropdown'
import YellowAlert from '../ui-elements/YellowAlert'
import AssociatedItems from '../ui-elements/AssociatedItems'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import AddButton from '../formfields/AddButton'
import AddEditStage from './AddEditStage'
import FooterNavButtonFrame from '../ui-elements/FooterNavButtonFrame'

type Props = {
}

function AddEditProcess({
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)

    const { processDefinitionId } = useParams()
    const navigate = useNavigate()

    const initialFormData = { "DisplayName": "" }
    const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
    const [formErrors, setFormErrors] = useState<ObjectStringKeyAnyValue>({})
    const [sortedStageIds, setSortedStageIds] = useState<string[]>([])
    const thisProcess = processDefinitionId && tableData && tableData.ProcessDefinitions && tableData.ProcessDefinitions[processDefinitionId]



    useEffect(() => {
        // populate fields with existing data
        if (thisProcess) {
            const newFieldValues: ObjectStringKeyAnyValue = { ...thisProcess }
            setFormFields(newFieldValues)
            if (thisProcess.Stages) {
                const updatedArrayOfStageIds = Object.keys(thisProcess.Stages).sort((a: string, b: string) => {
                    return thisProcess.Stages[a].Order - thisProcess.Stages[b].Order
                }) || []
                setSortedStageIds(updatedArrayOfStageIds)
            }
        }
    }, [tableData])



    const handleChange = (key: string, value: any) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        newFieldValues[key] = value
        setFormFields(newFieldValues)
    }

    const addStage = () => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        if (!newFieldValues['Stages']) {
            newFieldValues['Stages'] = {}
        }
        const newStageId = `stage-${Date.now()}`

        // find the highest order number and add 1
        let highestOrder: number = 0
        Object.keys(newFieldValues['Stages']).forEach((stageId: string) => {
            if (newFieldValues['Stages'][stageId].Order > highestOrder) {
                highestOrder = newFieldValues['Stages'][stageId].Order
            }
        })
        const newOrder = parseInt(`${highestOrder}`) + 1
        newFieldValues['Stages'][newStageId] = { "Order": newOrder }
        setSortedStageIds([...sortedStageIds, newStageId])
        setFormFields(newFieldValues)
    }

    const repositionStringwithinArray = (arr: string[], itemId: string, direction: 'up' | 'down') => {
        // reposition a string within a sorted array of strings
        const index = arr.indexOf(itemId)
        if (index === -1) {
            console.log("⛔️ Error: String not found in array");
        }
        if (direction === 'up' && index > 0) {
            [arr[index], arr[index - 1]] = [arr[index - 1], arr[index]];
        } else if (direction === 'down' && index < arr.length - 1) {
            [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]];
        }
        return arr
    }

    const renumberItems = (orderedArray: string[]) => {
        const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
        if (newFieldValues['Stages'] && Object.keys(newFieldValues['Stages']).length > 0) {
            const newStages: ObjectStringKeyAnyValue = {}
            orderedArray.forEach((stageId: string, index: number) => {
                newStages[stageId] = { ...newFieldValues['Stages'][stageId] }
                newStages[stageId].Order = index
            })
            newFieldValues['Stages'] = newStages
        }
        setFormFields(newFieldValues)
    }

    const repositionItem = (itemId: string, direction: 'up' | 'down') => {
        const updatedArrayOfStageIds = repositionStringwithinArray(sortedStageIds, itemId, direction)
        renumberItems(updatedArrayOfStageIds)
    }



    const saveItem = () => {
        const isValid = validateForm()
        if (isValid) {
            setShowModal({ "spinner": 'Saving...' })
            let item_id = processDefinitionId
            // generate a new Id if this is a new process
            if (!thisProcess) {
                const item_name = formFields['DisplayName'] || 'PROCESS'
                item_id = `${removeNonAlphanumeric(item_name).substring(0, 10)}-${Date.now()}`
            }
            const payload: ObjectStringKeyAnyValue = {
                action: "processesAdmin",
                subAction: "saveProcess",
                formFields: formFields,
                Id: item_id
            }
            sendMessageToWebsocket(JSON.stringify(payload))
            const unsubscribe = subscribe("processSaved", data => {
                if (data.Id === item_id) {
                    setShowModal(null)
                    navigate(`/process/${item_id}`)
                }
                unsubscribe()
            })
        }
    }


    const validateForm = () => {
        let isValid = true
        const newErrors: ObjectStringKeyAnyValue = {}

        if (!formFields['DisplayName'] || formFields['DisplayName'].length < 1) {
            newErrors['DisplayName'] = 'Please enter a process name'
            isValid = false
        }


        if (!formFields['Stages'] || Object.keys(formFields['Stages']).length < 1) {
            newErrors['Stages'] = 'Please add at least one stage'
            isValid = false
        }

        if (formFields['Stages']) {
            Object.keys(formFields['Stages']).forEach((stageId: string) => {


                if (!formFields['Stages'][stageId]['DisplayName'] || formFields['Stages'][stageId]['DisplayName'].length < 1) {
                    newErrors[`stage-${stageId}-DisplayName`] = 'Please enter a name for this stage'
                    isValid = false
                }

                if (!formFields['Stages'][stageId]['Behaviour']) {
                    newErrors[`stage-${stageId}-Behaviour`] = 'Please select a behaviour for this stage'
                    isValid = false
                }

                if (!formFields['Stages'][stageId]['Checklists'] || Object.keys(formFields['Stages'][stageId]['Checklists']).length < 1) {
                    newErrors[`stage-${stageId}-Checklists`] = 'Please add at least one checklist to this stage'
                    isValid = false
                }

                if (
                    formFields['Stages'][stageId]['Behaviour'] &&
                    formFields['Stages'][stageId]['Behaviour'] === 'ongoing' &&
                    !formFields['Stages'][stageId]['RepeatAfterMinutes']
                ) {
                    newErrors[`stage-${stageId}-RepeatAfterMinutes`] = 'Please select an option'
                    isValid = false
                }

                if (formFields['Stages'][stageId]['NextSteps']) {
                    Object.keys(formFields['Stages'][stageId]['NextSteps']).forEach((nextStepId: string) => {

                        if (
                            !formFields['Stages'][stageId]['NextSteps'][nextStepId]['NextStepName'] ||
                            !formFields['Stages'][stageId]['NextSteps'][nextStepId]['NextStage']
                        ) {

                            newErrors[`stage-${stageId}-NextStep-${nextStepId}`] = 'Please enter an event name and select a next stage'
                            isValid = false
                        }
                    })
                }

            })
        }
        setFormErrors(newErrors)
        return isValid
    }





    if (!tableData.ProcessDefinitions) {
        return <div className={`w-full flex flex-col items-center`}>
            <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>
                <Spinner>Loading processes...</Spinner>
            </div>
        </div>
    }

    return <div className={`w-full flex flex-col items-center mb-6`}>
        <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <h3 className={`font-righteous text-3xl font-brandblue`}>
                    {thisProcess ?
                        `Edit Process: ${thisProcess.DisplayName}` :
                        `Add new process`}
                </h3>
            </div>





            <div className={`w-full flex flex-col items-center gap-4`}>
                <div className={`w-full flex flex-col items-start gap-4`}>


                    <div className={`w-full flex md:flex-row flex-col gap-2 items-end justify-between`}>
                        <div className={`w-full flex flex-col gap-2`}>
                            <p className={`font-bold`}>Process Name:</p>
                            <input
                                className={`bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full text-sm px-3 py-2`}
                                type='text'
                                name={`DisplayName`}
                                id={`DisplayName`}
                                value={formFields[`DisplayName`] ? formFields[`DisplayName`] : ''}
                                onChange={(e) => handleChange(`DisplayName`, e.target.value)}
                            />

                            {formErrors['DisplayName'] && <RedAlert size={`small`} alignment='left' fullwidth={true}>{formErrors['DisplayName']}</RedAlert>}
                        </div>

                   
                    </div>


                    {sortedStageIds.map((stageId: string, index: number) => {
                        if (!formFields['Stages'] || !formFields['Stages'][stageId]) {
                            return null
                        }
                        return <AddEditStage
                            key={index}
                            formFields={formFields}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            setFormFields={setFormFields}
                            stageId={stageId}
                            nextStageId={sortedStageIds[index + 1] || null}
                            isFirst={stageId === sortedStageIds[0]}
                            isLast={stageId === sortedStageIds[sortedStageIds.length - 1]}
                            repositionItem={repositionItem}
                        />
                    })}

                    <AddButton
                        onClick={addStage}
                        text={`Add a stage`}
                    />

                    {formErrors['Stages'] && <RedAlert size={`small`} alignment='left' fullwidth={true}>{formErrors['Stages']}</RedAlert>}


                    {/* <p className={`mt-3 text-xs`}>{JSON.stringify(formFields).replaceAll(',', ', ')}</p> */}

                </div>

                <FooterNavButtonFrame>
                    <Button
                        internalLinkUrl={`/processes`}
                        text={`Back`}
                        variant={`gray`}
                        size='big'
                    />
                    <Button
                        onClick={saveItem}
                        text={`Save`}
                        size='big'
                    />
                </FooterNavButtonFrame>
            </div>

        </div>
    </div>

}
export default AddEditProcess