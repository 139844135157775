import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link } from 'react-router-dom'
import { subscribe } from "../utils/pubsub"
import CloudformationOutputs from '../CloudformationOutputs.json'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import { dateDiff } from '../utils/datediff'
import Button from '../formfields/Button'
import DeleteButton from '../formfields/DeleteButton'
import Card from '../ui-elements/Card'
import YellowAlert from '../ui-elements/YellowAlert'
import RedAlert from '../ui-elements/RedAlert'
import AssociatedItems from '../ui-elements/AssociatedItems'
import ButtonMenu from '../ui-elements/ButtonMenu'




type Props = {


}

function ListProcesses({

}: Props) {

    const {
        tableData,
        setShowModal,
        sendMessageToWebsocket
    } = useContext(GlobalContext)



    const deleteItem = (Id: string) => {
        setShowModal({ "spinner": 'Deleting...' })
        const payload = JSON.stringify({
            action: "processesAdmin",
            subAction: "deleteProcess",
            Id: Id
        })
        sendMessageToWebsocket(payload)
        const unsubscribe = subscribe("processDeleted", data => {
            if (data.Id === Id) {
                setShowModal(null)
            }
            unsubscribe()
        })

    }


    const generateQrCode = (Id: string) => {
        setShowModal({ "spinner": 'Generating code...' })
        const payload = JSON.stringify({
            action: "processesAdmin",
            subAction: "generateQrCode",
            processId: Id
        })
        sendMessageToWebsocket(payload)
        const unsubscribe = subscribe("qrCodeGenerated", data => {
            // console.log(data)
            if (data.processId === Id) {
                setShowModal(null)
                if (data.downloadUrl) {
                    window.location.href = data.downloadUrl
                }
                unsubscribe()
            }
        })
    }



    return <div className={`w-full flex flex-col items-center`}>
        <div className={`max-w-3xl w-full p-4 flex flex-col gap-4 items-center justify-between`}>

            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <h3 className={`font-righteous text-3xl font-brandblue`}>Processes</h3>

                <div className={`flex flex-row gap-2 justify-center items-center`}>
                    <Button
                        internalLinkUrl={`/process`}
                        text={`Build new process`}
                        variant={`primary`}
                        // size={`big`}
                        fullwidth={false}
                    />
                </div>
            </div>






            {(!tableData.ProcessDefinitions || Object.keys(tableData.ProcessDefinitions).length === 0) &&
                <p className={`w-full`}>No processes have been set up yet.</p>}


            {tableData && tableData.ProcessDefinitions && Object.keys(tableData.ProcessDefinitions).length > 0 && <div className={`w-full gap-2 flex md:flex-row flex-col flex-wrap`}>
                {Object.keys(tableData.ProcessDefinitions).map((processId: string, index: number) => {
                    const ProcessDefinition = tableData.ProcessDefinitions[processId]

                    return <Card
                        key={index}
                        fullwidth={false}
                    ><div
                        className={`h-full md:w-80 w-full flex flex-col gap-4 justify-between`}>

                            <div className={`flex flex-col gap-2`}>
                                <p className={`font-bold`}>{ProcessDefinition.DisplayName}</p>
                                <p className={`text-xs text-gray-600 italic`}>#{ProcessDefinition.Id}</p>


                                {ProcessDefinition.Stages && Object.keys(ProcessDefinition.Stages).length > 0 &&
                                    <p className={`text-sm`}>{Object.keys(ProcessDefinition.Stages).length} Stages</p>
                                }








                            </div>


                        </div>

                        <ButtonMenu>
                            <Button
                                internalLinkUrl={`/process/${processId}`}
                                text={`Edit settings`}
                                variant={`primary`}
                                // size={`big`}
                                fullwidth={true}
                            />
                            <Button
                                internalLinkUrl={`/process/${processId}/reporting`}
                                text={`Edit report settings`}
                                variant={`primary`}
                                // size={`big`}
                                fullwidth={true}
                            />
                            <Button
                                internalLinkUrl={`/results/process/${processId}`}
                                text={`View results`}
                                variant={`primary`}
                                // size={`big`}
                                fullwidth={true}
                            />
                            <Button
                                onClick={() => generateQrCode(processId)}
                                text={`Generate QR code`}
                                variant={`primary`}
                                // size={`big`}
                                fullwidth={true}
                            />
                            <DeleteButton
                                deleteFunction={deleteItem}
                                functionParams={processId}
                                fullwidth={true}
                            />
                        </ButtonMenu>
                    </Card>

                })} </div>}

        </div>
    </div>
}
export default ListProcesses