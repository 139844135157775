import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from '../utils/pubsub'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import YellowAlert from '../ui-elements/YellowAlert'
import { startNewQuestion } from './checklist-utils/startNewQuestion'


type Props = {
    checklistId: string
}

function QuestionsMiniSummary({
    checklistId
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setChecklistQuestionFields
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const checklistQuestions = tableData && checklistId && tableData.ChecklistQuestions && Object.keys(tableData.ChecklistQuestions).filter((questionKey) => tableData.ChecklistQuestions[questionKey].ChecklistId === checklistId)

    return <Card fullwidth={true}>
        <div className={`md:w-48 w-full h-full flex flex-col gap-4 justify-between`}>
            <div className={`flex-1 flex flex-col gap-2`}>
                <h4 className={`font-righteous text-xl font-brandblue`}>Questions</h4>

                {checklistQuestions && checklistQuestions.length > 0 ?
                    <p>This checklist contains {checklistQuestions.length} question{checklistQuestions.length === 1 ? '' : 's'} </p>
                    :
                    <YellowAlert fullwidth={true} alignment='left'>This checklist does not contain any questions</YellowAlert>
                }
            </div>

            {checklistQuestions && checklistQuestions.length > 0 ?
                <Button
                    internalLinkUrl={`/checklists/${checklistId}/questions`}
                    text={`Edit questions`}
                    variant={`primary`}
                    // size={`big`}
                    fullwidth={false}
                />
                :
                <Button
                    text={`Add a question`}
                    onClick={() => startNewQuestion(checklistId, setChecklistQuestionFields, navigate)}
                    variant='primary'
                    fullwidth={false}
                // size={'small'}
                />}
        </div>
    </Card>
}
export default QuestionsMiniSummary