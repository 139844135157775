import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import Spinner from '../ui-elements/Spinner'
import { useParams, useNavigate } from 'react-router-dom'
import DeleteButton from '../formfields/DeleteButton'
import ListOfChecklists from './ListOfChecklists'
import ChecklistWrapper from './wrapper/ChecklistWrapper'
import YellowAlert from '../ui-elements/YellowAlert'

type Props = {
}

function MyChecklists({
}: Props) {

  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields
  } = useContext(GlobalContext)

  const { checklistId } = useParams()
  const navigate = useNavigate()
  const [savingProgress, setSavingProgress] = useState(false)
  const numberOfChecklists = tableData && tableData.Checklists && Object.keys(tableData.Checklists).length
  
  const deleteChecklist = (checklistId: string) => {
    if (checklistId) {
      setSavingProgress(true)
      const payload = JSON.stringify({
        action: "checklists",
        subAction: "deleteChecklist",
        checklistId: checklistId
      })
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("checklistDeletedResponse", data => {
        setSavingProgress(false)
        unsubscribe()
      })
    }
  }


  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>

    <div className={`w-full flex md:flex-row flex-col items-start justify-between gap-3`}>
      <h3 className={`font-righteous text-3xl font-brandblue`}>My Checklists</h3>

      <div className={`flex flex-row items-center gap-2`}>
        <Button
          internalLinkUrl={`new/settings`}
          text={`Build new checklist`}
          variant={`primary`}
          // size={`big`}
          fullwidth={false}
        />
        <Button
          internalLinkUrl={`/checklists/presets/`}
          text={`Add checklist from template`}
          fullwidth={false}
        />
      </div>
      </div>


      {numberOfChecklists === 0 && <YellowAlert>No checklists found</YellowAlert>}
      {numberOfChecklists > 0 && <p>{numberOfChecklists} checklist{numberOfChecklists === 1 ? '' : 's'} found</p>}
      <ListOfChecklists />
    </div>
  </div>

}
export default MyChecklists