import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'
import { subscribe } from '../utils/pubsub'
import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import YellowAlert from '../ui-elements/YellowAlert'
import { b64toBlob } from '../utils/b64toBlob'


type Props = {
    checklistData: ObjectStringKeyAnyValue
}







function UploadResultsSpreadsheet({
    checklistData
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const { checklistId } = useParams()
    const [savingProgress, setSavingProgress] = useState(false)
    const [fileToUpload, setFileToUpload] = useState<any>(null)

    const downloadFormTemplateClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        console.log("downloading form template")
        console.log(checklistId)
        const payload = JSON.stringify({
            action: "document",
            subAction: "custom_template_download",
            checklistId: checklistId
        })
        sendMessageToWebsocket(payload)
        // subscribe to customTemplateDownloadResponse which will return a signed s3 download url
        // attmept to open the url in a new tab - this should trigger the downloa
        subscribe("customTemplateDownloadResponse", (data) => {
            console.log(data)
            window.open(data.downloadUrl, '_blank')
        })

    }


    const requestUploadUrl = async () => {
        setSavingProgress(true)
        const timestamp = new Date().getTime().toString()
        const filename = `spreadsheet.xlsx`
        const payload = JSON.stringify({
            action: "document",
            subAction: "custom_template_upload",
            checklistId: checklistId,
            file: filename
        })
        sendMessageToWebsocket(payload)

        const response = await new Promise<void>((resolve, reject) => {
            const unsubscribe = subscribe("customTemplateUploadResponse", async data => {

                console.log(`uploading to: ${data.uploadUrl}`)
                unsubscribe()
                const file = new File([fileToUpload], timestamp, {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                })

                await fetch(data.uploadUrl, {
                    method: 'PUT',
                    body: fileToUpload,
                    headers: {
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    },
                })
                    .then(response => {
                        console.log('Success:', response)
                        const payload = JSON.stringify({
                            action: "document",
                            subAction: "custom_template_upload_2",
                            checklistId: checklistId,
                            ImageFileName: data.imageFileName
                        })
                        sendMessageToWebsocket(payload)

                        const unsubscribe = subscribe("customTemplateUpload2Response", data => {
                            console.log(data)
                            setSavingProgress(false)
                            unsubscribe()
                        })

                        return response
                    }).catch((error) => {
                        console.error('Error:', error)
                    })
            })
        })
    }


    return <Card fullwidth={true}>
        <div className={`md:w-48 w-full h-full flex flex-col gap-4 justify-between`}>
            <div className={`w-full flex flex-col gap-2`}>

                {!(checklistData && checklistData.CustomTemplateUploaded) &&
                    <div className={`w-full flex flex-col gap-2`}>
                        <h4 className={`font-righteous text-xl font-brandblue`}>Upload custom results form</h4>
                        <p>Upload your existing forms, procedures and checklists and map the results from this checklist into a PDF</p>
                    </div>}

                {(checklistData && checklistData.CustomTemplateUploaded) &&
                    <div className={`w-full flex flex-col gap-2`}>
                        <h4 className={`font-righteous text-xl font-brandblue`}>Custom results form settings</h4>
                        <p>Your custom results form is uploaded and ready to use</p>
                        <a href="#" onClick={downloadFormTemplateClicked}>⬇️ Download form template</a>
                    </div>}

                <input
                    className={``}
                    type='file'
                    accept=".xls,.xlsx"
                    name={`spreadsheetFile`}
                    id={`spreadsheetFile`}
                    onChange={(e) => setFileToUpload(e!.target!.files![0])}
                />

                {fileToUpload && <Button
                    onClick={requestUploadUrl}
                    text={`Upload`}
                    variant={`primary`}
                    fullwidth={false}
                />}


            </div>

            <Button
                internalLinkUrl={`/checklists/${checklistId}/custom-template-fields`}
                text={`Edit template fields`}
                variant={`primary`}
                // size={`big`}
                fullwidth={true}
            />

        </div>



        {savingProgress && <Modal
            showCloseButton={true}
            setShowModal={setSavingProgress}
        ><Spinner><p>Uploading...</p></Spinner>
        </Modal>}
    </Card>
}
export default UploadResultsSpreadsheet