import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import { GlobalContext } from '../GlobalContext'

import Button from '../formfields/Button'
import Card from '../ui-elements/Card'
import SummaryItem from './SummaryItem'
import SummaryCheckWindow from './SummaryCheckWindow'
import IndividualQuestion from './IndividualQuestion'
import { startNewQuestion } from './checklist-utils/startNewQuestion'
import { sortQuestionIdsByOrder } from './checklist-utils/sortQuestionIdsByOrder'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import DroppableStrictMode from '../ui-elements/DroppableStrictMode'
import DraggableQuestionDetail from './DraggableQuestionDetail'
import AnswerActionSummary from './AnswerActionSummary'
import { subscribe } from '../utils/pubsub'



type Props = {
  questionsData: null | ObjectStringKeyAnyValue
  showEditButton?: boolean
  savingProgress: boolean
  setSavingProgress: React.Dispatch<React.SetStateAction<any>>
}

function SettingsDetails({
  questionsData,
  showEditButton = true,
  savingProgress,
  setSavingProgress
}: Props) {



  const { checklistId } = useParams()
  const {
    tableData,
    checklistQuestionFields,
    setChecklistQuestionFields,
    setShowModal,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const navigate = useNavigate()

  const [sortedParentQuestionIds, setSortedParentQuestionIds] = useState<null | Array<string>>(null)


  useEffect(() => {
    if (questionsData) {
      const parentQuestionsOnly: ObjectStringKeyAnyValue = {}
      if (tableData && tableData['ChecklistQuestions']) {
        for (const questionId in tableData['ChecklistQuestions']) {
          const thisQuestion = tableData['ChecklistQuestions'][questionId]
          if (thisQuestion['ChecklistId'] === checklistId && !thisQuestion['parentQuestionId'] && !thisQuestion['parentActionId']) {
            parentQuestionsOnly[questionId] = thisQuestion
          }
        }
      }
      const getSortedParentQuestionIds = sortQuestionIdsByOrder(parentQuestionsOnly)
      if (getSortedParentQuestionIds) {
        setSortedParentQuestionIds(getSortedParentQuestionIds)
      }
    }
  }, [questionsData])


  function handleOnDragEnd(result: any) {
    if (!result.destination) return
    if (!sortedParentQuestionIds) return
    const originalPosition = result.source.index
    const newPosition = result.destination.index
    const questionId = result.draggableId
    if (originalPosition === newPosition) return

    // const items = Array.from(sortedParentQuestionIds)
    // const [reorderedItem] = items.splice(result.source.index, 1)
    // items.splice(result.destination.index, 0, reorderedItem)
    // setSortedParentQuestionIds(items)

    //console.log(`Item moved from ${originalPosition} to ${newPosition}`)
    setShowModal({ "spinner": 'Moving...' })
    const payload = JSON.stringify({
      action: 'checklists',
      subAction: 'dragQuestionPosition',
      checklistId: checklistId,
      questionId: questionId,
      newPosition: newPosition
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("tableUpdateItemModified", data => {
      //console.log(data)
      if (data && 
        data.tableName === 'ChecklistQuestions' 
      ) {
        setShowModal(null)
      }
      unsubscribe()
    })



  }


  return <div>

    <Card>
      <div className={`w-full flex flex-row gap-4 items-center justify-between`}>
        <div>
          <h4 className={`font-righteous text-xl font-brandblue mb-4`}>
            Questions
          </h4>
          {sortedParentQuestionIds && <p>{Object.keys(sortedParentQuestionIds).length} questions</p>}
        </div>
        <Button
          onClick={() => startNewQuestion(checklistId, setChecklistQuestionFields, navigate)}
          text={`Add question`}
          variant={`primary`}
          // size={`small`}
          fullwidth={false}
        />
      </div>

      {(!sortedParentQuestionIds || Object.keys(sortedParentQuestionIds).length === 0) && <p>No questions yet</p>}


      {questionsData && sortedParentQuestionIds && <DragDropContext onDragEnd={handleOnDragEnd}>
        <DroppableStrictMode droppableId="questions">
          {(provided) => (
            <div className={`flex flex-col gap-2`}
              {...provided.droppableProps} ref={provided.innerRef}>
              {sortedParentQuestionIds.map((questionId, index) => {
                if (!questionsData[questionId]) return null
                return <div key={questionId}>
                  <Draggable key={questionId} draggableId={questionId} index={index}>
                    {(provided, snapshot) => (

                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <DraggableQuestionDetail
                          questionId={questionId}
                          question={questionsData[questionId]}
                          isDragging={snapshot.isDragging}
                        />
                      </div>
                    )}
                  </Draggable>

                  {questionsData[questionId]['AnswerActions'] && Object.keys(questionsData[questionId]['AnswerActions']).length > 0 &&
                    <AnswerActionSummary
                      question={questionsData[questionId]}
                      handleOnDragEnd={handleOnDragEnd}
                      questionsData={questionsData}
                    />
                  }

                </div>
              })}
              {provided.placeholder}
            </div>
          )}
        </DroppableStrictMode>
      </DragDropContext>
      }

    </Card>

  </div>
}
export default SettingsDetails