

import React, { useContext, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import Modal from '../ui-elements/Modal'

type Props = {
    assetId: string | null
}

function FormatAssetName({
    assetId
}: Props) {

    const {
        tableData
    } = useContext(GlobalContext)

    const assetName = assetId && tableData && tableData.Assets && tableData.Assets[assetId] && tableData.Assets[assetId].AssetName


    if (!assetId) {
        return null
    }


    return <span>
        {assetName ?
            ` "${assetName}" (#${assetId}): ` :
            ` asset Id #${assetId}: `
        }
    </span>
}

export default FormatAssetName