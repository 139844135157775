import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../../GlobalContext'
import Button from '../../formfields/Button'
import Card from '../../ui-elements/Card'
import RedAlert from '../../ui-elements/RedAlert'
import Spinner from '../../ui-elements/Spinner'
import ChecklistSetupWrapper from '../wrapper/ChecklistSetupWrapper'
import SingleCheckWindow from './SingleCheckWindow'


type Props = {
    settingsFormFields: ObjectStringKeyAnyValue
    thisChecklist: ObjectStringKeyAnyValue | null
    settingsFormErrors: ObjectStringKeyAnyValue
    setSettingsFormFields: React.Dispatch<React.SetStateAction<any>>
}

function ChecklistForAssetType({
    settingsFormFields,
    thisChecklist,
    settingsFormErrors,
    setSettingsFormFields
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
    } = useContext(GlobalContext)

    const [isChecklistForAssetType, setIsChecklistForAssetType] = useState(false)

    useEffect(() => {
        if (thisChecklist && thisChecklist['AssetTypeId']  && thisChecklist['AssetTypeId'] !== 'none') {
            setIsChecklistForAssetType(true)
        }
    }, [thisChecklist])


    const handleChange = (value: any) => {
        setIsChecklistForAssetType(value)
        const newFieldValues: ObjectStringKeyAnyValue = { ...settingsFormFields }
        if (value === false || value === '') {
            delete newFieldValues['AssetTypeId']
        }
        if (typeof value === 'string' && value !== '') {
            newFieldValues['AssetTypeId'] = value
        }
        setSettingsFormFields(newFieldValues)
    }



    if (!tableData || !tableData['AssetTypes'] || Object.keys(tableData['AssetTypes']).length === 0) {
        return null
    }


    return <div className={`flex flex-col gap-2`}>

        <div className={`flex flex-col gap-2`}>
            Is this checklist associated with a particular asset type?
            <div className={`flex flex-row gap-2`}>
                <Button
                    onClick={() => handleChange(true)}
                    text='Yes'
                    variant={isChecklistForAssetType ? `primary` : `gray`}
                    fullwidth={false}
                />

                <Button
                    onClick={() => handleChange(false)}
                    text='No'
                    variant={isChecklistForAssetType ? `gray` : `primary`}
                    fullwidth={false}
                />
            </div>
        </div>

        {isChecklistForAssetType && <div className={`flex flex-col gap-2`}>
            <h3 className={`font-righteous text-brandblue text-lg`}>Asset type:</h3>
            <select
                className={`w-full bg-white drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 text-sm px-3 py-2`}
                name={`AssetTypeId`}
                value={
                    settingsFormFields &&
                        settingsFormFields['AssetTypeId'] ?
                        settingsFormFields['AssetTypeId'] : ''}
                onChange={(e) => handleChange(e.target.value)}
            >

                <option value={''}>Please select</option>
                {tableData && tableData['AssetTypes'] && Object.keys(tableData['AssetTypes']).map((assetTypeId: string) => {
                    const thisAssetType = tableData['AssetTypes'][assetTypeId]
                    return <option key={assetTypeId} value={assetTypeId}>{thisAssetType.DisplayName}</option>
                })}
            </select>
            {settingsFormErrors && settingsFormErrors['AssetTypeId'] && <RedAlert>{settingsFormErrors['AssetTypeId']}</RedAlert>}
        </div>}
    </div>
}

export default ChecklistForAssetType