import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Button from '../formfields/Button'
import { useParams, useNavigate, Link } from 'react-router-dom'
import Text from '../formfields/Text'
import { validateForm } from '../utils/validateForm'
import { removeNonAlphanumeric } from '../utils/removeNonAlphanumeric'
import Dropdown from '../formfields/Dropdown'
import YellowAlert from '../ui-elements/YellowAlert'
import AssociatedItems from '../ui-elements/AssociatedItems'
import { getThingsThatUseThisItem } from '../utils/getThingsThatUseThisItem'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import AddButton from '../formfields/AddButton'
import AddEditStage from './AddEditStage'
import FooterNavButtonFrame from '../ui-elements/FooterNavButtonFrame'
import ProcessReportingOptionsStage from './ProcessReportingOptionsStage'
import JsonDebug from '../ui-elements/JsonDebug'
import Card from '../ui-elements/Card'
import DeleteButton from '../formfields/DeleteButton'

type Props = {
    thisReport: ObjectStringKeyAnyValue
    thisProcess: ObjectStringKeyAnyValue
    reportingOptionId: string,
    setFormFields: React.Dispatch<React.SetStateAction<any>>,
    formFields: ObjectStringKeyAnyValue
}

function ProcessReportingIndividual({
    thisReport,
    thisProcess,
    reportingOptionId,
    setFormFields,
    formFields
}: Props) {

    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal
    } = useContext(GlobalContext)




    const deleteReportingOption = ({ reportingOptionId, processDefinitionId}: ObjectStringKeyAnyValue) => {
        if (reportingOptionId && processDefinitionId) {
            setShowModal({ "spinner": 'Deleting...' })
            const payload: ObjectStringKeyAnyValue = {
                action: "processesAdmin",
                subAction: "deleteReportingOption",
                processDefinitionId: processDefinitionId,
                reportingOptionId: reportingOptionId
            }
            sendMessageToWebsocket(JSON.stringify(payload))
            const unsubscribe = subscribe("reportingOptionDeleted", data => {
                if (data.reportingOptionId === reportingOptionId) {
                    setShowModal(null)
                }
                unsubscribe()
            })
        }
    }


    return <Card fullwidth={true}>
        <div className={`w-full flex flex-row items-center justify-between gap-4`}>

            <div className={``}>
                <p className={`font-righteous text-brandblue text-lg`}>{thisReport.DisplayName || `Report id: #${reportingOptionId}`}</p>
            </div>

            <div className={`flex flex-col gap-1`}>
                <Button
                    internalLinkUrl={`/process/${thisProcess.Id}/reporting/${reportingOptionId}/`}
                    text={`Edit`}
                    variant={`primary`}
                    // size='big'
                />
                <DeleteButton
                    deleteFunction={deleteReportingOption}
                    functionParams={{reportingOptionId, processDefinitionId: thisProcess.Id}}
                />
            </div>
        </div>
    </Card>

}
export default ProcessReportingIndividual